import { useRef, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Axios from 'axios'
import styled, { useTheme } from 'styled-components'
import Select from 'react-select'
import { IconHelp } from './CommonStylesTables'
import ReactTooltip from 'react-tooltip'

import {
  setInvitationSelected,
  setInvitationURL,
} from '../redux/invitationsReducer'
import { setNotificationState } from '../redux/notificationsReducer'

import FormInvitationEmail from './FormInvitationEmail'
import FormDriversLicenseIssuance from './FormDriversLicenseIssuance'
import FormUniversalIssuance from './FormUniversalIssuance'
import FormPresentationQR from './FormPresentationQR'

import PageHeader from './PageHeader.js'
import PageSection from './PageSection.js'

import { SubmitBtnModal } from './CommonStylesForms'

const HomeHeader = styled.h2`
  display: inline-block;
  margin-right: 10px;
  font-size: 1.3em;
`
const SelectContainer = styled.div`
  float: left;
  z-index: 100;
  width: 300px;
  margin-right: 15px;
  overflow: visible;
`
const Form = styled.form`
  overflow: hidden;
  margin-bottom: 10px;
`

const BulkEmailErrors = styled.div`
  overflow-y: auto;
  max-height: 20rem;
`

function Home(props) {
  const dispatch = useDispatch()
  const settingsState = useSelector((state) => state.settings)
  const schemasState = useSelector((state) => state.schemas)
  const invitationsState = useSelector((state) => state.invitations)
  const contactsState = useSelector((state) => state.contacts)

  const organizationName = settingsState.organizationName
  const submitBtn = useRef()

  const [credentialSchemasOptions, setCredentialSchemasOptions] = useState([])
  const [chosenCredential, setChosenCredential] = useState({})
  const [notReadyForIssuance, setNotReadyForIssuance] = useState(true)

  const [driversLicenseModalIsOpen, setDriversLicenseModalIsOpen] = useState(
    false
  )

  const [universalModalIsOpen, setUniversalModalIsOpen] = useState(false)

  const [connected, setConnected] = useState(false)
  const [waitingForConnection, setWaitingForConnection] = useState(false)
  const [waitingForVerification, setWaitingForVerification] = useState(false)
  const [verificationStatus, setVerificationStatus] = useState(undefined)
  const [
    verificationDefinitionOptions,
    setVerificationDefinitionOptions,
  ] = useState([])
  const [chosenSchema, setChosenSchema] = useState({})
  const [notReadyForVerification, setNotReadyForVerification] = useState(true)
  const [bulkEmails, setBulkEmails] = useState({
    emailsSuccess: [],
    emailsFailure: [],
    invalidEmails: [],
  })
  const [verificationButtonClicked, setVerificationButtonClicked] = useState(
    false
  )

  const [csvData, setCsvData] = useState([])

  const [emailModalIsOpen, setEmailModalIsOpen] = useState(false)
  const closeEmailModal = () => {
    setNotReadyForIssuance(true)
    setEmailModalIsOpen(false)
    setChosenCredential({})
  }

  const [verificationModalIsOpen, setVerificationModalIsOpen] = useState(false)

  const closeVerificationQRModal = () => setVerificationModalIsOpen(false)

  const closeDriversLicenseModal = () => {
    setNotReadyForIssuance(true)
    setDriversLicenseModalIsOpen(false)
    setChosenCredential({})
  }

  const closeUniversalModal = () => {
    setNotReadyForIssuance(true)
    setUniversalModalIsOpen(false)
    setChosenCredential({})
  }

  useEffect(() => {
    let options = []

    if (schemasState.schemas) {
      const activeShemas = schemasState.schemas.filter(
        (schema) => schema.active === true
      )
      for (let i = 0; i < activeShemas.length; i++) {
        options.push({
          value: activeShemas[i].name,
          label: activeShemas[i].name.replaceAll('_', ' '),
        })
      }
      setCredentialSchemasOptions(options)
    }
  }, [schemasState.schemas])

  useEffect(() => {
    let options = []

    // (eldersonar) Handle governance options state (needs better description)
    if (schemasState.verificationDefinitions) {
      const activeShemas = schemasState.verificationDefinitions.filter(
        (schema) => schema.active === true || schema.active === undefined
      )
      for (let i = 0; i < activeShemas.length; i++) {
        options.push({
          id: i,
          label: activeShemas[i].verification_label,
          value: activeShemas[i].verification_label,
        })
      }
      setVerificationDefinitionOptions(options)
    }
  }, [schemasState.verificationDefinitions])

  useEffect(() => {
    if (csvData.length > 0) {
      submitBtn.current.removeAttribute('disabled')
    }
  }, [csvData])

  useEffect(() => {
    if (invitationsState.invitationURL !== '') {
      setWaitingForConnection(true)
    }
    if (contactsState.connection.state === 'active' && waitingForConnection) {
      setConnected(true)

      // (eldersonar) Trigger presentation request flow only if the invitation was created for verification
      if (verificationButtonClicked) {
        closeVerificationQRModal()
        requestPresentations()
      }
    }
  }, [
    invitationsState.invitationURL,
    contactsState.connection,
    waitingForConnection,
  ])

  useEffect(() => {
    if (props.verifiedCredential !== null && props.verifiedCredential !== '') {
      setVerificationStatus(true)
      setNotReadyForVerification(true)
    } else if (props.verifiedCredential === null) {
      setVerificationStatus(false)
      setNotReadyForVerification(true)
    } else {
      return
    }
  }, [props.verifiedCredential])

  function requestPresentations() {
    if (chosenSchema && chosenSchema.label) {
      const schema = schemasState.verificationDefinitions.find(
        ({ verification_label }) => verification_label === chosenSchema.label
      )

      // TODO: map connections and send to all connections
      props.sendRequest('PRESENTATIONS', 'ADMIN_REQUEST', {
        connection_id: contactsState.connection.connection_id,
        schema_id: schema.schema_id,
        attributes: schema.attribute_names,
        discovered_features: contactsState.connection.discovered_features,
        schema,
      })

      setChosenSchema({})
      setVerificationStatus(undefined)
      setWaitingForVerification(true)
      setVerificationButtonClicked(false)
      props.setVerifiedCredential('')
    } else {
      return
    }
  }

  const SchemaSelect = () => {
    return (
      <form>
        <HomeHeader>Verification Workflow</HomeHeader>
        <p>Select a Schema Option to Verify:</p>
        <SelectContainer>
          <Select
            id="chosenSchema"
            name="schemas"
            placeholder="---Choose Schema---"
            defaultValue={
              Object.keys(chosenSchema).length !== 0 ? chosenSchema : ''
            }
            onChange={(e) => handleSchemaChange(e)}
            menuPortalTarget={document.body}
            options={verificationDefinitionOptions}
          />
        </SelectContainer>

        <SubmitBtnModal
          disabled={notReadyForVerification}
          type="submit"
          onClick={(e) => {
            setVerificationButtonClicked(true)
            handleVerificationModalOpen(e)
          }}
          style={{ float: 'left' }}
        >
          Verify
        </SubmitBtnModal>
      </form>
    )
  }

  const CredentialSelect = () => {
    return (
      <form>
        <HomeHeader>Issuance Workflow</HomeHeader>
        <p>Select a Credential to Issue:</p>
        <SelectContainer>
          <Select
            className="react-select-container"
            name="chosenCred"
            placeholder="---Choose Credential---"
            defaultValue={
              Object.keys(chosenCredential).length !== 0 ? chosenCredential : ''
            }
            onChange={(e) => handleCredChange(e)}
            menuPortalTarget={document.body}
            options={credentialSchemasOptions}
            id="button-dropdown-credential-selection"
          />
        </SelectContainer>
        <SubmitBtnModal
          disabled={notReadyForIssuance}
          type="submit"
          onClick={(e) => {
            handleCredentialModalOpen(e)
          }}
          id="button-issue"
        >
          Issue
        </SubmitBtnModal>
      </form>
    )
  }

  const onBtnClick = (e) => {
    if (submitBtn.current) {
      submitBtn.current.setAttribute('disabled', 'disabled')
    }
  }

  function handleCredChange(e) {
    setChosenCredential(e)
    setNotReadyForIssuance(false)
  }

  function handleSchemaChange(e) {
    setChosenSchema(e)
    setNotReadyForVerification(false)
  }

  function handleCredentialModalOpen(e) {
    e.preventDefault()
    console.log(chosenCredential.value)
    if (chosenCredential.value === 'Email') {
      setEmailModalIsOpen((o) => !o)
    } else if (chosenCredential.value === 'Drivers_License') {
      // (AmmonBurgi) We need to reset the invitation so the modal doesn't proceed before a new invitation is received.
      dispatch(setInvitationSelected({}))
      dispatch(setInvitationURL(''))

      setDriversLicenseModalIsOpen((o) => !o)

      props.sendRequest('OUT_OF_BAND', 'CREATE_INVITATION', {
        handshakeProtocol: 'https://didcomm.org/didexchange/1.1',
        alias: 'Invitation',
        invitationMode: 'once',
        accept: 'auto',
        public: false,
        invitationStatus: 'active',
        invitationDescription:
          "Invitation for Driver's License credential issuance.",
        invitationLabel: settingsState.organizationName,
        invitationRole: 'Holder',
        usesAllowed: 1,
      })
    } else {
      // (AmmonBurgi) We need to reset the invitation so the modal doesn't proceed before a new invitation is received.
      dispatch(setInvitationSelected({}))
      dispatch(setInvitationURL(''))

      setUniversalModalIsOpen((o) => !o)

      props.sendRequest('OUT_OF_BAND', 'CREATE_INVITATION', {
        handshakeProtocol: 'https://didcomm.org/didexchange/1.1',
        alias: 'Invitation',
        invitationMode: 'once',
        accept: 'auto',
        public: false,
        invitationStatus: 'active',
        invitationDescription: 'Invitation for Universal credential issuance.',
        invitationLabel: settingsState.organizationName,
        invitationRole: 'Holder',
        usesAllowed: 1,
      })
    }
  }

  function handleVerificationModalOpen(e) {
    e.preventDefault()
    // (AmmonBurgi) We need to reset the invitation so the modal doesn't proceed before a new invitation is received.
    dispatch(setInvitationSelected({}))
    dispatch(setInvitationURL(''))

    setVerificationModalIsOpen((o) => !o)

    props.sendRequest('OUT_OF_BAND', 'CREATE_INVITATION', {
      handshakeProtocol: 'https://didcomm.org/didexchange/1.1',
      alias: 'Invitation',
      invitationMode: 'once',
      accept: 'auto',
      public: false,
      invitationStatus: 'active',
      invitationDescription: 'Invitation for credential verification',
      invitationLabel: settingsState.organizationName,
      invitationRole: 'Holder',
      usesAllowed: 1,
    })
  }

  function handleOpen(e) {
    e.preventDefault()
    console.log(chosenCredential)
    if (chosenCredential === 'Email') {
      setEmailModalIsOpen((o) => !o)
    } else if (chosenCredential === 'Drivers_License') {
      // (AmmonBurgi) We need to reset the invitation so the modal doesn't proceed before a new invitation is received.
      dispatch(setInvitationSelected({}))
      dispatch(setInvitationURL(''))

      setDriversLicenseModalIsOpen((o) => !o)
      props.sendRequest('OUT_OF_BAND', 'CREATE_INVITATION', {
        handshakeProtocol: 'https://didcomm.org/didexchange/1.1',
        alias: 'Invitation',
        invitationMode: 'once',
        accept: 'auto',
        public: false,
        invitationStatus: 'active',
        invitationDescription:
          "Invitation for Driver's License credential issuance.",
        invitationLabel: settingsState.organizationName,
        invitationRole: 'Holder',
        usesAllowed: 1,
      })
    } else {
      // (AmmonBurgi) We need to reset the invitation so the modal doesn't proceed before a new invitation is received.
      dispatch(setInvitationSelected({}))
      dispatch(setInvitationURL(''))

      setUniversalModalIsOpen((o) => !o)
      props.sendRequest('OUT_OF_BAND', 'CREATE_INVITATION', {
        handshakeProtocol: 'https://didcomm.org/didexchange/1.1',
        alias: 'Invitation',
        invitationMode: 'once',
        accept: 'auto',
        public: false,
        invitationStatus: 'active',
        invitationDescription: 'Invitation for Universal credential issuance.',
        invitationLabel: settingsState.organizationName,
        invitationRole: 'Holder',
        usesAllowed: 1,
      })
    }
  }

  //(RomanStepanyan) Converting CSV file to an array of objects
  function csvToArray(str, delimiter = ',', fileName) {
    const rows = str.split(/(?:\r\n|\n)+/).filter((el) => {
      return el.length !== 0
    })
    const headers = rows.splice(0, 1)[0].split(',')

    // They did not set the header correctly
    if (!headers.includes('email')) {
      dispatch(
        setNotificationState({
          message: `Please include an "email" header in your CSV!`,
          type: 'warning',
        })
      )
      return false
    }

    const arr = rows.map(function (row) {
      //Splitting and removing white spaces
      const values = row.replace(/\s/g, '').split(delimiter)
      //Assemble the object
      const el = headers.reduce((object, header, index) => {
        object[header] = values[index]
        return object
      }, {})
      return el
    })
    setCsvData(arr)
    // Reset Bulk emails to original state (remove any error messages)
    setBulkEmails({
      emailsSuccess: [],
      emailsFailure: [],
      invalidEmails: [],
    })

    // The Csv file successfully imported
    dispatch(
      setNotificationState({
        message: `Your CSV file, ${fileName}, has been imported. Next, click on "Send Emails".`,
        type: 'notice',
      })
    )
  }

  //(RomanStepanyan) CSV file upload
  let csvFileSelectHandler = (event) => {
    event.preventDefault()
    const file = event.target.files[0]

    // The File Exists
    if (file) {
      // Read in the csv
      const reader = new FileReader()
      reader.onload = function (e) {
        csvToArray(reader.result, ',', file.name)
      }
      reader.readAsText(file)
    }
  }

  const handleCsvSubmit = async (e) => {
    e.preventDefault()

    // (eldersonar) Check for the public DID
    if (schemasState.issuerDID) {
      onBtnClick()
      if (csvData) {
        //(RomanStepanyan) Sending an array with converted CSV data
        Axios({
          method: 'POST',
          data: {
            emails: csvData,
          },
          url: '/api/emails/verify',
        }).then((res) => {
          // 100% Failure
          if (res.data.error) {
            dispatch(
              setNotificationState({ message: res.data.error, type: 'error' })
            )
            // Something in the middle
          } else if (
            res.data.invalidEmails &&
            res.data.emailsSuccess &&
            res.data.emailsFailure
          ) {
            setBulkEmails(res.data)
            dispatch(
              setNotificationState({
                message: `Bulk credential issuance was partially successful, please see errors below`,
                type: 'warning',
              })
            )
          }
          // 100 % Success
          else {
            dispatch(
              setNotificationState({
                message: 'Bulk credential issuance was successful!',
                type: 'notice',
              })
            )
          }
        })
      } else {
        dispatch(
          setNotificationState({
            message:
              'Bulk credential issuance failed: No CSV file has been selected.',
            type: 'error',
          })
        )
      }
    } else {
      dispatch(
        setNotificationState({
          message: "Error: Public DID is not set. Invitations can't be sent.",
          type: 'error',
        })
      )
    }

    //Unselect the file
    document.getElementById('fileInputForm').reset()
    //Redisable File
    submitBtn.current.setAttribute('disabled', 'disabled')
  }

  return (
    <>
      {/* welcome to the ___ enterprise issuer */}
      <PageHeader title={organizationName} />
      <PageSection>
        <CredentialSelect />
      </PageSection>
      <PageSection>
        {connected ? (
          waitingForVerification ? (
            verificationStatus !== undefined ? (
              verificationStatus ? (
                props.verifiedCredential ? (
                  <>
                    <SchemaSelect />
                  </>
                ) : (
                  <>
                    <SchemaSelect />
                  </>
                )
              ) : (
                <>
                  <p>
                    There was a problem verifying your credential. Please try
                    again or contact support by clicking on the link below:
                  </p>
                  {/* <a href="https://indicio.tech/proven-support/">
                      <SubmitBtn>Contact Support</SubmitBtn>
                    </a> */}
                  {/* <HeaderVerify>
                      Credential Issuance and Verification
                    </HeaderVerify> */}
                  {/* <p>
                    Please wait for a credential to be issued or choose a
                    credential to verify
                  </p> */}
                  <SchemaSelect />
                </>
              )
            ) : (
              <>
                <p>
                  You will now receive a request on your mobile app to send your
                  credential to us for verification.
                </p>
              </>
            )
          ) : (
            <SchemaSelect />
          )
        ) : (
          <SchemaSelect />
        )}
      </PageSection>
      <PageSection>
        <HomeHeader>Bulk Import Mails</HomeHeader>
        <IconHelp
          data-tip
          data-for="logoTip"
          data-delay-hide="250"
          data-multiline="true"
          alt="Help"
        />
        <ReactTooltip
          id="logoTip"
          effect="solid"
          type="info"
          backgroundColor={useTheme().primary_color}
        >
          <span>
            Your CSV should contain at least
            <br />
            one column header titled "email"
          </span>
        </ReactTooltip>
        <p>
          Proven provides several ways to issue email credentials. Users can
          request an email invitation using the self-serve UI, administrators
          send invitations one-by-one or by uploading a CSV file in the admin
          UI, and developers request invitations via the API.
        </p>
        <Form onSubmit={handleCsvSubmit} id="fileInputForm">
          <input
            type="file"
            accept=".csv"
            onChange={csvFileSelectHandler}
            id="input-bulk-import-mails"
          />
          <button
            type="submit"
            ref={submitBtn}
            disabled
            id="button-send-emails"
          >
            Send Emails
          </button>
        </Form>
        {/* If any are present, display a scrollable section */}
        {bulkEmails.emailsSuccess.length > 0 ||
        bulkEmails.emailsFailure.length > 0 ||
        bulkEmails.invalidEmails.length > 0 ? (
          <BulkEmailErrors>
            {/* Only Display the section if there are emails that were invaild */}
            {bulkEmails.invalidEmails.length > 0 ? (
              <div>
                <p>
                  <b>Invalid emails not sent:</b>{' '}
                  {bulkEmails.invalidEmails.length}
                </p>
                <ul>
                  {bulkEmails.invalidEmails.map((email) => (
                    <li key={email}>{email}</li>
                  ))}
                </ul>
              </div>
            ) : (
              <></>
            )}

            {/* Only Display the section if there are emails that were  unsuccessful */}
            {bulkEmails.emailsFailure.length > 0 ? (
              <div>
                <p>
                  <b>Emails unsuccessfully sent:</b>{' '}
                  {bulkEmails.emailsFailure.length}
                </p>
                <ul>
                  {bulkEmails.emailsFailure.map((email) => (
                    <li key={email}>{email}</li>
                  ))}
                </ul>
              </div>
            ) : (
              <></>
            )}

            {/* Only Display the section if there are emails that were successful */}
            {bulkEmails.emailsSuccess.length > 0 ? (
              <div>
                <p>
                  <b>Emails successfully sent:</b>{' '}
                  {bulkEmails.emailsSuccess.length}
                </p>
                <ul>
                  {bulkEmails.emailsSuccess.map((email) => (
                    <li key={email}>{email}</li>
                  ))}
                </ul>
              </div>
            ) : (
              <></>
            )}
          </BulkEmailErrors>
        ) : (
          <></>
        )}
      </PageSection>
      <FormInvitationEmail
        sendRequest={props.sendRequest}
        emailModalIsOpen={emailModalIsOpen}
        closeEmailModal={closeEmailModal}
      />
      <FormDriversLicenseIssuance
        driversLicenseModalIsOpen={driversLicenseModalIsOpen}
        closeDriversLicenseModal={closeDriversLicenseModal}
        sendRequest={props.sendRequest}
      />
      <FormPresentationQR
        verificationModalIsOpen={verificationModalIsOpen}
        closeVerificationQRModal={closeVerificationQRModal}
        sendRequest={props.sendRequest}
      />
      <FormUniversalIssuance
        universalModalIsOpen={universalModalIsOpen}
        closeUniversalModal={closeUniversalModal}
        chosenCredential={chosenCredential.value}
        sendRequest={props.sendRequest}
      />
    </>
  )
}

export default Home
