import {
    GooglePlayButton,
    AppStoreButton,
    ButtonsContainer,
  } from "react-mobile-app-button"
  
export const AppStoreButtons = () => {
const googlePlayStoreUrl = "https://play.google.com/store/apps/details?id=tech.indicio.holdrplus"
const appleAppStoreUrl = "https://apps.apple.com/us/app/holdr/id1620628623"

return (
    <ButtonsContainer>
        <GooglePlayButton
            url={googlePlayStoreUrl}
            theme={"dark"}
            width={"182px"}
            className={"custom-style"}
        />

        <AppStoreButton
            url={appleAppStoreUrl}
            theme={"dark"}
            className={"custom-style"}
        />
    </ButtonsContainer>
    )
}