import Axios from 'axios'
import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setNotificationState } from '../redux/notificationsReducer'

import {
  StyledPopup,
  InputBox,
  Modal,
  ModalHeader,
  ModalContentWrapper,
  ModalContent,
  CloseBtn,
  Actions,
  CancelBtn,
  SubmitBtnModal,
  ModalLabel,
  InputFieldModal,
} from './CommonStylesForms'

function FormInvitationEmail(props) {
  const dispatch = useDispatch()
  const schemasState = useSelector((state) => state.schemas)
  const emailForm = useRef(null)

  const handleSubmit = (e) => {
    e.preventDefault()

    // (eldersonar) Check for the public DID
    if (schemasState.issuerDID) {
      const form = new FormData(emailForm.current)
      try {
        props.sendRequest(
          'EMAIL',
          'EMAIL_VERIFY',
          {
            email: form.get('email'),
          }
        )
      } catch (error) {
        dispatch(
          setNotificationState({ message: error, type: 'error' })
        )
      }
      props.closeEmailModal()
    } else {
      dispatch(
        setNotificationState({
          message: "Error: Public DID not set. Invitation can't be sent.",
          type: 'error',
        })
      )
    }
  }

  function closeEmailModal() {
    props.closeEmailModal()
  }

  return (
    <StyledPopup
      open={props.emailModalIsOpen}
      closeOnDocumentClick
      onClose={closeEmailModal}
    >
      <Modal className="modal">
        <ModalHeader>Send Email Invitation</ModalHeader>
        <ModalContentWrapper>
          <ModalContent>
            <form onSubmit={handleSubmit} ref={emailForm}>
              <InputBox>
                <ModalLabel htmlFor="email">Email Address</ModalLabel>
                <InputFieldModal
                  required
                  type="email"
                  name="email"
                  id="email"
                  placeholder="example@test.com"
                />
              </InputBox>
              <Actions>
                <CancelBtn
                  type="button"
                  onClick={closeEmailModal}
                  id="button-cancel"
                >
                  Cancel
                </CancelBtn>
                <SubmitBtnModal type="submit" id="button-send">
                  Send
                </SubmitBtnModal>
              </Actions>
            </form>
          </ModalContent>
        </ModalContentWrapper>
        <CloseBtn onClick={closeEmailModal} id="button-exit">
          &times;
        </CloseBtn>
      </Modal>
    </StyledPopup>
  )
}

export default FormInvitationEmail
