let localMaintenenceWindow;
//Setup the local window'
try{
  localMaintenenceWindow = JSON.parse(localStorage.getItem('maintenenceWindow'))
  if(localMaintenenceWindow?.startDateTime){
    localMaintenenceWindow.startDateTime =  new Date(localMaintenenceWindow.startDateTime)
  }
  if(localMaintenenceWindow?.endDateTime){
    localMaintenenceWindow.endDateTime = new Date(localMaintenenceWindow.endDateTime)
  }
} catch(e){
  localMaintenenceWindow = false;
}


const defaultTheme = {
  primary_color: '#333545',
  secondary_color: '#FF6701',
  tertiary_color: '#FAD744',
  neutral_color: '#808080',
  negative_color: '#e33636',
  warning_color: '#ff8c42',
  positive_color: '#4CB944',
  text_color: '#555',
  text_light: '#fff',
  border: '1px solid #e3e3e3',
  drop_shadow: '3px 3px 3px rgba(0, 0, 0, 0.3)',
  background_primary: '#fff',
  background_secondary: '#f5f5f5',
}

let localTheme;
try {
  const storedTheme = localStorage.getItem('recentTheme');
  if (storedTheme && storedTheme !== "undefined") {
    localTheme = JSON.parse(storedTheme);
  } else {
    localTheme = defaultTheme;
  }
} catch (error) {
  console.error('Failed to parse theme from localStorage:', error);
  localTheme = defaultTheme;
}

const defaultMaintenenceWindow = {
  active: false,
  startDateTime: null,
  endDateTime: null
}

const initialState = {
  agentConfig: {},
  logo: null,
  organizationName: null,
  smtp: null,
  siteTitle: '',
  theme: localTheme,
  manifest: {
    short_name: undefined,
    name: undefined,
    theme_color: undefined,
    background_color: undefined,
  },
  revocationAllowed: false,
  maintenenceWindow: localMaintenenceWindow ? 
    localMaintenenceWindow : defaultMaintenenceWindow,
  defaultSettings: {
    theme: undefined,
    smtp: undefined,
    organization: undefined,
    manifest: undefined,
    logos: {
      logo: undefined,
      logo192: undefined,
      logo512: undefined,
      favicon: undefined
    }
  },
}

const SET_AGENT_CONFIG = 'SET_AGENT_CONFIG'
const SET_LOGO = 'SET_LOGO'
const SET_ORGANIZATION_NAME = 'SET_ORGANIZATION_NAME'
const SET_SMTP = 'SET_SMTP'
const SET_SITE_TITLE = 'SET_SITE_TITLE'
const SET_THEME = 'SET_THEME'
const SET_MANIFEST = 'SET_MANIFEST'
const SET_REVOCATION_ALLOWED = 'SET_REVOCATION_ALLOWED'
const WALLET_SETTINGS_NEED_CREATION = 'WALLET_SETTINGS_NEED_CREATION'
const SET_MAINTENENCE_WINDOW = 'SET_MAINTENENCE_WINDOW'
const CLEAR_SETTINGS_STATE = 'CLEAR_SETTINGS_STATE'

export function setAgentConfig(config) {
  return {
    type: SET_AGENT_CONFIG,
    payload: config,
  }
}

export function setLogo(logo) {
  return {
    type: SET_LOGO,
    payload: logo,
  }
}
export function setOrganizationName(organization) {
  return {
    type: SET_ORGANIZATION_NAME,
    payload: organization,
  }
}
export function setSiteTitle(title) {
  return {
    type: SET_SITE_TITLE,
    payload: title,
  }
}
export function setSMTP(smtp) {
  return {
    type: SET_SMTP,
    payload: smtp,
  }
}
export function setTheme(theme) {
  return {
    type: SET_THEME,
    payload: theme,
  }
}
export function setManifest(manifest) {
  return {
    type: SET_MANIFEST,
    payload: manifest,
  }
}

export function setRevocationAllowed(revocationAllowed) {
  return {
    type: SET_REVOCATION_ALLOWED,
    payload: revocationAllowed,
  }
}

export function setDefaultSettings(defaultSettings) {
  return {
    type: WALLET_SETTINGS_NEED_CREATION,
    payload: defaultSettings,
  }
}

export function clearSettingsState() {
  return {
    type: CLEAR_SETTINGS_STATE,
    payload: {},
  }
}

export function setMaintenenceWindow(maintenenceWindow) {
  // Store the window object locally
  localStorage.setItem("maintenenceWindow",JSON.stringify(maintenenceWindow))

  return {
    type: SET_MAINTENENCE_WINDOW,
    payload: maintenenceWindow,
  }
}

export default function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_AGENT_CONFIG:
      return { ...state, agentConfig: action.payload }
      
    case SET_LOGO:
      return { ...state, logo: action.payload }

    case SET_ORGANIZATION_NAME:
      return { ...state, organizationName: action.payload }

    case SET_SITE_TITLE:
      return { ...state, siteTitle: action.payload }

    case SET_SMTP:
      return { ...state, smtp: action.payload }

    case SET_THEME:
      return { ...state, theme: action.payload }

    case SET_MANIFEST:
      return { ...state, manifest: action.payload }

    case SET_REVOCATION_ALLOWED:
      return { ...state, revocationAllowed: action.payload }

    case WALLET_SETTINGS_NEED_CREATION:
      return { ...state, defaultSettings: action.payload }

    case CLEAR_SETTINGS_STATE:
      return { ...state, organizationName: null, smtp: null }
    
    case SET_MAINTENENCE_WINDOW:
      return { ...state, maintenenceWindow:action.payload }

    default:
      return state
  }
}
