import { useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ScheduledMaintenenceDateSelector from './components/ScheduledMaintenenceDateSelector'
import ReactTooltip from 'react-tooltip'
import styled, { useTheme } from 'styled-components'

import PageHeader from './PageHeader'
import PageSection from './PageSection'
import ThemeSettings from './SettingsTheme'

import { setNotificationState } from '../redux/notificationsReducer'

import {
  setLogo,
  setOrganizationName,
  setManifest,
  setSiteTitle,
} from '../redux/settingsReducer'

import { IconHelp } from './CommonStylesTables'
import { handleImageSrc } from './util'

const H3 = styled.h3`
  margin: 5px 0;
`

const SMTPHeader = styled.h3`
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 0;
`

const SettingsHeader = styled.h2`
  display: inline-block;
  margin-right: 10px;
`

const SaveBtn = styled.button`
  width: 80px;
  background: ${(props) => props.theme.primary_color};
  padding: 10px;
  color: ${(props) => props.theme.text_light};
  border: none;
  float: right;
  box-shadow: ${(props) => props.theme.drop_shadow};
`

const SubmitFormBtn = styled.button``
const BlockInput = styled.input`
  display: block;
  margin-bottom: 15px;
`

const Input = styled.input``

const Form = styled.form`
  overflow: hidden;
  margin-bottom: 10px;
`

function Settings(props) {
  const dispatch = useDispatch()
  const settingsState = useSelector((state) => state.settings)
  let smtpConf = settingsState.smtp

  // File state
  const [selectedFavicon, setSelectedFile] = useState('')
  const [selectedLogo, setSelectedLogo] = useState('')
  const [selectedLogo192, setSelectedLogo192] = useState('')
  const [selectedLogo512, setSelectedLogo512] = useState('')
  const [logoFileName, setLogoFileName] = useState('Choose file')
  const [faviconFileName, setFaviconFileName] = useState('Choose file')
  const [logo192FileName, setLogo192FileName] = useState('Choose file')
  const [logo512FileName, setLogo512FileName] = useState('Choose file')

  // SMTP input references
  const smtpForm = useRef(null)
  const host = useRef(null)
  const mailUsername = useRef(null)
  const userEmail = useRef(null)
  const userPassword = useRef(null)
  const port = useRef(null)

  // Organization input references
  const organizationForm = useRef(null)
  const organizationName = useRef(null)
  const siteTitle = useRef(null)

  // Manifest input references
  const manifestDetailsForm = useRef(null)
  const manifestShortName = useRef(null)
  const manifestName = useRef(null)
  const manifestThemeColor = useRef(null)
  const manifestBackgroundColor = useRef(null)

  const handleSubmit = (e) => {
    e.preventDefault()
    e.target.reset()
  }

  // Setting the style to the default value
  const undoStyle = (key) => {
    props.undoStyle(key)
    props.removeStylesFromArray(key)
  }

  // Save style settings
  function saveStyle() {
    props.saveTheme()
    props.clearStylesArray()
  }

  // Save SMTP settings
  const handleSMTP = (e) => {
    e.preventDefault()

    const form = new FormData(smtpForm.current)

    if (!form.get('host') || !form.get('mailUsername') || !form.get('email')) {
      dispatch(
        setNotificationState({
          message:
            'Host, Mail Username, User Email and Password are required fields. See the tooltip for more info.',
          type: 'error',
        })
      )
    } else {
      const smtpConfigs = {
        host: form.get('host'),
        port: form.get('port'),
        auth: {
          email: form.get('email'),
          pass: form.get('password'),
          mailUsername: form.get('mailUsername'),
        },
      }

      props.sendRequest('SETTINGS', 'SET_SMTP', smtpConfigs)

      // (eldersonar) Wait for 2 seconds to update the SMTP object
      setTimeout(() => props.sendRequest('SETTINGS', 'GET_SMTP'), 2000)
    }
  }

  // Save manifest settings
  const handleManifest = (e) => {
    e.preventDefault()

    const form = new FormData(manifestDetailsForm.current)

    const manifestConfigs = {
      short_name: form.get('short_manifest_name'),
      name: form.get('manifest_name'),
      theme_color: form.get('theme_color'),
      background_color: form.get('background_color'),
    }

    console.log(manifestConfigs)
    props.sendRequest('SETTINGS', 'SET_MANIFEST', manifestConfigs)
  }

  // Save organization details
  const handleOrganizationDetails = (e) => {
    e.preventDefault()
    const form = new FormData(
      organizationForm.current || settingsState.defaultSettings.organization
    )
    const name = {
      organizationName: form.get('organizationName'),
      title: form.get('siteTitle'),
    }
    props.sendRequest('SETTINGS', 'SET_ORGANIZATION', name)
  }

  // Logo upload

  // Setting up file and file name
  let logoSelectHandler = (event) => {
    const file = event.target.files[0]

    // The image is over 0.5Mb size. It will grow 33% (1mb) as it's converted to base64
    if (file && file.size > 500300) {
      dispatch(
        setNotificationState({
          message: 'The image is over 0.5Mb',
          type: 'error',
        })
      )
      return
    }

    if (file) {
      // Converting the image to base64
      const reader = new FileReader()
      reader.onloadend = function () {
        setSelectedLogo(reader.result)
      }
      reader.readAsDataURL(file)

      setLogoFileName(event.target.files[0].name)
    }
  }

  const handleLogoSubmit = async (e) => {
    e.preventDefault()
    if (selectedLogo) {
      const image = {
        name: logoFileName,
        type: 'logo',
        image: selectedLogo,
      }

      props.sendRequest('IMAGES', 'SET_LOGO', image)
    } else {
      dispatch(
        setNotificationState({
          message: 'The image is not selected.',
          type: 'error',
        })
      )
    }
  }

  const handleDefaultLogoSelect = async (e) => {
    e.preventDefault()
    setSelectedLogo(
      handleImageSrc(settingsState.defaultSettings.logos.logo.image.data)
    )
    setLogoFileName(settingsState.defaultSettings.logos.logo.name)
  }

  // Favicon upload

  // Setting up file and file name
  let faviconSelectHandler = (event) => {
    const file = event.target.files[0]
    console.log(file)

    // The image is over 0.2Mb size. It will grow 33% (1mb) as it's converted to base64
    if (file && file.size > 200200) {
      dispatch(
        setNotificationState({
          message: 'The image is over 0.2Mb.',
          type: 'error',
        })
      )
      return
    }

    if (file) {
      // Converting the image to base64
      const reader = new FileReader()
      reader.onloadend = function () {
        setSelectedFile(reader.result)
      }
      reader.readAsDataURL(file)

      setFaviconFileName(event.target.files[0].name)
    }
  }

  const handleFaviconSubmit = async (e) => {
    e.preventDefault()
    if (selectedFavicon) {
      console.log(selectedFavicon)
      const image = {
        name: faviconFileName,
        type: 'icon',
        image: selectedFavicon,
      }

      props.sendRequest('IMAGES', 'SET_FAVICON', image)
    } else {
      dispatch(
        setNotificationState({
          message: 'The image is not selected.',
          type: 'error',
        })
      )
    }
  }

  const handleDefaultFaviconSelect = async (e) => {
    e.preventDefault()
    setSelectedFile(
      handleImageSrc(settingsState.defaultSettings.logos.favicon.image.data)
    )
    setFaviconFileName(settingsState.defaultSettings.logos.favicon.name)
  }

  // Logo192 upload

  let logo192SelectHandler = (event) => {
    const file = event.target.files[0]

    // The image is over 0.1Mb size. It will grow 33% (1mb) as it's converted to base64
    if (file && file.size > 100100) {
      dispatch(
        setNotificationState({
          message: 'The image is over 0.1Mb.',
          type: 'error',
        })
      )
      return
    }

    if (file) {
      // Converting the image to base64
      const reader = new FileReader()
      reader.onloadend = function () {
        setSelectedLogo192(reader.result)
      }
      reader.readAsDataURL(file)

      setLogo192FileName(event.target.files[0].name)
    }
  }

  const handleLogo192Submit = async (e) => {
    e.preventDefault()
    if (selectedLogo192) {
      const image = {
        name: logo192FileName,
        type: 'icon',
        image: selectedLogo192,
      }

      props.sendRequest('IMAGES', 'SET_LOGO192', image)
    } else {
      dispatch(
        setNotificationState({
          message: 'The image is not selected.',
          type: 'error',
        })
      )
    }
  }

  const handleDefaultLogo192Select = async (e) => {
    e.preventDefault()
    setSelectedLogo192(
      handleImageSrc(settingsState.defaultSettings.logos.logo192.image.data)
    )
    setLogo192FileName(settingsState.defaultSettings.logos.logo192.name)
  }

  // Logo512 upload

  let logo512SelectHandler = (event) => {
    const file = event.target.files[0]

    // The image is over 0.2Mb size. It will grow 33% (1mb) as it's converted to base64
    if (file && file.size > 200200) {
      dispatch(
        setNotificationState({
          message: 'The image is over 0.2Mb',
          type: 'error',
        })
      )
      return
    }

    if (file) {
      // Converting the image to base64
      const reader = new FileReader()
      reader.onloadend = function () {
        setSelectedLogo512(reader.result)
      }
      reader.readAsDataURL(file)

      setLogo512FileName(event.target.files[0].name)
    }
  }

  const handleLogo512Submit = async (e) => {
    e.preventDefault()
    if (selectedLogo512) {
      const image = {
        name: logo512FileName,
        type: 'icon',
        image: selectedLogo512,
      }

      props.sendRequest('IMAGES', 'SET_LOGO512', image)
    } else {
      dispatch(
        setNotificationState({
          message: 'The image is not selected.',
          type: 'error',
        })
      )
    }
  }

  const handleDefaultLogo512Select = async (e) => {
    e.preventDefault()
    setSelectedLogo512(
      handleImageSrc(settingsState.defaultSettings.logos.logo512.image.data)
    )
    setLogo512FileName(settingsState.defaultSettings.logos.logo512.name)
  }

  const handleSaveAllSettings = (e) => {
    e.preventDefault()

    const getImageData = (imageSelector, fileName, type) => {
      if (imageSelector) {
        return { name: fileName, type, image: imageSelector }
      } else {
        dispatch(
          setNotificationState({
            message: 'The image is not selected.',
            type: 'error',
          })
        )
        return null
      }
    }

    let form = new FormData(manifestDetailsForm.current)
    const manifest = {
      short_name: form.get('short_manifest_name'),
      name: form.get('manifest_name'),
      theme_color: form.get('theme_color'),
      background_color: form.get('background_color'),
    }
    manifestDetailsForm.current.reset()
    form = new FormData(
      organizationForm.current || settingsState.defaultSettings.organization
    )
    const org = {
      organizationName: form.get('organizationName'),
      title: form.get('siteTitle'),
    }
    organizationForm.current.reset()
    const logoImage = getImageData(selectedLogo, logoFileName, 'logo')
    const logo192Image = getImageData(selectedLogo192, logo192FileName, 'icon')
    const logo512Image = getImageData(selectedLogo512, logo512FileName, 'icon')
    const faviconImage = getImageData(selectedFavicon, faviconFileName, 'icon')
    saveStyle()
    props.loadingList.push('SETTINGS_UPDATED')
    const allSettings = {
      manifest,
      organization: org,
      logo: logoImage,
      logo192: logo192Image,
      logo512: logo512Image,
      favicon: faviconImage,
    }
    props.sendRequest('SETTINGS', 'SET_ALL_SETTINGS', allSettings)
    dispatch(setManifest(manifest))
    dispatch(setOrganizationName(org.organizationName))
    dispatch(setSiteTitle(org.title))
    dispatch(setLogo(logoImage.image))
  }
  const theme_color = useTheme()
  const DefaultSubmitBtn = ({
    type,
    id,
    shouldRender = props.saveAllButton,
    children,
  }) => {
    return shouldRender ? (
      <SubmitFormBtn type={type} id={id}>
        {children}
      </SubmitFormBtn>
    ) : null
  }

  return (
    <div id="settings">
      {props.saveAllButton ? (
        <PageHeader
          title={
            'Settings: First Time Wallet Setup (Admin or Super-Admin Only)'
          }
        />
      ) : (
        <PageHeader title={'Settings'} />
      )}
      <PageSection>
        <SettingsHeader>Organization Details</SettingsHeader>
        <IconHelp
          data-tip
          data-for="organizationTip"
          data-delay-hide="250"
          data-multiline="true"
          alt="Help"
        />
        <ReactTooltip
          id="organizationTip"
          effect="solid"
          type="info"
          backgroundColor={useTheme().primary_color}
        >
          <span>
            Organization name is used in
            <br />
            the UI and email messages.
            <br />
            <br />
            A website title identifies what
            <br />
            the web page is about for both
            <br />
            web users and search engines.
          </span>
        </ReactTooltip>
        <Form onSubmit={handleSubmit} ref={organizationForm}>
          <H3>Organization Name</H3>
          <BlockInput
            name="organizationName"
            defaultValue={
              settingsState.organizationName ||
              settingsState.defaultSettings?.organization?.organizationName ||
              ''
            }
            ref={organizationName}
            id="input-website-title"
          />
          <H3>Website Title</H3>
          <BlockInput
            name="siteTitle"
            defaultValue={
              settingsState.siteTitle ||
              settingsState.defaultSettings?.organization?.title ||
              ''
            }
            ref={siteTitle}
          />
          <SaveBtn onClick={handleOrganizationDetails} id="button-save">
            Save
          </SaveBtn>
        </Form>
      </PageSection>
      <PageSection>
        <SettingsHeader>Change Logo</SettingsHeader>
        <IconHelp
          data-tip
          data-for="logoTip"
          data-delay-hide="250"
          data-multiline="true"
          alt="Help"
        />
        <ReactTooltip
          id="logoTip"
          effect="solid"
          type="info"
          backgroundColor={useTheme().primary_color}
        >
          <span>
            Organization logo is used in
            <br />
            the UI and email messages.
            <br />
            The max size is 500KB.
          </span>
        </ReactTooltip>
        <Form onSubmit={handleLogoSubmit}>
          <Input
            type="file"
            accept=".jpeg, .jpg, .png, .gif, .webp"
            onChange={logoSelectHandler}
            id="input-change-logo-choose-file"
          ></Input>
          <SubmitFormBtn type="submit" id="button-change-logo-upload">
            Upload
          </SubmitFormBtn>
        </Form>
        <Form onSubmit={handleDefaultLogoSelect}>
          <DefaultSubmitBtn
            type="submit"
            id="button-update-default-logo-upload"
          >
            Select Default Logo
          </DefaultSubmitBtn>
        </Form>
      </PageSection>

      <PageSection>
        <SettingsHeader>Change Logo 192x192</SettingsHeader>
        <IconHelp
          data-tip
          data-for="logo192Tip"
          data-delay-hide="250"
          data-multiline="true"
          alt="Help"
        />
        <ReactTooltip
          id="logo192Tip"
          effect="solid"
          type="info"
          backgroundColor={useTheme().primary_color}
        >
          <span>
            logo192.png is the icon used to show on the tab
            <br />
            of mobile device. logo192.png is part of PWA.
            <br />
            The max size is 100KB.
          </span>
        </ReactTooltip>
        <Form onSubmit={handleLogo192Submit}>
          <Input
            type="file"
            accept=".png"
            onChange={logo192SelectHandler}
            id="input-192x192-change-logo-choose-file"
          ></Input>
          <SubmitFormBtn type="submit" id="button-192x192-change-logo-upload">
            Upload
          </SubmitFormBtn>
        </Form>
        <Form onSubmit={handleDefaultLogo192Select}>
          <DefaultSubmitBtn
            type="submit"
            id="button-update-default-logo192-upload"
          >
            Select Default Logo192
          </DefaultSubmitBtn>
        </Form>
      </PageSection>

      <PageSection>
        <SettingsHeader>Change Logo 512x512</SettingsHeader>
        <IconHelp
          data-tip
          data-for="logo512Tip"
          data-delay-hide="250"
          data-multiline="true"
          alt="Help"
        />
        <ReactTooltip
          id="logo512Tip"
          effect="solid"
          type="info"
          backgroundColor={useTheme().primary_color}
        >
          <span>
            logo192.png is the icon used to show on the tab
            <br />
            of mobile device. logo512.png is part of PWA.
            <br />
            The max size is 200KB.
          </span>
        </ReactTooltip>
        <Form onSubmit={handleLogo512Submit}>
          <Input
            type="file"
            accept=".png"
            onChange={logo512SelectHandler}
            id="input-512x512-change-logo-choose-file"
          ></Input>
          <SubmitFormBtn type="submit" id="button-512x512-change-logo-upload">
            Upload
          </SubmitFormBtn>
        </Form>
        <Form onSubmit={handleDefaultLogo512Select}>
          <DefaultSubmitBtn
            type="submit"
            id="button-update-default-logo512-upload"
          >
            Select Default Logo512
          </DefaultSubmitBtn>
        </Form>
      </PageSection>

      <PageSection>
        <SettingsHeader>Update favicon.ico</SettingsHeader>
        <IconHelp
          data-tip
          data-for="faviconTip"
          data-delay-hide="250"
          data-multiline="true"
          alt="Help"
        />
        <ReactTooltip
          id="faviconTip"
          effect="solid"
          type="info"
          backgroundColor={useTheme().primary_color}
        >
          <span>
            Organization favicon is used to
            <br />
            represents a website in web browsers.
            <br />
            The max size is 200KB.
          </span>
        </ReactTooltip>
        <Form onSubmit={handleFaviconSubmit}>
          <Input
            type="file"
            accept=".ico"
            onChange={faviconSelectHandler}
            id="input-update-favicon-choose-file"
          ></Input>
          <SubmitFormBtn type="submit" id="button-update-favicon-upload">
            Upload
          </SubmitFormBtn>
        </Form>
        <Form onSubmit={handleDefaultFaviconSelect}>
          <DefaultSubmitBtn
            type="submit"
            id="button-update-default-favicon-upload"
          >
            Select Default Favicon
          </DefaultSubmitBtn>
        </Form>
      </PageSection>

      <PageSection>
        <SettingsHeader>Web App Manifest</SettingsHeader>
        <IconHelp
          data-tip
          data-for="manifestTip"
          data-delay-hide="250"
          data-multiline="true"
          alt="Help"
        />
        <ReactTooltip
          id="manifestTip"
          effect="solid"
          type="info"
          backgroundColor={useTheme().primary_color}
        >
          <span>
            The web app manifest provides information about
            <br />
            a web application in a JSON text file to provide
            <br />
            users with quicker access and a richer experience.
          </span>
        </ReactTooltip>
        <Form onSubmit={handleSubmit} ref={manifestDetailsForm}>
          <H3>Short Name</H3>
          <BlockInput
            type="text"
            name="short_manifest_name"
            defaultValue={
              settingsState.manifest?.short_name ||
              settingsState.defaultSettings.manifest?.short_name ||
              ''
            }
            ref={manifestShortName}
            id="input-manifest-short-name"
          />
          <H3>Full Name</H3>
          <BlockInput
            type="text"
            name="manifest_name"
            defaultValue={
              settingsState.manifest?.name ||
              settingsState.defaultSettings.manifest?.name ||
              ''
            }
            ref={manifestName}
            id="input-manifest-full-name"
          />
          <H3>Theme Color</H3>
          <BlockInput
            type="text"
            name="theme_color"
            defaultValue={
              settingsState.manifest?.theme_color ||
              settingsState.defaultSettings.manifest?.theme_color ||
              ''
            }
            ref={manifestThemeColor}
            id="input-manifest-theme-color"
          />
          <H3>Background Color</H3>
          <BlockInput
            type="text"
            name="background_color"
            defaultValue={
              settingsState.manifest?.background_color ||
              settingsState.defaultSettings.manifest?.background_color ||
              ''
            }
            ref={manifestBackgroundColor}
            id="input-manifest-background-color"
          />
          <SaveBtn onClick={handleManifest} id="button-manifest-save">
            Save
          </SaveBtn>
        </Form>
      </PageSection>
      <PageSection>
        <SettingsHeader>SMTP Configuration</SettingsHeader>
        <IconHelp
          data-tip
          data-for="smtpTip"
          data-delay-hide="250"
          data-multiline="true"
          alt="Help"
        />
        <ReactTooltip
          id="smtpTip"
          effect="solid"
          type="info"
          backgroundColor={theme_color.primary_color}
        >
          <span>
            The SMTP configuration is used for sending
            <br />
            new user and password reset emails.
          </span>
        </ReactTooltip>
        <Form onSubmit={handleSubmit} ref={smtpForm}>
          <SMTPHeader>Host</SMTPHeader>
          <IconHelp
            data-tip
            data-for="smtpHostTip"
            data-delay-hide="250"
            data-multiline="true"
            alt="Help"
          />
          <ReactTooltip
            id="smtpHostTip"
            effect="solid"
            type="info"
            backgroundColor={theme_color.primary_color}
          >
            <span>
              This is the hostname or IP address to connect to.
              <br />
              <br />
              Required field.
            </span>
          </ReactTooltip>
          <BlockInput
            name="host"
            ref={host}
            defaultValue={smtpConf ? (smtpConf.host ? smtpConf.host : '') : ''}
            id="input-smtp-host"
          />
          <SMTPHeader>Mail Username</SMTPHeader>
          <IconHelp
            data-tip
            data-for="smtpMailUsernameTip"
            data-delay-hide="250"
            data-multiline="true"
            alt="Help"
          />
          <ReactTooltip
            id="smtpMailUsernameTip"
            effect="solid"
            type="info"
            backgroundColor={theme_color.primary_color}
          >
            <span>
              This is the username.
              <br />
              For Gmail accounts mail username must be the same as the user
              email.
              <br />
              <br />
              Required field.
            </span>
          </ReactTooltip>
          <BlockInput
            name="mailUsername"
            ref={mailUsername}
            defaultValue={
              smtpConf ? (smtpConf.auth ? smtpConf.auth.mailUsername : '') : ''
            }
            id="input-smtp-mail-username"
          />
          <SMTPHeader>User email</SMTPHeader>
          <IconHelp
            data-tip
            data-for="smtpUserEmailTip"
            data-delay-hide="250"
            data-multiline="true"
            alt="Help"
          />
          <ReactTooltip
            id="smtpUserEmailTip"
            effect="solid"
            type="info"
            backgroundColor={theme_color.primary_color}
          >
            <span>
              This is the user email address.
              <br />
              <br />
              Required field.
            </span>
          </ReactTooltip>
          <BlockInput
            name="email"
            ref={userEmail}
            defaultValue={
              smtpConf ? (smtpConf.auth ? smtpConf.auth.email : '') : ''
            }
            id="input-smtp-email"
          />
          <SMTPHeader>User password</SMTPHeader>
          <IconHelp
            data-tip
            data-for="smtpPasswordTip"
            data-delay-hide="250"
            data-multiline="true"
            alt="Help"
          />
          <ReactTooltip
            id="smtpPasswordTip"
            effect="solid"
            type="info"
            backgroundColor={theme_color.primary_color}
          >
            <span>
              This is the password for the user email.
              <br />
              For Gmail SMTP cofiguration use the APP Password.
              <br />
              <br />
              Optional field.
            </span>
          </ReactTooltip>
          <BlockInput
            type="password"
            name="password"
            ref={userPassword}
            defaultValue={smtpConf ? (smtpConf.auth ? '************' : '') : ''}
            id="input-smtp-password"
          />
          <SMTPHeader>Port</SMTPHeader>
          <IconHelp
            data-tip
            data-for="smtpPortTip"
            data-delay-hide="250"
            data-multiline="true"
            alt="Help"
          />
          <ReactTooltip
            id="smtpPortTip"
            effect="solid"
            type="info"
            backgroundColor={theme_color.primary_color}
          >
            <span>
              This is the port to connect to.
              <br />
              It defaults to 587 if "secure" is false or 465 if true.
              <br />
              <br />
              Optional field.
            </span>
          </ReactTooltip>
          <BlockInput
            name="port"
            placeholder="587 or 465"
            ref={port}
            defaultValue={smtpConf ? (smtpConf.port ? smtpConf.port : '') : ''}
            id="input-smtp-port"
          />
          <SaveBtn onClick={handleSMTP} id="button-smtp-save">
            Save
          </SaveBtn>
        </Form>
      </PageSection>
      {props.shouldDisplayMaintenenceWindow && (
        <PageSection>
          <SettingsHeader>Scheduled Maintenence</SettingsHeader>
          <IconHelp
            data-tip
            data-for="scheduledMaintenenceNotification"
            data-delay-hide="250"
            data-multiline="true"
            alt="Help"
          />
          <ReactTooltip
            id="scheduledMaintenenceNotification"
            effect="solid"
            type="info"
            backgroundColor={theme_color.primary_color}
          >
            <span>
              Future maintenence? Update date and time to cause a
              <br />
              notification to be shown on all admin routes.
            </span>
          </ReactTooltip>
          <ScheduledMaintenenceDateSelector
            saveMaintenenceWindow={props.saveMaintenenceWindow}
          />
        </PageSection>
      )}

      <PageSection>
        <ThemeSettings
          test={'test'}
          theme={props.theme}
          setNotificationState={props.setNotificationState}
          saveStyle={saveStyle}
          undoStyle={undoStyle}
          handleSubmit={handleSubmit}
          stylesArray={props.stylesArray}
          updateTheme={props.updateTheme}
          addStylesToArray={props.addStylesToArray}
        />
      </PageSection>
      {props.saveAllButton && (
        <SaveBtn onClick={handleSaveAllSettings} id="button-theme-save-all">
          Save All
        </SaveBtn>
      )}
    </div>
  )
}

export default Settings
