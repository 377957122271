import { useSelector } from 'react-redux'

import PageHeader from './PageHeader'
import PageSection from './PageSection'

import { DataTable, DataRow, DataHeader, DataCell } from './CommonStylesTables'

function Presentations(props) {
  let presentations = useSelector(
    (state) => state.presentations.presentationReports
  )
  const contacts = useSelector((state) => state.contacts.contacts)

  // (AmmonBurgi) Match up the connection_id's and give each presentation a contact_label. Used for-loop for speed.
  for (let i = 0; i < presentations.length; i++) {
    for (let j = 0; j < contacts.length; j++) {
      if (
        presentations[i].connection_id ===
        contacts[j].Connections[0].connection_id
      ) {
        presentations[i].contact_label = contacts[j].label
        break
      }
    }
  }

  function openPresentation(history, id) {
    if (history !== undefined) {
      history.push('/admin/presentations/' + id)
    }
  }

  const presentationRows = presentations.map((presentation_record) => {
    let requestName = ''

    const presentation_id = presentation_record.presentation_exchange_id
    const presentationState =
      presentation_record.state.replaceAll('_', ' ') || ''

    const dateCreated =
      new Date(presentation_record.created_at).toLocaleString() || ''

    const presentationRequest = presentation_record.presentation_request
    if (
      presentation_record.presentation_request !== null &&
      presentation_record.presentation_request !== undefined &&
      !presentationRequest.options
    ) {
      let schemaId = null

      console.log('presentationRequest:', presentationRequest)

      const requestedAttributeKeys = Object.keys(
        presentationRequest.requested_attributes
      )

      for (const attributeKey of requestedAttributeKeys) {
        const attribute = presentationRequest.requested_attributes[attributeKey]
        if (attribute.restrictions && attribute.restrictions.length > 0) {
          schemaId = attribute.restrictions[0].schema_id
          break
        }
      }

      requestName = schemaId
        ? schemaId.split(':')[2].replaceAll('_', ' ')
        : `Self attested`
    } else if (presentationRequest?.options) {
      requestName =
        presentationRequest.presentation_definition.input_descriptors[0].name
    }

    return (
      <DataRow
        key={presentation_id}
        onClick={() => {
          openPresentation(props.history, presentation_id)
        }}
      >
        <DataCell>{presentation_record.contact_label}</DataCell>
        <DataCell>{requestName}</DataCell>
        <DataCell className="title-case">{presentationState}</DataCell>
        <DataCell>{dateCreated}</DataCell>
      </DataRow>
    )
  })

  return (
    <>
      <div id="presentations">
        <PageHeader title={'Presentations'} />
        <PageSection>
          <DataTable>
            <thead>
              <DataRow>
                <DataHeader>Label</DataHeader>
                <DataHeader>Request Name</DataHeader>
                <DataHeader>Status</DataHeader>
                <DataHeader>Date Issued</DataHeader>
              </DataRow>
            </thead>
            <tbody>{presentationRows}</tbody>
          </DataTable>
        </PageSection>
      </div>
    </>
  )
}

export default Presentations
