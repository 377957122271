import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import QRCode from 'qrcode.react'
import styled from 'styled-components'

import {
  setInvitationSelected,
  setInvitationURL,
} from '../redux/invitationsReducer'
import { setSchemaSelected } from '../redux/schemasReducer.js'
import { setNotificationState } from '../redux/notificationsReducer'

import {
  StyledPopup,
  InputBox,
  ModalMedium,
  ModalHeader,
  ModalContentWrapper,
  ModalContent,
  CloseBtn,
  Actions,
  CancelBtn,
  SubmitBtnModal,
  ModalLabel,
  InputFieldModal,
} from './CommonStylesForms'
import { setConnection } from '../redux/contactsReducer'

const QR = styled(QRCode)`
  display: block;
  margin: auto;
  padding: 20px;
  width: 300px;
  height: 300px;
  background-color: white;
`

function FormUniversalIssuance(props) {
  const dispatch = useDispatch()
  const schemasState = useSelector((state) => state.schemas)
  const schemaSelected = schemasState.schemaSelected
  let [schemaAttributes, setSchemaAttributes] = useState([])
  let mappedAttributes = {}
  const contactsState = useSelector((state) => state.contacts)
  const invitationsState = useSelector((state) => state.invitations)
  const { invitationSelected } = invitationsState
  const { connection } = contactsState
  const [schemaDID, setSchemaDID] = useState('')

  const universalForm = useRef(null)
  const [connectionFound, setConnectionFound] = useState(false)

  const { contactId, connections } = props

  useEffect(() => {
    // (AmmonBurgi) Find the active connection using the selected invitations connection_id
    if (contactId) {
      setConnectionFound(true)
    } else if (connection && invitationSelected && props.universalModalIsOpen) {
      if (
        connection.connection_id &&
        invitationSelected.connection_id &&
        connection.connection_id === invitationSelected.connection_id
      ) {
        setConnectionFound(true)
      }
    }
  }, [connection, contactId, invitationSelected, props.universalModalIsOpen])

  useEffect(() => {
    schemasState.schemas.forEach((schema) => {
      if (schema.name === props.chosenCredential) {
        dispatch(setSchemaSelected(schema))
        setSchemaAttributes(schema.attribute_names)
        setSchemaDID(schema.schema_id.split(':')[0])
      }
    })
  }, [schemasState.schemas, props.chosenCredential, dispatch])

  const handleClosingModal = () => {
    setConnectionFound(false)
    // (AmmonBurgi) Set Invitation to default values to prevent a false connect
    dispatch(setInvitationSelected({}))
    dispatch(setInvitationURL(''))
    dispatch(setConnection({}))

    props.closeUniversalModal()
  }

  mappedAttributes = schemaAttributes.map((attribute) => {
    return (
      <InputBox attribute={attribute} key={attribute}>
        <ModalLabel htmlFor={attribute}>
          {attribute
            .replace(/_/g, ' ')
            .replace(/\b\w/g, (letter) => letter.toUpperCase())}
        </ModalLabel>
        <InputFieldModal name={attribute} id={attribute} placeholder="" />
      </InputBox>
    )
  })

  const handleSubmit = (e) => {
    e.preventDefault()

    if (schemasState.issuerDID) {
      const form = new FormData(universalForm.current)

      const universalAttr = []

      schemaAttributes.forEach((attribute) => {
        universalAttr.push({
          name: attribute,
          value: form.get(attribute),
        })
      })

      if (contactId) {
        // Loop through all connections to send request
        connections.forEach((connection) =>
          props.sendRequest('CREDENTIALS', 'ISSUE_USING_SCHEMA', {
            connectionID: connection.connection_id,
            schemaID: schemaSelected.schema_id,
            schemaVersion: schemaSelected.version,
            schemaName: schemaSelected.name,
            schemaIssuerDID: schemaDID,
            attributes: universalAttr,
            credDefID: schemaSelected.CredDefs[0].credential_definition_id,
            issuerDID: schemasState.issuerDID,
          })
        )
      } else {
        props.sendRequest('CREDENTIALS', 'ISSUE_USING_SCHEMA', {
          // connectionID: contactId ? contactId : connection.connection_id,
          connectionID: connection.connection_id,
          schemaID: schemaSelected.schema_id,
          schemaVersion: schemaSelected.version,
          schemaName: schemaSelected.name,
          schemaIssuerDID: schemaDID,
          attributes: universalAttr,
          credDefID: schemaSelected.CredDefs[0].credential_definition_id,
          issuerDID: schemasState.issuerDID,
        })
      }

      handleClosingModal()
    } else {
      dispatch(
        setNotificationState({
          message: "Error: Public DID not set. Credential can't be issued.",
          type: 'error',
        })
      )
    }
  }

  return (
    <StyledPopup
      open={props.universalModalIsOpen}
      closeOnDocumentClick
      onClose={handleClosingModal}
    >
      <ModalMedium className="modal">
        <ModalHeader>Issue {props.chosenCredential} Credential</ModalHeader>
        <ModalContentWrapper>
          {(connection || contactId) && connectionFound ? (
            <ModalContent>
              <form onSubmit={handleSubmit} ref={universalForm}>
                <div>{mappedAttributes}</div>
                <Actions>
                  <CancelBtn
                    type="button"
                    onClick={handleClosingModal}
                    id="button-cancel"
                  >
                    Cancel
                  </CancelBtn>
                  <SubmitBtnModal type="submit" id="button-send">
                    Send
                  </SubmitBtnModal>
                </Actions>
              </form>
            </ModalContent>
          ) : (
            <ModalContent>
              <p>
                Simply scan the following QR code to begin the issuance process:
              </p>
              {invitationsState.invitationURL ? (
                <div className="qr">
                  <p>
                    <QR
                      value={invitationsState.invitationURL}
                      size={256}
                      renderAs="svg"
                    />
                  </p>
                </div>
              ) : (
                <p>
                  <span>Loading...</span>
                </p>
              )}
            </ModalContent>
          )}
        </ModalContentWrapper>
        <CloseBtn onClick={handleClosingModal} id="button-exit">
          &times;
        </CloseBtn>
      </ModalMedium>
    </StyledPopup>
  )
}

export default FormUniversalIssuance
