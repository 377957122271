const initialState = {
  heldCredentials: [],
}

const SET_HELD_CREDENTIALS = 'SET_HELD_CREDENTIALS'
const CLEAR_HELD_CREDENTIALS_STATE = 'CLEAR_HELD_CREDENTIALS_STATE'

export function setHeldCredentials(heldCredentials) {
  return {
    type: SET_HELD_CREDENTIALS,
    payload: heldCredentials,
  }
}

export function clearHeldCredentialsState() {
  return {
    type: CLEAR_HELD_CREDENTIALS_STATE,
    payload: null,
  }
}

export default function heldCredentialsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_HELD_CREDENTIALS:
      return { ...state, heldCredentials: action.payload }

    case CLEAR_HELD_CREDENTIALS_STATE:
      return initialState

    default:
      return state
  }
}
