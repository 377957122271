// Variety of states being set to help the functionality of the endorser module
// By default, should be able to onboard
const initialState = {
  userRole:
    localStorage.getItem('agentConfig') &&
    JSON.parse(localStorage.getItem('agentConfig'))['endorser.endorser']
      ? 'endorser'
      : 'author',
  authorInvMsgID: '',
  authorInvitationID: '',
}

const SET_AUTHOR_INV_MSG_ID = 'SET_AUTHOR_INV_MSG_ID'
const SET_AUTHOR_INV_ID = 'SET_AUTHOR_INV_ID'
const SET_USER_ROLE = 'SET_USER_ROLE'

export function setAuthorInvMsgID(invMsgID) {
  return {
    type: SET_AUTHOR_INV_MSG_ID,
    payload: invMsgID,
  }
}

export function setAuthorInvID(invID) {
  return {
    type: SET_AUTHOR_INV_ID,
    payload: invID,
  }
}

export function setUserRole(role) {
  return {
    type: SET_USER_ROLE,
    payload: role,
  }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_AUTHOR_INV_MSG_ID:
      return { ...state, authorInvMsgID: action.payload }

    case SET_AUTHOR_INV_ID:
      return { ...state, authorInvitationID: action.payload }

    case SET_USER_ROLE:
      return { ...state, userRole: action.payload }

    default:
      return state
  }
}
