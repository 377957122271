import { useRef, useState } from 'react'
import styled from 'styled-components'
import {
  CloseBtn,
  InputBox,
  InputFieldModal,
  Modal,
  ModalContentWrapper,
  ModalContent,
  ModalHeader,
  ModalLabel,
  StyledPopup,
} from './CommonStylesForms'

import { IconClose, IconAdd } from './CommonStylesTables'

const SubmitButton = styled.button`
  float: right;
  border: none;
  color: ${(props) => props.theme.text_light};
  background: ${(props) => props.theme.primary_color};
  padding: 5px;
  box-shadow: ${(props) => props.theme.drop_shadow};
  padding: 10px;
  width: 25%;
  font-size: 1.3em;

  :disabled {
    background: ${(props) => props.theme.neutral_color};
  }
`
const ClickableText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3em;
  opacity: 0.5;
  margin-top: 10px;

  :hover {
    cursor: pointer;
  }
`
const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`
const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 78%;
`
const VersionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
`
const StyledInputBox = styled(InputBox)`
  display: flex;
  position: relative;
  width: 100%;
  margin: 0;
`
const StyledInputFieldModal = styled(InputFieldModal)`
  width: 100%;
  margin-right: 10px;
`

const StyledAttributeFieldModal = styled(InputFieldModal)`
  flex: 1;
  margin-top: 10px;
`

const StyledIconRemove = styled(IconClose)`
  fill: ${(props) => props.theme.negative_color};
  stroke: ${(props) => props.theme.negative_color};
  stroke-width: 55px;
  width: 17px;
  height: 17px;
  position: absolute;
  right: 0;
  top: 42%;
  // transform: translateY(-50%);

  :hover {
    background-color: #fff;
    border-radius: 0%;
  }
`
const StyledModalLabel = styled(ModalLabel)`
  font-size: 1.3em;
`

function FormSchemaCreate(props) {
  const submitBtn = useRef()

  // Disable button on submit
  const onBtnClick = (e) => {
    if (submitBtn.current) {
      submitBtn.current.setAttribute('disabled', 'disabled')
    }
  }

  // (eldersonar) [''] means that the form comes with 1 empty attribute input box by default
  const [attributes, setAttributes] = useState([''])
  const [schemaName, setSchemaName] = useState('')
  const [schemaVersion, setSchemaVersion] = useState('')

  const handleAddInput = () => {
    setAttributes([...attributes, ''])
  }

  // (eldersonar) Filters through the attributes and removes 1 by index
  const handleRemoveInput = (index) => {
    if (attributes.length > 1) {
      const updatedAttributesArray = attributes.filter((_, i) => i !== index)
      setAttributes(updatedAttributesArray)
    }
  }

  // (eldersonar) Maps through the attributes and updates the attribute in the array
  const handleInputChange = (e, index) => {
    const updatedAttributesArray = attributes.map((input, i) =>
      i === index ? e.target.value : input
    )
    setAttributes(updatedAttributesArray)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    onBtnClick()

    const formData = {
      attributes: attributes,
      schema_name: schemaName,
      schema_version: schemaVersion,
    }
    // (anwalker) If we're an author, make a transaction
    if (props.userRole) {
      formData.user_role = props.userRole
    }

    console.log('Props.userRole:', props.userRole)
    console.log('Form Data:', formData)

    props.sendRequest('SCHEMAS', 'CREATE_SCHEMA', formData)
    closeModal()
  }

  function closeModal() {
    props.closeCreateSchemaModal()
  }

  return (
    <StyledPopup
      open={props.createschemaModalIsOpen}
      closeOnDocumentClick
      onClose={closeModal}
    >
      <Modal className="modal">
        <ModalHeader>Create New Schema</ModalHeader>
        <ModalContentWrapper>
          <ModalContent>
            <form onSubmit={handleSubmit}>
              <FlexBox>
                <NameWrapper>
                  <StyledModalLabel htmlFor="schema_name">
                    Name
                  </StyledModalLabel>
                  <StyledInputFieldModal
                    type="text"
                    name="schema_name"
                    id="schema_name"
                    placeholder="User"
                    value={schemaName}
                    onChange={(e) => setSchemaName(e.target.value)}
                    required
                  />
                </NameWrapper>
                <VersionWrapper>
                  <StyledModalLabel htmlFor="schema_version">
                    Version
                  </StyledModalLabel>
                  <StyledInputFieldModal
                    type="text"
                    name="schema_version"
                    id="schema_version"
                    placeholder="1.0"
                    value={schemaVersion}
                    onChange={(e) => setSchemaVersion(e.target.value)}
                    required
                  />
                </VersionWrapper>
              </FlexBox>
              <hr />
              {attributes.map((input, index) => (
                <StyledInputBox key={index}>
                  <StyledAttributeFieldModal
                    type="text"
                    name="schema_version"
                    id="schema_version"
                    value={input}
                    onChange={(e) => handleInputChange(e, index)}
                    placeholder={`Attribute ${index + 1}`}
                    required
                  />
                  {attributes.length > 1 && (
                    <StyledIconRemove
                      alt="Remove attribute"
                      onClick={() => handleRemoveInput(index)}
                    />
                  )}
                </StyledInputBox>
              ))}
              <ClickableText onClick={handleAddInput} id="add-attribute">
                <IconAdd /> Add an attribute
              </ClickableText>
              <SubmitButton type="submit" id="button-submit" ref={submitBtn}>
                Submit
              </SubmitButton>
            </form>
          </ModalContent>
        </ModalContentWrapper>
        <CloseBtn onClick={closeModal} id="button-exit">
          &times;
        </CloseBtn>
      </Modal>
    </StyledPopup>
  )
}

export default FormSchemaCreate
