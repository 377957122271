import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Select from 'react-select'
import AppMenu from './AppMenu.js'
import PoweredByImage from '../assets/powered-by.png'

import { Logo, LogoHolder, PoweredHolder, PoweredBy } from './CommonStylesForms'
const Header = styled.header`
  flex: 3;
  min-width: 240px;
  max-width: 240px;
  min-height: 100vh;
  padding-bottom: 48px;
  background: ${(props) => props.theme.background_primary};
  position: relative;
`

const LogoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  width: 100%;
`

const OrganizationName = styled.h3`
  margin: 10px 35px;
  color: ${(props) => props.theme.secondary_color};
  text-align: center;
  text-transform: uppercase;
  width: 70%;
`

const UserName = styled.h4`
  color: ${(props) => props.theme.primary_color};
  margin: 0;

  text-transform: uppercase;
`

const Logout = styled.button`
  margin: 5px 12px 0 12px;
  border: none;
  background: none;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`
const SelectContainer = styled.div`
  z-index: 100;
  width: 215px;
  margin-left: 15px;
  overflow: visible;
`
// const HomeHeader = styled.h2`
//   display: inline-block;
//   margin-right: 10px;
//   font-size: 1.3em;
// `

function AppHeader(props) {
  const loginState = useSelector((state) => state.login)
  const settingsState = useSelector((state) => state.settings)
  const multiTenancyState = useSelector((state) => state.multitenancy)
  const logo = settingsState.logo

  const [subwalletOptions, setSubwalletOptions] = useState([])
  let [activeWallet, setActiveWallet] = useState(null)

  useEffect(() => {
    let options = []

    if (multiTenancyState.scopedWallets) {
      for (let i = 0; i < multiTenancyState.scopedWallets.length; i++) {
        options.push({
          value: multiTenancyState.scopedWallets[i].wallet_id,
          label: multiTenancyState.scopedWallets[i].wallet_name.replaceAll(
            '_',
            ' '
          ),
        })
      }
      setSubwalletOptions(options)
    }

    const activeWallet = multiTenancyState.scopedWallets.find(
      (wallet) => wallet.active
    )
    if (activeWallet) {
      setActiveWallet({
        value: activeWallet.wallet_id,
        label: activeWallet.wallet_name.replaceAll('_', ' '),
      })
    }
  }, [multiTenancyState.scopedWallets])

  function handleCredChange(e) {
    props.sendRequest('MULTITENANCY', 'SET_SELECTED_WALLET', {
      wallet_id: e.value,
    })
  }

  const SubwalletSelect = () => {
    return (
      <form>
        <SelectContainer>
          <Select
            className="react-select-container"
            name="selectedSubwallet"
            placeholder="---Choose subwallet---"
            onChange={(e) => handleCredChange(e)}
            menuPortalTarget={document.body}
            options={subwalletOptions}
            defaultValue={activeWallet}
            id="button-dropdown-subwallet-selection"
          />
        </SelectContainer>
      </form>
    )
  }

  const handleLogout = () => {
    props.handleLogout()
  }

  const handleUserProfile = (e) => {
    e.preventDefault()
  }

  return (
    <Header id="app-header">
      <LogoHolder>
        <Logo src={logo} alt="Logo" />
      </LogoHolder>
      {/* <SubwalletSelect />
      <OrganizationName>{settingsState.organizationName}</OrganizationName> */}
      {subwalletOptions.length > 1 ? (
        <SubwalletSelect />
      ) : (
        <OrganizationName>{settingsState.organizationName}</OrganizationName>
      )}
      <LogoutWrapper>
        <UserName onClick={handleUserProfile}>
          {loginState.loggedInUsername}
        </UserName>
        <Logout onClick={handleLogout} id="button-logout">
          Log Out
        </Logout>
      </LogoutWrapper>
      <AppMenu match={props.match} />
      <PoweredHolder>
        <PoweredBy src={PoweredByImage} alt="Powered By Indicio" />
      </PoweredHolder>
    </Header>
  )
}

export default AppHeader
