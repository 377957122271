import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Button } from '../CommonStylesForms'
import { setMaintenenceWindow } from "../../redux/settingsReducer";
import { useSelector } from 'react-redux'

const StyledButton = styled(Button)`
  margin-left: 15px;
  margin-bottom: 15px;
`

export default function ScheduledMaintenenceDateSelector(props) {
  const dispatch = useDispatch()
  const settingsState = useSelector((state) => state.settings)

  // Have to define before setting state
  const customNewDate = function(date){
    // If there isnt a maintenence window set, default to today
    if(!date){
      let customDate = new Date()
      customDate.setMilliseconds(0)
      customDate.setSeconds(0)
      customDate.setMinutes(Math.round(customDate.getMinutes() / 15) * 15)
      
      return customDate
    } 

    return date
  }

  const [startDate, setStartDate] = useState(customNewDate(settingsState.maintenenceWindow.startDateTime))
  const [endDate, setEndDate] = useState(customNewDate(settingsState.maintenenceWindow.endDateTime))


  const handleMainteneceWindowSubmit = async (e) => {
    e.preventDefault()

    // Start maintenence window
    if(e.target.name === "start"){
      dispatch(setMaintenenceWindow({
        active:true,
        startDateTime: startDate,
        endDateTime: endDate
      }))

      // Save the new state to the database. 
      props.saveMaintenenceWindow()

    // End maintenence window
    } else if (e.target.name === "end"){
      dispatch(setMaintenenceWindow({
        active:false,
        startDateTime: null,
        endDateTime: null
      }))
      // Save the new state to the database. 
      props.saveMaintenenceWindow()
    }
  }

  const Label = styled.h3`
  margin-right: 10px;
  margin-bottom: 0;`

  const Div = styled.form`
  overflow: hidden;
  margin-bottom: 10px;`

  return (
    <Div id="form">
      <Label>Start Date</Label>
      <DatePicker 
        showIcon
        showTimeSelect 
        timeIntervals={15} 
        selected={startDate} 
        dateFormat="yyyy-MM-d HH:mm"
        timeFormat="HH:mm"
        onChange={(date) => setStartDate(date)} 
      />

      <Label>End Date</Label>
      <DatePicker 
        showIcon
        showTimeSelect 
        timeIntervals={15} 
        selected={endDate} 
        dateFormat="yyyy-MM-d HH:mm"
        timeFormat="HH:mm"
        onChange={(date) => setEndDate(date)} />
      <br/><br/>
      {/* Need Start buttons to check e.target.name to know if starting or ending maintenence window */}
      <StyledButton type="submit" name="start" onClick={handleMainteneceWindowSubmit}>
        {settingsState.maintenenceWindow.active ? "Update" : "Start"}
      </StyledButton>
      <StyledButton type="submit" name="end" onClick={handleMainteneceWindowSubmit}>
        End
      </StyledButton>
    </Div>
  )
}