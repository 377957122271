import { useSelector, useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'
import Endorser from './Endorser'
import FormVerificationDefinitionDelete from './FormVerificationDefinitionDelete'
import FormIssuanceSchemaAdd from './FormIssuanceSchemaAdd'
import FormVerificationDefinitionAdd from './FormVerificationDefinitionAdd'
import FormSchemaCreate from './FormSchemaCreate'
import PageHeader from './PageHeader'
import PageSection from './PageSection'
import ReactTooltip from 'react-tooltip'
import Slider from './BasicSliderSwitch'
import JSONLDContextFiles from './JSONLDContextFiles'

import {
  setHideInactiveIssuanceSchemas,
  setHideInactiveVerificationDefinitions,
} from '../redux/schemasReducer'

import { IconHelp } from './CommonStylesTables'
import styled, { useTheme } from 'styled-components'

import {
  DataTable,
  DataRow,
  DataHeader,
  DataCell,
  AttributeTable,
  AttributeRow,
  IconCheck,
  IconX,
  IconRemove,
  IconWait,
} from './CommonStylesTables'
import { Button, SectionHeader } from './CommonStylesForms'

const ToolTipSectionHeader = styled(SectionHeader)`
  display: inline-block;
  margin-right: 10px;
`

const StyledButton = styled(Button)`
  margin-left: 15px;
  margin-bottom: 15px;
`

function Schemas(props) {
  const dispatch = useDispatch()
  const schemasState = useSelector((state) => state.schemas)
  const transactionsState = useSelector((state) => state.transactions)
  const issuanceSchemas = schemasState.schemas
  const nymTransactionStatus = transactionsState.transactions.find(
    (transaction) => transaction.transaction_type === 'NYM'
  )?.transaction_state

  const theme = useTheme()

  const verificationDefinitions = schemasState.verificationDefinitions
  const TAA = schemasState.TAA

  const sortedIssuanceSchemas = issuanceSchemas.sort((a, b) =>
    a.name.localeCompare(b.name)
  )
  const sortedVerificationDefinitions = verificationDefinitions.sort((a, b) =>
    a.verification_label.localeCompare(b.verification_label)
  )
  const [createDIDPressed, setCreateDIDPressed] = useState(false)
  const [filteredIssuanceSchemas, setFilteredIssuanceSchemas] = useState([])
  const [
    filteredVerificationDefinitions,
    setFilteredVerificationDefinitions,
  ] = useState([])
  const [verificationLabel, setVerificationLabel] = useState('')
  const [publicDIDFlow, setPublicDIDFlow] = useState('default')

  const history = props.history

  // (eldersonar) Handle issuance schemas display
  useEffect(() => {
    if (schemasState.schemas && schemasState.hideInactiveIssuanceSchemas) {
      setFilteredIssuanceSchemas(
        sortedIssuanceSchemas.filter((schema) => schema.active === true)
      )
    } else {
      setFilteredIssuanceSchemas(sortedIssuanceSchemas)
    }
  }, [schemasState.schemas, schemasState.hideInactiveIssuanceSchemas])

  // (eldersonar) Handle verification schemas display
  useEffect(() => {
    if (
      schemasState.verificationDefinitions &&
      schemasState.hideInactiveVerificationDefinitions
    ) {
      setFilteredVerificationDefinitions(
        sortedVerificationDefinitions.filter((schema) => schema.active === true)
      )
    } else {
      setFilteredVerificationDefinitions(sortedVerificationDefinitions)
    }
  }, [
    schemasState.verificationDefinitions,
    schemasState.hideInactiveVerificationDefinitions,
  ])

  function openSchemaIssuance(history, id) {
    if (history !== undefined) {
      history.push('/admin/schemas/issuance/' + id)
    }
  }

  function openSchemaVerification(history, id) {
    if (history !== undefined) {
      history.push('/admin/schemas/verification/' + id)
    }
  }

  const [endorserModalIsOpen, setEndorserModalIsOpen] = useState(false)
  const closeEndorserModal = () => setEndorserModalIsOpen(false)

  const [
    addIssuanceSchemaModalIsOpen,
    setAddIssuanceSchemaModalIsOpen,
  ] = useState(false)
  const closeAddIssuanceSchemaModal = () =>
    setAddIssuanceSchemaModalIsOpen(false)

  const [
    addVerificationDefinitionModalIsOpen,
    setAddVerificationDefinitionModalIsOpen,
  ] = useState(false)
  const closeAddVerificationDefinitionModal = () =>
    setAddVerificationDefinitionModalIsOpen(false)

  const [createschemaModalIsOpen, setCreateSchemaModalIsOpen] = useState(false)
  const closeCreateSchemaModal = () => setCreateSchemaModalIsOpen(false)

  const [deleteUserModalIsOpen, setDeleteUserModalIsOpen] = useState(false)
  const closeDeleteModal = () => setDeleteUserModalIsOpen(false)

  const addIssuanceSchema = () => {
    setAddIssuanceSchemaModalIsOpen(true)
  }

  const addVerificationDefinition = () => {
    setAddVerificationDefinitionModalIsOpen(true)
  }

  const createSchema = () => {
    setCreateSchemaModalIsOpen(true)
  }

  function handleEndorserModalOpen(e) {
    setEndorserModalIsOpen(true)
  }

  const deleteSchema = () => {
    setDeleteUserModalIsOpen(true)
  }

  const handleIssuanceToggleChange = (e, seqNo) => {
    props.sendRequest('SCHEMAS', 'UPDATE_ISSUANCE_SCHEMA_STATE', {
      seqNo,
      state: e.target.checked,
    })
  }

  const toggleIssuanceSchemaDisplay = () => {
    dispatch(
      setHideInactiveIssuanceSchemas(!schemasState.hideInactiveIssuanceSchemas)
    )
  }

  const toggleVerifiactionSchemaDisplay = () => {
    dispatch(
      setHideInactiveVerificationDefinitions(
        !schemasState.hideInactiveVerificationDefinitions
      )
    )
  }

  const issuanceSchemaRows = filteredIssuanceSchemas.map((schema) => {
    return (
      <DataRow key={schema.schema_id}>
        <DataCell
          onClick={() => {
            openSchemaIssuance(history, schema.schema_id)
          }}
        >
          {schema.name.replaceAll('_', ' ')}
        </DataCell>
        <DataCell
          onClick={() => {
            openSchemaIssuance(history, schema.schema_id)
          }}
        >
          {schema.schema_id}
        </DataCell>
        <DataCell>{schema.version}</DataCell>
        <DataCell>{String(schema.active)}</DataCell>
        <DataCell>
          <Slider
            checked={schema.active}
            onChange={(e) =>
              handleIssuanceToggleChange(e, schema.sequence_number)
            }
          />
        </DataCell>
      </DataRow>
    )
  })

  const newSchemasRows = schemasState.tempLedgerSchemas.map((schema) => {
    return (
      <DataRow key={schema.transaction_id}>
        <DataCell>
          {schema.meta_data.context.schema_name.replaceAll('_', ' ')}
        </DataCell>
        <DataCell>{schema.meta_data.context.schema_id}</DataCell>
        <DataCell>{schema.meta_data.context.schema_version}</DataCell>
        <DataCell>
          {schema.state === 'request_received'
            ? 'Pending'
            : schema.state === 'transaction_acked'
            ? 'Endorsed'
            : schema.state === 'transaction_refused'
            ? 'Rejected'
            : schema.state}
        </DataCell>
      </DataRow>
    )
  })

  const verificationDefinitionRows = filteredVerificationDefinitions.map(
    (schema) => {
      return (
        <DataRow key={schema.verification_label}>
          <DataCell
            onClick={() => {
              openSchemaVerification(history, schema.id)
            }}
          >
            {schema.verification_label}
          </DataCell>
          <DataCell
            onClick={() => {
              openSchemaVerification(history, schema.id)
            }}
          >
            {schema.schema_id}
          </DataCell>
          <DataCell>{schema.version}</DataCell>
          <DataCell>
            {schema.active === true
              ? 'true'
              : schema.active === false
              ? 'false'
              : ''}
          </DataCell>
          <DataCell
            onClick={() => {
              deleteSchema()
              setVerificationLabel(schema.verification_label)
            }}
          >
            <IconRemove alt="Remove" />
          </DataCell>
        </DataRow>
      )
    }
  )

  return (
    <>
      <div id="contacts">
        <PageHeader title={'Schemas'} />
        <PageSection>
          {props.userRole !== 'author' ? (
            <h2>Endorser Information</h2>
          ) : (
            <h2>Author Information</h2>
          )}

          <AttributeTable>
            <tbody>
              <AttributeRow>
                <th>TAA Accepted</th>
                <td>
                  {!TAA.taa_accepted?.mechanism ? (
                    <IconX alt="TAA is not acceped" />
                  ) : (
                    <IconCheck alt="TAA is accepted" />
                  )}
                </td>
              </AttributeRow>
              <AttributeRow>
                <th>DID and VerKey Generated</th>
                <td>
                  {props.userRole === 'author' ? (
                    nymTransactionStatus &&
                    nymTransactionStatus !== 'transaction_acked' ? (
                      <IconCheck alt="DID is generated" />
                    ) : schemasState.walletDID.did ||
                      schemasState.issuerDID ||
                      nymTransactionStatus === 'transaction_acked' ? (
                      <IconCheck alt="DID is generated" />
                    ) : (
                      <IconX alt="DID is not generated" />
                    )
                  ) : schemasState.issuerDID ? (
                    <IconCheck alt="DID is generated" />
                  ) : (
                    <IconX alt="DID is not generated" />
                  )}
                </td>
              </AttributeRow>
              <AttributeRow>
                <th>DID is Public</th>
                <td>
                  {props.userRole === 'author' ? (
                    nymTransactionStatus &&
                    nymTransactionStatus !== 'transaction_acked' ? (
                      <IconWait alt="Awaiting public DID" />
                    ) : nymTransactionStatus === 'transaction_acked' ||
                      schemasState.issuerDID ? (
                      <IconCheck alt="DID is public" />
                    ) : (
                      <IconX alt="DID is not public" />
                    )
                  ) : schemasState.issuerDID ? (
                    <IconCheck alt="DID is public" />
                  ) : (
                    <IconX alt="DID is not public" />
                  )}
                </td>
              </AttributeRow>
              <AttributeRow>
                {props.userRole !== 'author' ? (
                  <th>Endorser DID:</th>
                ) : (
                  <th>Author DID:</th>
                )}
                <td>{schemasState.issuerDID}</td>
              </AttributeRow>
            </tbody>
          </AttributeTable>
          {props.userRole === 'endorser' &&
            !TAA.taa_accepted?.mechanism &&
            schemasState.issuerDID && (
              <StyledButton
                onClick={(e) => {
                  handleEndorserModalOpen(e)
                  setCreateDIDPressed(true)
                }}
                id="button-accept-taa"
              >
                Accept TAA
              </StyledButton>
            )}
          {props.userRole === 'author' &&
            !TAA.taa_accepted?.mechanism &&
            nymTransactionStatus &&
            schemasState.issuerDID && (
              <StyledButton
                onClick={(e) => {
                  handleEndorserModalOpen(e)
                  setCreateDIDPressed(true)
                }}
                id="button-accept-taa"
              >
                Accept TAA
              </StyledButton>
            )}
          {!schemasState.issuerDID && (
            <>
              {props.userRole !== 'author' && props.userRole !== 'endorser' ? (
                <StyledButton
                  onClick={(e) => {
                    handleEndorserModalOpen(e)
                    setCreateDIDPressed(true)
                    setPublicDIDFlow('default')
                  }}
                  id="button-create-did"
                >
                  Onboard
                </StyledButton>
              ) : null}
              {props.userRole === 'author' && !nymTransactionStatus ? (
                <>
                  <StyledButton
                    onClick={(e) => {
                      handleEndorserModalOpen(e)
                      setCreateDIDPressed(true)
                      setPublicDIDFlow('endorser')
                    }}
                    id="button-create-did"
                  >
                    Onboard Through Endorser
                  </StyledButton>
                </>
              ) : (
                <></>
              )}
              {props.userRole === 'endorser' ? (
                <StyledButton
                  onClick={(e) => {
                    handleEndorserModalOpen(e)
                    setCreateDIDPressed(true)
                    setPublicDIDFlow('default')
                  }}
                  id="button-create-did"
                >
                  Onboard
                </StyledButton>
              ) : null}
            </>
          )}
        </PageSection>
        <JSONLDContextFiles sendRequest={props.sendRequest} />
        <PageSection>
          <SectionHeader>Issuance Schemas</SectionHeader>
          {schemasState.issuerDID && (
            <>
              <StyledButton onClick={addIssuanceSchema} id="button-schema-add">
                Add
              </StyledButton>
              <StyledButton onClick={createSchema} id="button-schema-create">
                Create
              </StyledButton>
              <StyledButton
                onClick={toggleIssuanceSchemaDisplay}
                id="button-issuance-schema-display-toggle"
              >
                {!schemasState.hideInactiveIssuanceSchemas
                  ? 'Hide Inactive'
                  : 'Show All Schemas'}
              </StyledButton>
            </>
          )}
          <DataTable>
            <thead>
              <DataRow>
                <DataHeader>Schema Name</DataHeader>
                <DataHeader>Schema ID</DataHeader>
                <DataHeader>Version</DataHeader>
                <DataHeader>Active</DataHeader>
                <DataHeader> </DataHeader>
              </DataRow>
            </thead>
            <tbody>{issuanceSchemaRows}</tbody>
          </DataTable>
        </PageSection>

        {(schemasState.tempLedgerSchemas.length && (
          <PageSection>
            <ToolTipSectionHeader>
              Schemas Added to the Ledger
            </ToolTipSectionHeader>
            <IconHelp
              data-tip
              data-for="organizationTip"
              data-delay-hide="250"
              data-multiline="true"
              alt="Help"
            />
            <ReactTooltip
              id="organizationTip"
              effect="solid"
              type="info"
              backgroundColor={theme.primary_color}
            >
              <span>
                This component is temporary.
                <br />
                It displays newly added schemas.
                <br />
                <br />
                The list will be cleared
                <br />
                and component will be hidden
                <br />
                as soon as the page is refreshed.
                <br />
                <br />
              </span>
            </ReactTooltip>
            <DataTable>
              <thead>
                <DataRow>
                  <DataHeader>Schema Name</DataHeader>
                  <DataHeader>Schema ID</DataHeader>
                  <DataHeader>Version</DataHeader>
                  <DataHeader>State</DataHeader>
                </DataRow>
              </thead>
              <tbody>{newSchemasRows}</tbody>
            </DataTable>
          </PageSection>
        )) ||
          null}
        <PageSection>
          <SectionHeader>Verification Options</SectionHeader>
          {schemasState.issuerDID ? (
            <>
              <StyledButton
                onClick={addVerificationDefinition}
                id="button-schema-add"
              >
                Add
              </StyledButton>
              <StyledButton
                onClick={toggleVerifiactionSchemaDisplay}
                id="button-verification-schema-display-toggle"
              >
                {!schemasState.hideInactiveVerificationDefinitions
                  ? 'Hide Inactive'
                  : 'Show All Schemas'}
              </StyledButton>
            </>
          ) : (
            <></>
          )}
          <DataTable>
            <thead>
              <DataRow>
                <DataHeader>Option Name</DataHeader>
                <DataHeader>Schema ID</DataHeader>
                <DataHeader>Version</DataHeader>
                <DataHeader>Active</DataHeader>
                <DataHeader>Remove</DataHeader>
                <DataHeader> </DataHeader>
              </DataRow>
            </thead>
            <tbody>{verificationDefinitionRows}</tbody>
          </DataTable>
        </PageSection>
      </div>
      <Endorser
        endorserModalIsOpen={endorserModalIsOpen}
        closeEndorserModal={closeEndorserModal}
        sendRequest={props.sendRequest}
        authorRoleIsSet={props.authorRoleIsSet}
        endorserInfoIsSet={props.endorserInfoIsSet}
        userRole={props.userRole}
        createDIDPressed={createDIDPressed}
        flow={publicDIDFlow}
        nymTransactionStatus={nymTransactionStatus}
      />
      <FormIssuanceSchemaAdd
        addIssuanceSchemaModalIsOpen={addIssuanceSchemaModalIsOpen}
        closeAddIssuanceSchemaModal={closeAddIssuanceSchemaModal}
        sendRequest={props.sendRequest}
        userRole={props.userRole}
      />
      <FormVerificationDefinitionAdd
        addVerificationDefinitionModalIsOpen={
          addVerificationDefinitionModalIsOpen
        }
        closeAddVerificationDefinitionModal={
          closeAddVerificationDefinitionModal
        }
        sendRequest={props.sendRequest}
      />
      <FormSchemaCreate
        createschemaModalIsOpen={createschemaModalIsOpen}
        closeCreateSchemaModal={closeCreateSchemaModal}
        sendRequest={props.sendRequest}
        userRole={props.userRole}
      />
      <FormVerificationDefinitionDelete
        sendRequest={props.sendRequest}
        verificationLabel={verificationLabel}
        deleteUserModalIsOpen={deleteUserModalIsOpen}
        closeDeleteModal={closeDeleteModal}
      />
    </>
  )
}

export default Schemas
