const initialState = {
  invitations: {},
  invitationSelected: {},
  invitationURL: '',
  waitingForInvitations: true,
}

const SET_INVITATIONS = 'SET_INVITATIONS'
const SET_INVITATION_SELECTED = 'SET_INVITATION_SELECTED'
const SET_INVITATION_URL = 'SET_INVITATION_URL'
const SET_WAITING_FOR_INVITATIONS = 'SET_WAITING_FOR_INVITATIONS'
const CLEAR_INVITATIONS = 'CLEAR_INVITATIONS'

export function setInvitations(invitations) {
  return {
    type: SET_INVITATIONS,
    payload: invitations,
  }
}
export function setInvitationSelected(invitations) {
  return {
    type: SET_INVITATION_SELECTED,
    payload: invitations,
  }
}
export function setInvitationURL(invitation) {
  return {
    type: SET_INVITATION_URL,
    payload: invitation,
  }
}
export function setWaitingForInvitations(waiting) {
  return {
    type: SET_WAITING_FOR_INVITATIONS,
    payload: waiting,
  }
}
export function clearInvitationsState() {
  return {
    type: CLEAR_INVITATIONS,
    payload: null,
  }
}

export default function invitationsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_INVITATIONS:
      return { ...state, invitations: action.payload }

    case SET_INVITATION_SELECTED:
      return { ...state, invitationSelected: action.payload }

    case SET_INVITATION_URL:
      return { ...state, invitationURL: action.payload }

    case SET_WAITING_FOR_INVITATIONS:
      return { ...state, waitingForInvitations: action.payload }

    case CLEAR_INVITATIONS:
      return initialState

    default:
      return state
  }
}
