import { useRef } from 'react'

import {
  StyledPopup,
  Modal,
  ModalHeader,
  ModalContentWrapper,
  ModalContent,
  CloseBtn,
  Actions,
  CancelBtn,
  SubmitBtnModal,
} from './CommonStylesForms'

function FormVerificationDefinitionDelete(props) {
  const verificationLabel = props.verificationLabel

  const submitBtn = useRef()

  // Disable button on submit
  const onBtnClick = (e) => {
    if (submitBtn.current) {
      submitBtn.current.setAttribute('disabled', 'disabled')
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    onBtnClick()

    props.sendRequest(
      'SCHEMAS',
      'DELETE_VERIFICATION_DEFINITION',
      verificationLabel
    )
    closeModal()
  }

  function closeModal() {
    props.closeDeleteModal()
  }

  return (
    <StyledPopup
      open={props.deleteUserModalIsOpen}
      closeOnDocumentClick
      onClose={closeModal}
    >
      <Modal className="modal">
        <ModalHeader>
          Are you sure you want to remove this verification schema option?
        </ModalHeader>
        <ModalContentWrapper>
          <ModalContent>
            <form id="form" onSubmit={handleSubmit}>
              <Actions>
                <CancelBtn type="button" onClick={closeModal}>
                  Cancel
                </CancelBtn>
                <SubmitBtnModal type="submit" ref={submitBtn}>
                  Remove
                </SubmitBtnModal>
              </Actions>
            </form>
          </ModalContent>
        </ModalContentWrapper>
        <CloseBtn onClick={closeModal}>&times;</CloseBtn>
      </Modal>
    </StyledPopup>
  )
}

export default FormVerificationDefinitionDelete
