import { useRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { formattedDate } from './util'

import styled from 'styled-components'

import {
  IconSend,
  IconClose,
  IconMoreHoriz,
  IconAvatar,
} from './CommonStylesTables'

const ChatPopup = styled.div`
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  position: fixed;
  bottom: 0;
  right: 30px;
  border: 1px solid #5a5a5a;
  z-index: 9;
  transition: opacity 0.2s ease;
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
`
const ChatContainer = styled.div`
  border-top: 1px solid ${(props) => props.theme.neutral_color};
  width: 400px;
  padding: 10px;
  background-color: #fff;
  box-sizing: border-box;
`
const MessageBubble = styled.div`
  display: flex;
  align-items: center;
  &.active ul {
    display: block;
  }
  &.sent {
    flex-direction: row-reverse;
  }
  &.sent p {
    color: white;
    background: #0b93f6;
    align-self: flex-end;
  }
  &.received p {
    background: #e5e5ea;
    color: black;
  }
`
const Message = styled.p`
  max-width: 300px;
  margin-bottom: 12px;
  line-height: 24px;
  padding: 10px 20px;
  position: relative;
  color: white;
  text-align: center;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-align: left;
  white-space: pre-wrap;
  border-radius: 10px;
`
const ChatSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  border-radius: 25px;
`
const MainWrapper = styled.main`
  height: 400px;
  padding: 10px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;
  border-radius: 25px;
  margin-bottom: 10px;
`
const Textarea = styled.textarea`
  font-size: 12px;
  line-height: 1.5em;
  padding: 5px;
  box-sizing: border-box;
  resize: none;
  overflow-y: auto;
  width: 340px;
  min-height: 34px;
  max-height: 102px; // (eldersonar) This is good for 5 rows. Adjust this value based on our needs
  border-radius: 10px;

  &:focus {
    outline: none;
  }
`
const MessageDate = styled.p`
  font-size: 10px;
  text-align: right;
`
const ChatHeader = styled.div`
  background: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & p {
    margin: 0;
    padding: 15px;
    font-size: 20px;
  }
`
const IconHolder = styled.div`
  margin-left: 10px;
`
const ChatForm = styled.form`
  display: flex;
  align-items: end;
`

const MessageComponent = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: ${({ isSent }) => (isSent ? 'flex-end' : 'flex-start')};
`

const ChatButtonIconWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const ContactIdToogle = styled.div`
  font-size: 12px;
  line-height: 13px;
  text-align: left;
  padding: 6px 8px;
  position: fixed;
  bottom: 453px;
  right: 90px;
  box-shadow: 1px 1px 1px 0px #00000033;
  border: 1px solid rgba(205, 205, 205, 0.45);
  border-radius: 4px 0px 4px 4px;
  background: linear-gradient(0deg, #ffffff, #ffffff);
  z-index: 10;
`

function Chat(props) {
  const basicMessagesState = useSelector((state) => state.basicMessages)
  const [formValue, setFormValue] = useState('')
  const [messages, setMessages] = useState([])
  const [contactIdIsOpen, setContactIdIsOpen] = useState(false)

  const textareaRef = useRef()
  const dummy = useRef()

  const { contact_id } = props.contactSelected

  const closeForm = () => {
    props.setIsOpen(false)
  }

  const toggleContactId = (e) => {
    e.preventDefault()
    setContactIdIsOpen((o) => !o)
  }

  const resizeTextArea = () => {
    const maxRows = 5 // (eldersonar) Maximum number of rows. To increase - also consider increasing the max-height in the Textarea styled component
    const lineHeight = 1.5 // In em
    const baseHeight = 34 // In pixels

    textareaRef.current.style.height = 'auto' // (eldersonar) Reset height to auto to allow for size reduction
    const scrollHeight = textareaRef.current.scrollHeight
    let newHeight = scrollHeight < baseHeight ? baseHeight : scrollHeight
    let numberOfRows =
      newHeight /
      (lineHeight * parseFloat(getComputedStyle(textareaRef.current).fontSize))

    if (numberOfRows > maxRows) {
      newHeight =
        maxRows *
        lineHeight *
        parseFloat(getComputedStyle(textareaRef.current).fontSize)
    } else {
      newHeight = scrollHeight // (eldersonar) Adjust height based on current scroll height
    }

    textareaRef.current.style.height = `${newHeight}px`
  }

  // Send message function
  const sendMessage = async (e) => {
    e.preventDefault()

    const message = {
      contactId: props.contactSelected.contact_id,
      // invitationId: invitation_id,
      text: formValue,
      createdAt: Date.now(),
      connectionId: props.contactSelected.Connections[0].connection_id,
      // photoURL,
    }

    props.sendRequest('BASIC_MESSAGES', 'SEND', message)
    setMessages([...messages, message])

    setFormValue('')
    textareaRef.current.style.height = 34 + 'px' // Reset the textarea height
  }

  // (eldersonar) Scroll down for each message sent or received.
  // We might not want to scroll to the bottom of the message stack for incoming messages for better UX
  useEffect(() => {
    if (dummy.current) {
      dummy.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [messages])

  useEffect(() => {
    let options = []

    // (eldersonar) Filter by target and set the messages
    if (basicMessagesState.basicMessages) {
      for (let i = 0; i < basicMessagesState.basicMessages.length; i++) {
        // Ensure that only the current contacts messages are added.
        if(basicMessagesState.basicMessages[i].contact_id === contact_id){
          options.push({
            id: basicMessagesState.basicMessages[i].id,
            target: basicMessagesState.basicMessages[i].message_id
              ? 'them'
              : 'us',
            text: basicMessagesState.basicMessages[i].message,
            createdAt: basicMessagesState.basicMessages[i].created_at,
          })
        }
      }
      setMessages(options)
    }
  }, [basicMessagesState.basicMessages])

  function MessageWrapper(props) {
    const { text, uid, photoURL, createdAt, target } = props.message

    // Determine the border radius variant based on who wrote the message and its position
    // const isFirstMessage = props.index === 0
    // const isLastMessage = props.index === props.messagesLength - 1
    let borderRadiusVariant = ''
    const messageClass = target === 'us' ? 'sent' : 'received'

    // (eldersonar) TODO: fix formatting message bubles
    // based on how sent the message and how many messages in a stack
    if (target === 'us') {
      borderRadiusVariant = '10px 10px 0 10px'
    } else {
      borderRadiusVariant = '10px 10px 10px 0'
    }

    // Format the date
    const cleanDate = formattedDate(createdAt)

    return (
      <>
        <MessageComponent isSent={target === 'us'}>
          <MessageDate className={`${messageClass}`}>{cleanDate}</MessageDate>
          <MessageBubble className={`${messageClass}`}>
            <Message style={{ borderRadius: borderRadiusVariant }}>
              {text}
            </Message>
          </MessageBubble>
        </MessageComponent>
      </>
    )
  }

  return (
    <ChatPopup isOpen={props.isOpen}>
      <ChatHeader>
        <IconAvatar />
        <p>{props.contactSelected.label || ''}</p>
        <ChatButtonIconWrapper>
          <IconMoreHoriz onClick={toggleContactId} />
          <IconClose onClick={closeForm} />
        </ChatButtonIconWrapper>
        {contactIdIsOpen ? (
          <ContactIdToogle>ID: {contact_id}</ContactIdToogle>
        ) : null}
      </ChatHeader>
      <ChatContainer>
        <ChatSection>
          <MainWrapper>
            <span ref={dummy}></span>
            {messages.map((msg, index) => (
              <MessageWrapper key={index} message={msg} />
            ))}
          </MainWrapper>
          <ChatForm onSubmit={sendMessage}>
            <Textarea
              ref={textareaRef}
              rows="1"
              value={formValue}
              onChange={(e) => {
                setFormValue(e.target.value)
                resizeTextArea()
              }}
            />
            <IconHolder>
              <IconSend onClick={sendMessage} disabled={!formValue} />
            </IconHolder>
          </ChatForm>
        </ChatSection>
      </ChatContainer>
    </ChatPopup>
  )
}

export default Chat
