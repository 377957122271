import { useSelector } from 'react-redux'
import { useTheme } from 'styled-components'
import PageSection from './PageSection'
import ReactTooltip from 'react-tooltip'

import {
  DataTable,
  DataRow,
  DataHeader,
  DataCell,
  IconHelp,
} from './CommonStylesTables'

const AuthorHeader = () => {
  return (
    <h2 style={{ marginLeft: '-8px' }}>
      Authors
      <IconHelp
        data-tip
        data-for="authorsTip"
        data-delay-hide="250"
        data-multiline="true"
        alt="Help with Authors"
      />
      <ReactTooltip
        id="authorsTip"
        effect="solid"
        type="info"
        backgroundColor={useTheme().primary_color}
      >
        <span>
          The table below displays the list of connections the endorser has. By
          default,
          <br />
          all author ledger transactions will go through an endorser for
          approval.
          <br />
          <br />
          Rather than endorsing each transaction individually, the endorser can
          allow an
          <br />
          author to automatically approve their own transaction by pressing{' '}
          <i>self-endorse</i>
          <br />
          on the corresponding connection. Unpressing <i>self-endorse</i> will
          take away
          <br />
          this ability from the author.
          <br />
        </span>
      </ReactTooltip>
    </h2>
  )
}

const AuthorRowsHeader = () => {
  return (
    <thead>
      <DataRow>
        <DataHeader>Alias</DataHeader>
        <DataHeader>Connection ID</DataHeader>
        <DataHeader>Created At</DataHeader>
        <DataHeader></DataHeader>
        <DataHeader></DataHeader>
      </DataRow>
    </thead>
  )
}

function Authors(props) {
  const authorsState = useSelector((state) => state.authors)

  const authors = authorsState.authors

  const authorRows = authors.map((author) => {
    let authorAlias = ''
    let authorConnId = ''
    let authorCreatedAt = ''

    if (author) {
      if (author.author_alias) {
        authorAlias = author.author_alias
      }
      if (author.author_connection_id) {
        authorConnId = author.author_connection_id
      }
      if (author.created_at) {
        authorCreatedAt = author.created_at
      }
    }

    return (
      <DataRow key={author.author_connection_id}>
        <DataCell>{authorAlias}</DataCell>
        <DataCell>{authorConnId}</DataCell>
        <DataCell>{new Date(authorCreatedAt).toLocaleString()}</DataCell>
        <DataCell></DataCell>
        <DataCell
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
          }}
        ></DataCell>
      </DataRow>
    )
  })

  return (
    <>
      <div id="authors">
        <PageSection>
          <AuthorHeader />
          <DataTable id="table-authors">
            <AuthorRowsHeader />
            <tbody>{authorRows ? authorRows : ''}</tbody>
          </DataTable>
        </PageSection>
      </div>
    </>
  )
}

export default Authors
