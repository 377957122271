import { useRef } from 'react'
import {
  Actions,
  CancelBtn,
  CloseBtn,
  InputBox,
  InputFieldModal,
  ModalLarge,
  ModalContentWrapper,
  ModalContent,
  ModalHeader,
  ModalLabel,
  StyledPopup,
  SubmitBtnModal,
} from './CommonStylesForms'

function FormSubwalletApiKeyRevoke(props) {
  const subwalletForm = useRef()
  const submitBtn = useRef()

  const onBtnClick = () => {
    if (submitBtn.current) {
      submitBtn.current.setAttribute('disabled', 'disabled')
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    onBtnClick()

    const form = new FormData(subwalletForm.current)
    const keyId = form.get('key_id')
    const walletId = form.get('wallet_id')

    props.sendRequest('MULTITENANCY', 'REVOKE_API_KEY', {
      key_id: keyId,
      wallet_id: walletId,
    })
    closeModal()
  }

  function closeModal() {
    props.closeSubwalletApiKeyRevokeModal()
  }

  return (
    <StyledPopup
      open={props.subwalletApiKeyRevokeModalIsOpen}
      closeOnDocumentClick
      onClose={closeModal}
    >
      <ModalLarge className="modal">
        <ModalHeader>Revoke API key</ModalHeader>
        <ModalContentWrapper>
          <ModalContent>
            <form id="form" onSubmit={handleSubmit} ref={subwalletForm}>
              <InputBox>
                <ModalLabel htmlFor="key_id">API Key Id</ModalLabel>
                <InputFieldModal
                  type="key_id"
                  name="key_id"
                  id="key_id"
                  placeholder="1"
                  required
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="wallet_id">Wallet Id</ModalLabel>
                <InputFieldModal
                  type="wallet_id"
                  name="wallet_id"
                  id="wallet_id"
                  placeholder="ff65d729-a8a1-409d-9644-eac0883fde3b"
                  required
                />
              </InputBox>
              <Actions>
                <CancelBtn
                  type="button"
                  onClick={closeModal}
                  id="button-cancel"
                >
                  Cancel
                </CancelBtn>
                <SubmitBtnModal
                  type="submit"
                  ref={submitBtn}
                  id="button-submit"
                >
                  Submit
                </SubmitBtnModal>
              </Actions>
            </form>
          </ModalContent>
        </ModalContentWrapper>
        <CloseBtn onClick={closeModal} id="button-exit">
          &times;
        </CloseBtn>
      </ModalLarge>
    </StyledPopup>
  )
}

export default FormSubwalletApiKeyRevoke
