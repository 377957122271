const initialState = {
  authors: [],
  authorSelected: {},
  waitingForAuthors: true,
}

const SET_AUTHOR = 'SET_AUTHOR'
const SET_AUTHORS = 'SET_AUTHORS'
const SET_AUTHOR_SELECTED = 'SET_AUTHOR_SELECTED'
const SET_WAITING_FOR_AUTHORS = 'SET_WAITING_FOR_AUTHORS'
const CLEAR_AUTHORS = 'CLEAR_AUTHORS'

export function setAuthor(author) {
  return {
    type: SET_AUTHOR,
    payload: author,
  }
}
export function setAuthors(authors) {
  return {
    type: SET_AUTHORS,
    payload: authors,
  }
}
export function setInvitationSelected(authors) {
  return {
    type: SET_AUTHOR_SELECTED,
    payload: authors,
  }
}
export function setWaitingForAuthors(waiting) {
  return {
    type: SET_WAITING_FOR_AUTHORS,
    payload: waiting,
  }
}
export function clearAuthorsState() {
  return {
    type: CLEAR_AUTHORS,
    payload: null,
  }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_AUTHORS:
      return { ...state, authors: action.payload }

    case SET_AUTHOR:
      console.log('SET AUTHOR: ', action.payload)
      const newAuthor = action.payload.author
      return {
        ...state,
        authors: {
          ...state.authors,
          rows: [...state.authors.rows, newAuthor],
        },
      }

    case SET_AUTHOR_SELECTED:
      return { ...state, authorSelected: action.payload }

    case SET_WAITING_FOR_AUTHORS:
      return { ...state, waitingForAuthors: action.payload }

    case CLEAR_AUTHORS:
      return initialState

    default:
      return state
  }
}
