const initialState = {
  jsonldContextFiles: [],
  jsonldContextSelected: {},
}
const SET_JSONLD_CONTEXT = 'SET_JSONLD_CONTEXT'
const SET_JSONLD_CONTEXT_SELECTED = 'SET_JSONLD_CONTEXT_SELECTED'
const CLEAR_JSONLD_CONTEXTS_STATE = 'CLEAR_JSONLD_CONTEXTS_STATE'

export function setJsonldContextFiles(contextFiles) {
  return {
    type: SET_JSONLD_CONTEXT,
    payload: contextFiles,
  }
}

export function setJsonldContextSelected(contextFile) {
  return {
    type: SET_JSONLD_CONTEXT_SELECTED,
    payload: contextFile,
  }
}

export function clearJsonldContextsState() {
  return {
    type: CLEAR_JSONLD_CONTEXTS_STATE,
    payload: null,
  }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_JSONLD_CONTEXT:
      return { ...state, jsonldContextFiles: action.payload }

    case SET_JSONLD_CONTEXT_SELECTED:
      return { ...state, jsonldContextSelected: action.payload }

    case CLEAR_JSONLD_CONTEXTS_STATE:
      return initialState

    default:
      return state
  }
}
