import { useRef, useEffect } from 'react'

import {
  Actions,
  CancelBtn,
  CloseBtn,
  Modal,
  ModalContentWrapper,
  ModalContent,
  ModalHeader,
  StyledPopup,
  SubmitBtnModal,
} from './CommonStylesForms'

function FormCredentialRevocation(props) {
  const error = props.error

  const revocationForm = useRef()
  const submitBtn = useRef()

  useEffect(() => {
    if (error && submitBtn.current) {
      submitBtn.current.removeAttribute('disabled')
    }
  }, [error])

  // Disable button on submit
  const onBtnClick = (e) => {
    if (submitBtn.current) {
      submitBtn.current.setAttribute('disabled', 'disabled')
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    onBtnClick()

    const data = {
      connection_id: props.connection_id,
      credential_exchange_id: props.credential_exchange_id,
    }

    props.sendRequest('CREDENTIALS', 'REVOKE', data)
    closeModal()
  }

  function closeModal() {
    props.closeRevocationModal()
  }

  return (
    <StyledPopup
      open={props.revocationModalIsOpen}
      closeOnDocumentClick
      onClose={closeModal}
    >
      <Modal className="modal">
        <ModalHeader>
          Are you sure you want to revoke the credential?
        </ModalHeader>
        <ModalContentWrapper>
          <ModalContent>
            <form id="form" onSubmit={handleSubmit} ref={revocationForm}>
              <Actions>
                <CancelBtn type="button" onClick={closeModal}>
                  Cancel
                </CancelBtn>
                <SubmitBtnModal type="submit" ref={submitBtn}>
                  Submit
                </SubmitBtnModal>
              </Actions>
            </form>
          </ModalContent>
        </ModalContentWrapper>
        <CloseBtn onClick={closeModal}>&times;</CloseBtn>
      </Modal>
    </StyledPopup>
  )
}

export default FormCredentialRevocation
