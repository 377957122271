import { useSelector } from 'react-redux'
import PageSection from '../PageSection'
import { IconAlertSquareFilled, IconX } from '@tabler/icons-react';
import { setMaintenenceWindow } from "../../redux/settingsReducer";
import { useDispatch } from 'react-redux'

export default function MaintenenceNotification(props) {
  const settingsState = useSelector((state) => state.settings)
  const dispatch = useDispatch()
  
  const theme = settingsState.theme

  // aligns the outer svg's vertically, and also position x at the end
  let outerContainer = {
    display: "flex",
    justifyContent:"space-between",
    alignItems: "center",
  }

  // This aligns the svg's vertically
  let innerContainer = {
    display: "flex",
    alignItems: "center"
  }

  // Have color match primary color, and give alittle space between icon
  let rightMargin = {
    marginLeft: "5px",
    color: theme.primary_color,
  }

  // 
  const dateFormatter = function(date){
    // Get year, month, and day part from the date
    let year = date.toLocaleString("default",{year:"numeric"})
    let month = date.toLocaleString("default",{month:"2-digit"})
    let day = date.toLocaleString("default",{day:"2-digit"})

// Generate yyyy-mm-dd date string
    return `${year}-${month}-${day} at ${date.toLocaleTimeString("en-US", { hour12: false })}`
  }


  const dismissNotification = function(){
    // Just set to active. Will be reset to true on logout (Reload from db)
    dispatch(setMaintenenceWindow({
      active:false,
      startDateTime: settingsState.maintenenceWindow.startDateTime,
      endDateTime: settingsState.maintenenceWindow.endDateTime,
    }))
  }
  
  return (
    <PageSection>
      <div style={outerContainer}>
        <div style={innerContainer}>
          <IconAlertSquareFilled color={theme.primary_color} size={24}/>
          {/* <a> tag used instead of <p> tag to ignore <p> defaults. */}
          <a style={rightMargin}>
            <b>NOTICE: </b>Scheduled maintenence will take place from <b>{dateFormatter(settingsState.maintenenceWindow.startDateTime)}
            </b> to <b>{dateFormatter(settingsState.maintenenceWindow.endDateTime)}&nbsp;
            {settingsState.maintenenceWindow.startDateTime.toLocaleString('en', {timeZoneName: 'short'}).split(' ').pop()}</b>
          </a>
        </div>
        <IconX onClick={()=>{dismissNotification()}}></IconX> 
      </div>
    </PageSection>
  )
}