import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import PageSection from './PageSection'
import { setNotificationState } from '../redux/notificationsReducer'
import styled from 'styled-components'
import {
  DataTable,
  DataRow,
  DataHeader,
  DataCell,
  IconRemove,
} from './CommonStylesTables'

import { SectionHeader } from './CommonStylesForms'

const H3 = styled.h3`
  margin: 5px 0;
`
const SubmitFormBtn = styled.button``
const Input = styled.input``
const Form = styled.form`
  overflow: hidden;
  margin-bottom: 10px;
`

function JSONLDContextFiles(props) {
  const dispatch = useDispatch()
  const lsonldContextState = useSelector((state) => state.jsonldContexts)
  const lsonldContextFiles = lsonldContextState.jsonldContextFiles

  const [selectedFile, setSelectedFile] = useState(null)
  const [contextListForRender, setContextListForRender] = useState([])
  const [contextFileName, setContextFileName] = useState('')
  const [selectedFileHTML, setSelectedFileHTML] = useState(null)

  const sortedJsonldContextFiles = lsonldContextFiles.sort((a, b) =>
    a.context_file_id.localeCompare(b.context_file_id)
  )

  let contextFileSelectHandler = (event) => {
    const file = event.target.files[0]

    // (eldersonar) Save HTML element to a local state
    setSelectedFileHTML(event.target)

    if (file) {
      setContextFileName(file.name)

      const fileReader = new FileReader()
      fileReader.readAsText(event.target.files[0], 'UTF-8')
      fileReader.onload = (e) => {
        setSelectedFile(JSON.parse(e.target.result))
      }

      dispatch(
        setNotificationState({
          message: `The JSON-LD context file, ${file.name}, has been selected. Next, click on "Add".`,
          type: 'notice',
        })
      )
    }
  }

  const getContextURLAndAttr = (contextFiles) => {
    const urls = []

    if (contextFiles.length > 0) {
      contextFiles.forEach((contextFile) => {
        const contextFileId = contextFile.context_file_id
        const context = contextFile.file['@context']

        for (const key in context) {
          if (context[key].hasOwnProperty('@id')) {
            const url = context[key]['@id'].split('#')[0]
            urls.push({ url, contextFileId })
          }
        }
      })
    }
    return urls
  }

  useEffect(() => {
    if (lsonldContextFiles.length > 0) {
      const contextFilesList = getContextURLAndAttr(sortedJsonldContextFiles)
      setContextListForRender(contextFilesList)
    } else {
      setContextListForRender([])
    }
  }, [lsonldContextFiles, sortedJsonldContextFiles])

  //(RomanStepanyan) Handle JSON-LD context file
  const handleJSONLDContextFile = async (e) => {
    e.preventDefault()
    if (selectedFile) {
      props.sendRequest('JSONLD_CONTEXT', 'ADD_JSONLD_CONTEXT_FILE', {
        context_file_id: contextFileName,
        file: selectedFile,
      })

      dispatch(
        setNotificationState({
          message: `This file is a draft file, and should not be used for production`,
          type: 'warning',
        })
      )

      resetSelectContextFile()
    } else {
      dispatch(
        setNotificationState({
          message: `This file is not valid JSON-LD context file or it is missing core data`,
          type: 'error',
        })
      )
    }
  }

  const resetSelectContextFile = () => {
    setSelectedFileHTML(null)
    setSelectedFile(null)
    setContextFileName('')
    document.getElementById('input-choose-files').value = ''
  }

  const deleteContextFile = async (fileId) => {
    props.sendRequest('JSONLD_CONTEXT', 'DELETE_JSONLD_CONTEXT_FILE', {
      fileId,
    })
  }

  const jsonldContextFilesRows = contextListForRender.map((file) => {
    return (
      <DataRow key={file.contextFileId}>
        <DataCell>{file.url}</DataCell>
        <DataCell
          onClick={() => {
            deleteContextFile(file.contextFileId)
          }}
        >
          <IconRemove alt="Remove" />
        </DataCell>
      </DataRow>
    )
  })

  return (
    <>
      <div id="files">
        <PageSection>
          <SectionHeader>JSON-LD context files</SectionHeader>
          <H3>Add JSON-LD context file</H3>
          <Form onSubmit={handleJSONLDContextFile}>
            <Input
              id="input-choose-files"
              type="file"
              accept=".json"
              onChange={contextFileSelectHandler}
            ></Input>
            <SubmitFormBtn
              id="button-upload-context-file"
              type="submit"
              disabled={!selectedFile}
            >
              Add file
            </SubmitFormBtn>
          </Form>
          <DataTable>
            <thead>
              <DataRow>
                <DataHeader>Context URL</DataHeader>
                <DataHeader>Remove</DataHeader>
              </DataRow>
            </thead>
            <tfoot>{jsonldContextFilesRows && jsonldContextFilesRows}</tfoot>
          </DataTable>
        </PageSection>
      </div>
    </>
  )
}

export default JSONLDContextFiles
