const initialState = {
    email: [],
}

const SET_EMAIL = 'SET_EMAIL'
const CLEAR_EMAIL = 'CLEAR_EMAIL'

export function setEmail(email) {
    return {
        type: SET_EMAIL,
        payload: email,
    }
}

export function clearEmail() {
    return {
        type: CLEAR_EMAIL,
        payload: null,
    }
}

export default function emailReducer(state = initialState, action) {
    switch (action.type) {
        case SET_EMAIL:
            return { ...state, email: action.payload }

        case CLEAR_EMAIL:
            return initialState
          
        default:
            return state
    }
}