const initialState = {
  basicMessages: [],
}

const SET_BASIC_MESSAGES = 'SET_BASIC_MESSAGES'
const CLEAR_BASIC_MESSAGES = 'CLEAR_BASIC_MESSAGES'

export function setBasicMessages(basicMessages) {
  return {
    type: SET_BASIC_MESSAGES,
    payload: basicMessages,
  }
}

export function clearBasicMessages() {
  return {
    type: CLEAR_BASIC_MESSAGES,
    payload: null,
  }
}

export default function basicMessagesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_BASIC_MESSAGES:
      return { ...state, basicMessages: action.payload }

    case CLEAR_BASIC_MESSAGES:
      return initialState

    default:
      return state
  }
}
