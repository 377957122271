import { DateTime } from 'luxon'
import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import QRCode from 'qrcode.react'
import styled from 'styled-components'

import {
  setInvitationSelected,
  setInvitationURL,
} from '../redux/invitationsReducer'

import { setConnection } from '../redux/contactsReducer'

import {
  StyledPopup,
  ModalMedium,
  ModalHeader,
  ModalContentWrapper,
  ModalContent,
  CloseBtn,
} from './CommonStylesForms'
import { LoadingHolder, Spinner } from './LoadingStyles'

const QR = styled(QRCode)`
  display: block;
  margin: auto;
  padding: 20px;
  width: 300px;
  height: 300px;
  background-color: white;
`

function FormPresentationQR(props) {
  const dispatch = useDispatch()
  const contactsState = useSelector((state) => state.contacts)
  const invitationsState = useSelector((state) => state.invitations)
  const { connection } = contactsState
  const { invitationSelected, invitationURL } = invitationsState

  const [connectionFound, setConnectionFound] = useState(false)

  const { closeVerificationQRModal, verificationModalIsOpen } = props

  useEffect(() => {
    // (AmmonBurgi) Find the active connection using the selected invitations connection_id
    if (connection && invitationSelected && props.verificationModalIsOpen) {
      if (
        connection.connection_id &&
        invitationSelected.connection_id &&
        connection.connection_id === invitationSelected.connection_id
      ) {
        setConnectionFound(true)
      }
    }
  }, [connection, invitationSelected, props.verificationModalIsOpen])

  const handleClosingModal = () => {
    setConnectionFound(false)

    // (AmmonBurgi) Set Invitation to default values to prevent a false connect
    dispatch(setInvitationSelected({}))
    dispatch(setInvitationURL(''))
    dispatch(setConnection({}))
    closeVerificationQRModal()
  }

  return (
    <StyledPopup
      open={verificationModalIsOpen}
      closeOnDocumentClick
      onClose={handleClosingModal}
    >
      <ModalMedium className="modal">
        <ModalHeader>Verification workflow</ModalHeader>
        <ModalContentWrapper>
          {connection && connectionFound ? (
            'connection active'
          ) : (
            <ModalContent>
              {!invitationURL ? (
                <>
                  <p>Loading invitation...</p>
                  <LoadingHolder>
                    <Spinner />
                  </LoadingHolder>
                </>
              ) : invitationSelected.workflow_status !== 'active' ? (
                <>
                  <p>Processing invitation...</p>
                  <LoadingHolder>
                    <Spinner />
                  </LoadingHolder>
                </>
              ) : (
                <>
                  <p>
                    Simply scan the following QR code to begin the issuance
                    process:
                  </p>
                  <div className="qr">
                    <p>
                      <QR value={invitationURL} size={256} renderAs="svg" />
                    </p>
                  </div>
                </>
              )}
            </ModalContent>
          )}
        </ModalContentWrapper>
        <CloseBtn onClick={handleClosingModal} id="button-exit">
          &times;
        </CloseBtn>
      </ModalMedium>
    </StyledPopup>
  )
}

export default FormPresentationQR
