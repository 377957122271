import { useRef, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Select from 'react-select'

import { check } from './CanUser'

import ChatComponent from './ChatPopup'

import PageHeader from './PageHeader.js'
import PageSection from './PageSection.js'
import styled from 'styled-components'
import FormUniversalIssuance from './FormUniversalIssuance'
import FormInvitationEmail from './FormInvitationEmail'
import { setNotificationState } from '../redux/notificationsReducer'
import { clearBasicMessages } from '../redux/basicMessagesReducer'

import { SubmitBtnModal } from './CommonStylesForms'
import { IconExpandLess, IconAvatar } from './CommonStylesTables'

import {
  DataTable,
  DataRow,
  DataHeader,
  DataCell,
  AttributeTable,
  AttributeRow,
  IconCheck,
} from './CommonStylesTables'
import { LoadingHolder, Spinner } from './LoadingStyles'

const Chip = styled.div`
  float: right;
  padding: 8px 10px;
  margin-right: 10px;
  line-height: 20px;
`

const SelectContainer = styled.div`
  float: left;
  z-index: 100;
  width: 300px;
  margin-right: 15px;
`
const PingBtn = styled.button`
  float: right;
  padding: 10px 12px;
  margin-right: 10px;
  line-height: 20px;
  color: ${(props) => props.theme.text_light};
  border: none;
  box-shadow: ${(props) => props.theme.drop_shadow};
  background: ${(props) => props.theme.primary_color};
`
const InteractionButton = styled.button`
  float: right;
  padding: 10px 12px;
  margin-right: 10px;
  line-height: 20px;
  color: ${(props) => props.theme.text_light};
  border: none;
  box-shadow: ${(props) => props.theme.drop_shadow};
  background: ${(props) => props.theme.primary_color};
`
const ChatButton = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  color: ${(props) => props.theme.primary_color};
  padding: 8px;
  border: 1px solid #5a5a5a;
  cursor: pointer;
  opacity: 0.8;
  position: fixed;
  bottom: 0px;
  right: 30px;
  width: 400px;
  font-size: 18px;
  opacity: ${(props) => (props.isOpen ? '0' : '1')};
`
const ChatButtonTextWrapper = styled.div``
const ChatButtonIconWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
function Contact(props) {
  const dispatch = useDispatch()
  const contactsState = useSelector((state) => state.contacts)
  const credentials = useSelector((state) => state.credentials.credentials)
  const presentations = useSelector(
    (state) => state.presentations.presentationReports
  )
  const schemasState = useSelector((state) => state.schemas)

  const contactSelected = contactsState.contactSelected

  const [waitingForVerification, setWaitingForVerification] = useState(false)
  const [verificationStatus, setVerificationStatus] = useState(undefined)
  const [
    verificationDefinitionOptions,
    setVerificationDefinitionOptions,
  ] = useState([])
  const [chosenSchema, setChosenSchema] = useState({})
  const [notReadyForVerification, setNotReadyForVerification] = useState(true)

  const [credentialSchemasOptions, setCredentialSchemasOptions] = useState([])
  const [chosenCredential, setChosenCredential] = useState({})
  const [notReadyForIssuance, setNotReadyForIssuance] = useState(true)

  const [universalModalIsOpen, setUniversalModalIsOpen] = useState(false)
  const closeUniversalModal = () => {
    setNotReadyForIssuance(true)
    setUniversalModalIsOpen(false)
    setChosenCredential({})
  }

  const { history, contactId, sendRequest } = props

  const [isOpen, setIsOpen] = useState(false)

  const websocketsState = useSelector((state) => state.websockets)
  const { websocket, readyForWebsocketMessages } = websocketsState

  const [connections, setConnections] = useState([])
  const [waitingForContact, setWaitingForContact] = useState(true)
  const [emailModalIsOpen, setEmailModalIsOpen] = useState(false)
  const closeEmailModal = () => {
    setNotReadyForIssuance(true)
    setEmailModalIsOpen(false)
    setChosenCredential({})
  }

  const submitBtn = useRef()

  // (eldersonar) Reset basic messages state on component unmount
  useEffect(
    () => () => {
      dispatch(clearBasicMessages())
    },
    []
  )
  useEffect(() => {
    if (props.verifiedCredential !== null && props.verifiedCredential !== '') {
      setVerificationStatus(true)
      setNotReadyForVerification(true)
    } else if (props.verifiedCredential === null) {
      setVerificationStatus(false)
      setNotReadyForVerification(true)
    } else {
      return
    }
  }, [props.verifiedCredential])

  const [KELICourseModalIsOpen, setKELICourseModalIsOpen] = useState(false)
  const closeKELICourseModal = () => setKELICourseModalIsOpen(false)

  const [univDegreeModalIsOpen, setUnivDegreeModalIsOpen] = useState(false)
  const closeUnivDegreeModal = () => setUnivDegreeModalIsOpen(false)

  useEffect(() => {
    let options = []

    if (schemasState.schemas) {
      const activeShemas = schemasState.schemas.filter(
        (schema) => schema.active === true
      )
      for (let i = 0; i < activeShemas.length; i++) {
        options.push({
          value: activeShemas[i].name,
          label: activeShemas[i].name.replaceAll('_', ' '),
        })
      }
      setCredentialSchemasOptions(options)
    }
  }, [schemasState.schemas])

  useEffect(() => {
    let options = []

    // (eldersonar) Handle governance options state (needs better description)
    if (schemasState.verificationDefinitions) {
      const activeShemas = schemasState.verificationDefinitions.filter(
        (schema) => schema.active === true || schema.active === undefined
      )
      for (let i = 0; i < activeShemas.length; i++) {
        options.push({
          id: i,
          label: activeShemas[i].verification_label,
          value: i,
        })
      }
      setVerificationDefinitionOptions(options)
    }
  }, [schemasState.verificationDefinitions])

  const SchemaSelect = () => {
    return (
      <>
        <form>
          <p>Select a Schema Option to Verify:</p>
          <SelectContainer>
            <Select
              id="chosenSchema"
              name="schemas"
              placeholder="---Choose Schema---"
              defaultValue={
                Object.keys(chosenSchema).length !== 0 ? chosenSchema : ''
              }
              onChange={(e) => handleSchemaChange(e)}
              menuPortalTarget={document.body}
              options={verificationDefinitionOptions}
            />
          </SelectContainer>

          <SubmitBtnModal
            disabled={notReadyForVerification}
            type="submit"
            onClick={(e) => {
              requestPresentations(e)
            }}
            style={{ float: 'left' }}
          >
            Verify
          </SubmitBtnModal>
        </form>
      </>
    )
  }

  const CredentialSelect = () => {
    return (
      <>
        <h2>Credential Issuance</h2>
        <form>
          <p>Select a Credential to Issue:</p>

          <SelectContainer>
            <Select
              className="react-select-container"
              id="chosenCred"
              name="chosenCred"
              placeholder="---Choose Credential---"
              defaultValue={
                Object.keys(chosenCredential).length !== 0
                  ? chosenCredential
                  : ''
              }
              onChange={(e) => handleCredChange(e)}
              menuPortalTarget={document.body}
              options={credentialSchemasOptions}
            />
          </SelectContainer>

          <SubmitBtnModal
            disabled={notReadyForIssuance}
            type="submit"
            onClick={(e) => {
              handleOpen(e)
            }}
            style={{ float: 'left' }}
          >
            Issue
          </SubmitBtnModal>
        </form>
      </>
    )
  }

  useEffect(() => {
    //(AmmonBurgi) Stop waiting for contactSelected if the contact_id matches the target ID. If no match is found, fetch the needed contact.
    if (websocket && readyForWebsocketMessages) {
      if (
        Object.keys(contactSelected).length !== 0 &&
        contactSelected.contact_id === contactId
      ) {
        setWaitingForContact(false)
        setConnections(contactSelected.Connections)

        // (eldersonar) Get messages
        if (check('basicMessages:read')) {
          sendRequest('BASIC_MESSAGES', 'GET_MESSAGES_BY_CONTACT_ID', {
            contact_id: contactId,
          })
        }
      } else {
        setWaitingForContact(true)
        if (check('contacts:read')) {
          sendRequest('CONTACTS', 'GET', {
            contact_id: contactId,
            additional_tables: [],
          })
        }
      }
    }
  }, [contactSelected, contactId, websocket, readyForWebsocketMessages])

  const requestPresentations = (event) => {
    event.preventDefault()
    if (chosenSchema && chosenSchema.label) {
      if (check('presentations:request')) {
        const schema = schemasState.verificationDefinitions.find(
          ({ verification_label }) => verification_label === chosenSchema.label
        )

        // TODO: map connections and send to all connections
        connections.forEach(async (connection) => {
          if (connection.state === 'active') {
            props.sendRequest('PRESENTATIONS', 'ADMIN_REQUEST', {
              connection_id: connection.connection_id,
              schema_id: schema.schema_id,
              attributes: schema.attribute_names,
              discovered_features: connection.discovered_features,
              schema,
            })
          }
        })
        setVerificationStatus(undefined)
        setWaitingForVerification(true)
        props.setVerifiedCredential('')
        setChosenSchema({})

        if (submitBtn.current) {
          submitBtn.current.setAttribute('disabled', 'disabled')
          submitBtn.current.style.background = 'grey'
          submitBtn.current.style.cursor = 'default'
        }
      } else {
        dispatch(
          setNotificationState({
            message: 'You are not authorized to send presentation requests.',
            type: 'error',
          })
        )
      }
    } else {
      return
    }
  }

  function handleCredChange(e) {
    setChosenCredential(e)
    setNotReadyForIssuance(false)
  }

  function handleSchemaChange(e) {
    setChosenSchema(e)
    setNotReadyForVerification(false)
  }

  function handleOpen(e) {
    e.preventDefault()
    if (chosenCredential.value === 'Email') {
      setEmailModalIsOpen((o) => !o)
    } else {
      setUniversalModalIsOpen((o) => !o)
    }
  }

  const connectionRows = connections.map((connection) => {
    return (
      <DataRow key={connection.connection_id}>
        <DataCell>{connection.connection_id}</DataCell>
        <DataCell className="title-case">{connection.state}</DataCell>
        <DataCell>{new Date(connection.created_at).toLocaleString()}</DataCell>
      </DataRow>
    )
  })

  async function trustPing() {
    const connectionId = contactSelected.Connections[0].connection_id
    props.sendRequest('CONTACT', 'PING', { connectionId: connectionId })
  }

  function openCredential(history, id) {
    if (history !== undefined) {
      history.push('/admin/credentials/' + id)
    }
  }

  function openPresentation(history, id) {
    if (history !== undefined) {
      history.push('/admin/presentations/' + id)
    }
  }

  function Pong() {
    if (
      contactSelected.Connections &&
      contactSelected.Connections[0].connection_id ===
        contactsState?.pingData?.connectionId
    ) {
      return (
        <Chip>
          <IconCheck alt="Pong" />
        </Chip>
      )
    } else {
      return <Chip>&nbsp;</Chip>
    }
  }

  const credentialRows = credentials.map((credential_record) => {
    if (
      contactSelected.Connections &&
      contactSelected.Connections.some(
        (connection) =>
          connection.connection_id === credential_record.connection_id
      )
    ) {
      let credentialName = ''
      const credential_id = credential_record.credential_exchange_id
      const credentialState = credential_record.state.replaceAll('_', ' ') || ''
      const dateCreated =
        new Date(credential_record.created_at).toLocaleString() || ''

      credentialName = credential_record.schema_id
        ?.split(':')[2]
        .replaceAll('_', ' ')

      return (
        <DataRow
          key={credential_id}
          onClick={() => {
            openCredential(history, credential_id)
          }}
        >
          <DataCell>{credentialName}</DataCell>
          <DataCell className="title-case">{credentialState}</DataCell>
          <DataCell>{dateCreated}</DataCell>
        </DataRow>
      )
    } else {
      return []
    }
  })

  const presentationRows = presentations.map((presentation_record) => {
    let presentationRecords = []
    if (
      contactSelected.Connections &&
      contactSelected.Connections.some(
        (connection) =>
          connection.connection_id === presentation_record.connection_id
      )
    ) {
      const presentation_id = presentation_record.presentation_exchange_id
      const presentationState = presentation_record.state
      const presentationVerified = presentation_record.verified.toString()

      const dateCreated =
        new Date(presentation_record.created_at).toLocaleString() || ''

      let presentationName = ''
      const presentationRequest = presentation_record.presentation_request

      if (
        presentation_record.presentation_request !== null &&
        presentation_record.presentation_request !== undefined &&
        !presentation_record.presentation_request_dict[
          'request_presentations~attach'
        ][0]?.data?.json?.presentation_definition?.format
      ) {
        let schemaId = null

        const requestedAttributeKeys = Object.keys(
          presentationRequest.requested_attributes
        )

        for (const attributeKey of requestedAttributeKeys) {
          const attribute =
            presentationRequest.requested_attributes[attributeKey]
          if (attribute.restrictions && attribute.restrictions.length > 0) {
            schemaId = attribute.restrictions[0].schema_id
            break
          }
        }

        presentationName = schemaId
          ? schemaId.split(':')[2].replaceAll('_', ' ')
          : `Self attested`
      } else if (
        presentation_record.presentation_request_dict[
          'request_presentations~attach'
        ][0].data.json.presentation_definition.format
      ) {
        presentationName =
          presentation_record.presentation_request_dict[
            'request_presentations~attach'
          ][0].data.json.presentation_definition.input_descriptors[0].name
      }
      presentationRecords.push(
        <DataRow
          key={presentation_id}
          onClick={() => {
            openPresentation(history, presentation_id)
          }}
        >
          <DataCell>{presentationName}</DataCell>
          <DataCell className="title-case">{presentationState}</DataCell>
          <DataCell className="title-case">{presentationVerified}</DataCell>
          <DataCell>{dateCreated}</DataCell>
        </DataRow>
      )
    }
    return presentationRecords
  })

  const onDiscoverFeatures = () => {
    props.sendRequest('DISCOVER_FEATURES', 'GET', {
      connection_id: contactSelected.Connections[0].connection_id,
    })

    dispatch(
      setNotificationState({
        message: 'See the backend logs for the result.',
        type: 'notice',
      })
    )
  }

  function onOpenUnivDegree(e) {
    e.preventDefault()
    setUnivDegreeModalIsOpen((o) => !o)
  }
  function onOpenKELICourse(e) {
    e.preventDefault()
    setKELICourseModalIsOpen((o) => !o)
  }

  const openForm = () => {
    setIsOpen(true)
  }

  return (
    <>
      {!waitingForContact ? (
        <div id="contact">
          <PageHeader
            title={'Contact Details: ' + (contactSelected.label || '')}
          />
          <PageSection>
            <h2>General Information</h2>
            <AttributeTable>
              <tbody>
                <AttributeRow>
                  <th>Contact ID:</th>
                  <td>{contactSelected.contact_id || ''}</td>
                </AttributeRow>
                <AttributeRow>
                  <th>Connection Status:</th>
                  <td>
                    {contactSelected.Connections !== undefined
                      ? contactSelected.Connections[0].state || ''
                      : ''}
                  </td>
                </AttributeRow>
              </tbody>
            </AttributeTable>
            <h2>Contact Information</h2>
            <AttributeTable>
              <tbody>
                <AttributeRow>
                  <th>Name:</th>
                  <td>{contactSelected.label || ''}</td>
                </AttributeRow>
              </tbody>
            </AttributeTable>
            <InteractionButton onClick={() => onDiscoverFeatures()}>
              Discover Features v2.0
            </InteractionButton>
            <PingBtn
              onClick={() => {
                trustPing()
              }}
            >
              Ping
            </PingBtn>
            <Pong />
            <div style={{ clear: 'both' }}></div>
          </PageSection>

          <PageSection>
            <CredentialSelect />
          </PageSection>

          <PageSection>
            {waitingForVerification ? (
              verificationStatus !== undefined ? (
                verificationStatus ? (
                  props.verifiedCredential ? (
                    <>
                      <SchemaSelect />
                    </>
                  ) : (
                    <>
                      <SchemaSelect />
                    </>
                  )
                ) : (
                  <>
                    <h2>Verification Failed</h2>
                    <p>
                      There was a problem verifying your credential. Please try
                      again or contact support by clicking on the link below:
                    </p>
                    <SchemaSelect />
                  </>
                )
              ) : (
                <>
                  <h2>Credential Verification</h2>
                  <p>
                    You will now receive a request on your mobile app to send
                    your credential to us for verification.
                  </p>
                </>
              )
            ) : schemasState.verificationDefinitions &&
              schemasState.verificationDefinitions.length ? (
              <>
                {/* <h2>Credential Verification</h2> */}
                <SchemaSelect />
              </>
            ) : (
              <>
                <h2>No schemas set for verification</h2>
              </>
            )}
          </PageSection>
          <PageSection>
            <DataTable>
              <thead>
                <DataRow>
                  <DataHeader>Credential</DataHeader>
                  <DataHeader>Status</DataHeader>
                  <DataHeader>Date Issued</DataHeader>
                </DataRow>
              </thead>
              <tbody>{credentialRows}</tbody>
            </DataTable>
          </PageSection>
          <PageSection>
            <DataTable>
              <thead>
                <DataRow>
                  <DataHeader>Presentation</DataHeader>
                  <DataHeader>Status</DataHeader>
                  <DataHeader>Verified</DataHeader>
                  <DataHeader>Date Issued</DataHeader>
                </DataRow>
              </thead>
              <tbody>{presentationRows}</tbody>
            </DataTable>
          </PageSection>
          <PageSection>
            <DataTable>
              <thead>
                <DataRow>
                  <DataHeader>Connection</DataHeader>
                  <DataHeader>Status</DataHeader>
                  <DataHeader>Created At</DataHeader>
                </DataRow>
              </thead>
              <tbody>{connectionRows}</tbody>
            </DataTable>
          </PageSection>
          <FormInvitationEmail
            sendRequest={props.sendRequest}
            emailModalIsOpen={emailModalIsOpen}
            closeEmailModal={closeEmailModal}
          />
          <FormUniversalIssuance
            contactId={contactId}
            connections={connections}
            universalModalIsOpen={universalModalIsOpen}
            closeUniversalModal={closeUniversalModal}
            chosenCredential={chosenCredential.value}
            sendRequest={props.sendRequest}
            onOpenUnivDegree={onOpenUnivDegree}
            onOpenKELICourse={onOpenKELICourse}
          />
        </div>
      ) : (
        <LoadingHolder>
          <p>Fetching contacts, please wait...</p>
          <Spinner />
        </LoadingHolder>
      )}

      <ChatButton
        className="open-button"
        onClick={() => openForm()}
        isOpen={isOpen}
      >
        <IconAvatar />
        <ChatButtonTextWrapper>
          {contactSelected.label || ''}
        </ChatButtonTextWrapper>
        <ChatButtonIconWrapper>
          <IconExpandLess />
        </ChatButtonIconWrapper>
      </ChatButton>

      <ChatComponent
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        contactSelected={contactSelected}
        sendRequest={sendRequest}
      />
    </>
  )
}

export default Contact
