import styled from 'styled-components'
import { ReactComponent as Remove } from '../assets/remove.svg'
import { ReactComponent as Edit } from '../assets/edit.svg'
import { ReactComponent as Email } from '../assets/email.svg'
import { ReactComponent as Help } from '../assets/help.svg'
import { ReactComponent as Check } from '../assets/check.svg'
import { ReactComponent as Send } from '../assets/send.svg'
import { ReactComponent as Close } from '../assets/close.svg'
import { ReactComponent as ExpandLess } from '../assets/expand_less.svg'
import { ReactComponent as Avatar } from '../assets/avatar.svg'
import { ReactComponent as MoreHoriz } from '../assets/more_horiz.svg'
import { ReactComponent as Add } from '../assets/add.svg'
import { ReactComponent as Clock } from '../assets/waitClock.svg'

export const DataTable = styled.table`
  box-sizing: content-box;
  margin-top: -16px;
  margin-left: -25px;
  width: calc(100% + 50px);
  border-collapse: collapse;
`

export const DataRow = styled.tr`
  :nth-child(2n + 2) td {
    background: ${(props) => props.theme.background_secondary};
  }

  :hover td {
    cursor: pointer;
    background: #ffc;
  }
`

export const DataHeader = styled.th`
  padding: 8px 12px;
  text-align: left;
  border-bottom: 1px solid ${(props) => props.theme.primary_color};
`

export const DataCell = styled.td`
  padding: 8px 12px;
  text-align: left;
`

export const AttributeTable = styled.table`
  margin-bottom: 1em;
  border-collapse: collapse;
`

export const AttributeRow = styled.tr`
  th {
    padding: 0 6px;
    text-align: left;
  }
`
export const CredFormContainer = styled.div`
  margin: 0px 0px 10px;
  padding: 0;
  min-width: 400px;
  width: 100%;
  height: 300px;
  text-align: center;
`

export const IconCell = styled.td`
  color: ${(props) => props.theme.primary_color};
  box-shadow: none;
  text-align: center;
  font-size: 1.2em;
  :hover {
    cursor: pointer;
  }
`

export const IconEdit = styled(Edit)`
  fill: ${(props) => props.theme.primary_color};
`

export const IconRemove = styled(Remove)`
  fill: ${(props) => props.theme.negative_color};
`

export const IconEmail = styled(Email)`
  fill: ${(props) =>
    !props.disabled ? props.theme.secondary_color : props.theme.neutral_color};
`

export const IconHelp = styled(Help)`
  fill: ${(props) => props.theme.secondary_color};
`
export const IconCheck = styled(Check)`
  fill: ${(props) => props.theme.positive_color};
`
export const InvertedIconCheck = styled.div`
  background-color: ${(props) => props.theme.positive_color};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  margin-left: 10px;
  box-shadow: ${(props) => props.theme.drop_shadow};
  :hover {
    cursor: pointer;
  }
  :disabled {
    cursor: default;
    background: #aaa;
  }
`;
export const WhiteIconCheck = styled(IconCheck)`
  fill: #ffffff;
`;
export const IconSend = styled(Send)`
  // fill: ${(props) => props.theme.primary_color};
  fill: ${(props) =>
    !props.disabled ? props.theme.secondary_color : props.theme.neutral_color};
  }
  :hover {
    cursor: ${(props) => (!props.disabled ? 'pointer' : 'default')}};
    // cursor: pointer;
`
export const IconX = styled(Close)`
  fill: ${(props) => props.theme.negative_color};
  height: 20px;
  width: 20px;
  }
`
export const InvertedIconX = styled.div`
  background-color: ${(props) => props.theme.negative_color};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  margin-left: 10px;
  box-shadow: ${(props) => props.theme.drop_shadow};
  :hover {
    cursor: pointer;
  }
  :disabled {
    cursor: default;
    background: #aaa;
  }
`;

export const WhiteIconX = styled(IconX)`
  fill: #ffffff;
`;
export const IconExpandLess = styled(ExpandLess)`
  fill: ${(props) => props.theme.primary_color};
  height: 35px;
`
export const IconClose = styled(Close)`
  fill: ${(props) => props.theme.primary_color};
  width: 30px;
  height: 30px;
  margin-right: 15px;
  :hover {
    cursor: pointer;
    background-color: #ebebeb;
    border-radius: 50%;
  }
`
export const IconAvatar = styled(Avatar)`
  fill: ${(props) => props.theme.primary_color};
  height: 35px;
`
export const IconWait = styled(Clock)`
  fill: ${(props) => props.theme.primary_color};
  height: ${(props) => props.height || '20px'};
`

export const IconMoreHoriz = styled(MoreHoriz)`
  fill: ${(props) => props.theme.primary_color};
  width: 30px;
  height: 30px;
  margin-right: 15px;
  :hover {
    cursor: pointer;
    background-color: #ebebeb;
    border-radius: 50%;
  }
`

export const IconAdd = styled(Add)`
  opacity: 0.7;
  }
`
