import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useRef, useState } from 'react'

import { setSelectedLedgerSchema } from '../redux/schemasReducer'

import styled from 'styled-components'
import {
  Actions,
  CancelBtn,
  CloseBtn,
  Checkbox,
  CheckboxHolder,
  InputBox,
  InputFieldModal,
  Modal,
  ModalContentWrapper,
  ModalContent,
  ModalHeader,
  ModalLabel,
  StyledPopup,
  SubmitBtnModal,
} from './CommonStylesForms'

const StyledInputFieldModal = styled(InputFieldModal)`
  width: 100%;
`
const StyledModalLabel = styled(ModalLabel)`
  line-height: 30px;
  width: 12%;
`
const AttributeHolder = styled.div`
  margin-left: 5px;
`

function FormVerificationDefinitionAdd(props) {
  const dispatch = useDispatch()
  const schemasState = useSelector((state) => state.schemas)
  const schemaIdForm = useRef()
  const submitBtn = useRef()
  const [selectedAttributes, setSelectedAttributes] = useState({})

  useEffect(() => {
    if (schemasState.selectedLedgerSchema) {
      setSelectedAttributes(
        schemasState.selectedLedgerSchema.attrNames.reduce((acc, attr) => {
          acc[attr] = true // all attributes selected by default
          return acc
        }, {})
      )
    }
  }, [schemasState.selectedLedgerSchema])

  // Disable button on submit
  const onBtnClick = (e) => {
    if (submitBtn.current) {
      submitBtn.current.setAttribute('disabled', 'disabled')
    }
  }

  function closeModal() {
    props.closeAddVerificationDefinitionModal()
    dispatch(setSelectedLedgerSchema(null))
  }

  function resetSchemaSelection() {
    dispatch(setSelectedLedgerSchema(null))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    onBtnClick()

    const form = new FormData(schemaIdForm.current)
    const schema_id = form.get('schema_id')
    props.sendRequest('SCHEMAS', 'GET_LEDGER_SCHEMA', schema_id)
  }

  const handleSubmitSchema = (e) => {
    e.preventDefault()
    onBtnClick()

    // Process the selected attributes and verification label
    const verificationLabel = e.target.verification_label.value
    const attributes = Object.keys(selectedAttributes).filter(
      (attr) => selectedAttributes[attr]
    )

    props.sendRequest('SCHEMAS', 'SET_VERIFICATION_DEFINITION', {
      schemaId: schemasState.selectedLedgerSchema.id,
      verificationLabel,
      attributes,
    })

    closeModal()
  }

  const toggleAttribute = (attribute) => {
    setSelectedAttributes((prev) => ({
      ...prev,
      [attribute]: !prev[attribute],
    }))
  }

  return (
    <StyledPopup
      open={props.addVerificationDefinitionModalIsOpen}
      closeOnDocumentClick
      onClose={closeModal}
    >
      <Modal className="modal">
        <ModalHeader>
          {schemasState.selectedLedgerSchema
            ? 'Define Verification Schema'
            : 'Add New Verification Schema'}
        </ModalHeader>
        <ModalContentWrapper>
          <ModalContent>
            {!schemasState.selectedLedgerSchema ? (
              <form id="form" onSubmit={handleSubmit} ref={schemaIdForm}>
                <InputBox>
                  <ModalLabel htmlFor="schema_id">Schema ID</ModalLabel>
                  <StyledInputFieldModal
                    type="text"
                    name="schema_id"
                    id="schema_id"
                    placeholder="5N5U5dLtgGnZWcUTwg9CSM:2:User:1.0"
                    required
                  />
                </InputBox>
                <Actions>
                  <CancelBtn
                    type="button"
                    onClick={closeModal}
                    id="button-cancel"
                  >
                    Cancel
                  </CancelBtn>
                  <SubmitBtnModal
                    type="submit"
                    ref={submitBtn}
                    id="button-submit"
                  >
                    Submit
                  </SubmitBtnModal>
                </Actions>
              </form>
            ) : (
              <form id="form-new" onSubmit={handleSubmitSchema}>
                <InputBox>
                  <StyledModalLabel htmlFor="verification_label">
                    Label
                  </StyledModalLabel>
                  <StyledInputFieldModal
                    type="hidden"
                    name="disable_autocomplete_trick"
                  />
                  <StyledInputFieldModal
                    type="text"
                    name="verification_label"
                    id="verification_label"
                    placeholder="Enter label"
                    autoComplete="off"
                    required
                  />
                </InputBox>
                <div>
                  <CheckboxHolder>
                    {schemasState.selectedLedgerSchema.attrNames.map((attr) => (
                      <AttributeHolder key={attr}>
                        <Checkbox
                          type="checkbox"
                          checked={selectedAttributes[attr] || false}
                          onChange={() => toggleAttribute(attr)}
                          id={attr}
                        />
                        <label htmlFor={attr}>{attr}</label>
                      </AttributeHolder>
                    ))}
                  </CheckboxHolder>
                </div>
                <Actions>
                  <Actions>
                    <CancelBtn
                      type="button"
                      onClick={resetSchemaSelection}
                      id="button-cancel"
                    >
                      Back
                    </CancelBtn>
                    <SubmitBtnModal
                      type="submit"
                      ref={submitBtn}
                      id="button-submit"
                    >
                      Submit
                    </SubmitBtnModal>
                  </Actions>
                </Actions>
              </form>
            )}
          </ModalContent>
        </ModalContentWrapper>
        <CloseBtn onClick={closeModal} id="button-exit">
          &times;
        </CloseBtn>
      </Modal>
    </StyledPopup>
  )
}

export default FormVerificationDefinitionAdd
