const initialState = {
  transactions: [],
  userRole: '',
  recentAuthorInvitation: {},
}

const SET_TRANSACTIONS = 'SET_TRANSACTIONS'
const SET_USER_ROLE = 'SET_USER_ROLE'
const SET_RECENT_AUTHOR_INVITATION = 'SET_RECENT_AUTHOR_INVITATION'

export function setRecentAuthorInvitation(invitation) {
  return {
    type: SET_RECENT_AUTHOR_INVITATION,
    payload: invitation,
  }
}
export function setTransactions(transactions) {
  return {
    type: SET_TRANSACTIONS,
    payload: transactions,
  }
}
export function setUserRole(role) {
  console.log('Setting user role, within transactions, to ', role)
  return {
    type: SET_USER_ROLE,
    payload: role,
  }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_RECENT_AUTHOR_INVITATION:
      return { ...state, recentAuthorInvitation: action.payload }

    case SET_TRANSACTIONS:
      return { ...state, transactions: action.payload }

    case SET_USER_ROLE:
      return { ...state, userRole: action.payload }

    default:
      return state
  }
}
