import { useSelector } from 'react-redux'

import PageHeader from './PageHeader'
import PageSection from './PageSection'

import { DataTable, DataRow, DataHeader, DataCell } from './CommonStylesTables'

function Credentials(props) {
  const credentials = useSelector((state) => state.credentials.credentials)

  const history = props.history
  function openCredential(history, id) {
    if (history !== undefined) {
      history.push('/admin/credentials/' + id)
    }
  }

  const credentialRows = credentials.map((credential_record) => {
    let credentialName = ''
    const credential_id = credential_record.credential_exchange_id
    const credentialState = credential_record.state.replaceAll('_', ' ') || ''
    const dateCreated =
      new Date(credential_record.created_at).toLocaleString() || ''

    if (credential_record.attributes?.credential) {
      credentialName = credential_record.attributes.credential.name
    } else {
      credentialName = credential_record.schema_id
        ?.split(':')[2]
        .replaceAll('_', ' ')
    }

    const revoked = credential_record.revoked === true ? 'true' : 'false'

    return (
      <DataRow
        key={credential_id}
        onClick={() => {
          openCredential(history, credential_id)
        }}
      >
        <DataCell>{credentialName}</DataCell>
        <DataCell className="title-case">{credentialState}</DataCell>
        <DataCell>{revoked}</DataCell>
        <DataCell>{dateCreated}</DataCell>
      </DataRow>
    )
  })

  return (
    <>
      <div id="credentials">
        <PageHeader title={'Credentials'} />
        <PageSection>
          <DataTable>
            <thead>
              <DataRow>
                <DataHeader>Credential</DataHeader>
                <DataHeader>Status</DataHeader>
                <DataHeader>Revoked</DataHeader>
                <DataHeader>Date Issued</DataHeader>
              </DataRow>
            </thead>
            <tbody>{credentialRows}</tbody>
          </DataTable>
        </PageSection>
      </div>
    </>
  )
}

export default Credentials
