export const handleImageSrc = (rawImage) => {
  let bytes = new Uint8Array(rawImage)

  let base64Image = ''
  for (let i = 0; i < bytes.length; i++) {
    base64Image += String.fromCharCode(bytes[i])
  }

  // Check the MIME type
  let result = null
  if (base64Image.charAt(0) === 'i') {
    result = `data:image/png;base64,${base64Image}`
  } else if (base64Image.charAt(0) === '/') {
    result = `data:image/jpeg;base64,${base64Image}`
  } else if (base64Image.charAt(0) === 'R') {
    result = `data:image/gif;base64,${base64Image}`
  } else if (base64Image.charAt(0) === 'U') {
    result = `data:image/webp;base64,${base64Image}`
  } else {
    result = base64Image
  }
  return result
}

// Format seconds
export function formatSeconds(secs) {
  function pad(n) {
    return n < 10 ? '0' + n : n
  }

  var h = Math.floor(secs / 3600)
  var m = Math.floor(secs / 60) - h * 60
  var s = Math.floor(secs - h * 3600 - m * 60)

  // return pad(h) +":"+ pad(m) +":"+ pad(s);
  return pad(m) + ':' + pad(s)
}
export const getNextId = (array, idKey) => {
  let idList = []
  let nextId = null
  array.forEach((element) => {
    idList.push(element[idKey])
  })
  if (idList.length !== 0) {
    nextId = Math.max.apply(0, idList) // (eldersonar) Math.max is great for small arrays only (up to ~120000)
    console.log(idList)
    console.log(nextId)
  } else {
    nextId = 0
  }
  nextId++
  return nextId
}

// Format the date (to the minute)
export const formattedDate = (ISO8601Date) => {
  const formattedDate = new Date(ISO8601Date).toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    // second: '2-digit',
  })
  return formattedDate
}
