import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import PageHeader from './PageHeader'

import Authors from './Authors'
import Transactions from './Transactions'
import { setAuthorInvMsgID, setAuthorInvID } from '../redux/endorserReducer'

function Endorsements(props) {
  const dispatch = useDispatch()
  const invitationsState = useSelector((state) => state.invitations)
  const websocketsState = useSelector((state) => state.websockets)
  const { websocket, readyForWebsocketMessages } = websocketsState

  useEffect(() => {
    if (websocket && readyForWebsocketMessages) {
      sendRequest('AUTHORS', 'GET_ALL', {})
    }
  }, [websocket, readyForWebsocketMessages])

  useEffect(() => {
    // Want to get invitation_msg_id
    // Set invitation msg id
    if (
      invitationsState.invitationSelected.their_role &&
      invitationsState.invitationSelected.their_role.toLowerCase() === 'author'
    ) {
      const invitationMsgID =
        invitationsState.invitationSelected.invitation_msg_id
      const invitationID = invitationsState.invitationSelected.invitation_id
      const connID = invitationsState.invitationSelected.connection_id
      console.log('invitation msg id: ', invitationMsgID)
      // Need to check that the role is author

      dispatch(setAuthorInvMsgID(invitationMsgID))
      dispatch(setAuthorInvID(invitationID))

      // Set role for the connection
      sendRequest('TRANSACTIONS', 'SET_ROLE', {
        role: 'TRANSACTION_ENDORSER',
        connection_id: connID,
      })
    }
  }, [invitationsState.invitationSelected])

  const { sendRequest } = props

  return (
    <div id="contacts">
      <PageHeader title={'Endorser'} />
      <Authors sendRequest={props.sendRequest} />
      <Transactions sendRequest={props.sendRequest} />
    </div>
  )
}

export default Endorsements
