import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { LoadingHolder, Spinner } from './LoadingStyles'
import { setNotificationState } from '../redux/notificationsReducer'
import { setWaitingForInvitations } from '../redux/invitationsReducer'
import {
  Button,
  CloseBtn,
  InputBox,
  InputFieldModal,
  Modal,
  ModalContentWrapper,
  ModalContent,
  ModalHeader,
  ModalLabel,
  StyledPopup,
} from './CommonStylesForms'

import { IconWait } from './CommonStylesTables'

// Style components
const ColumnarInputBox = styled(InputBox)`
  display: flex;
  flex-direction: column;
`
const DivBreak = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
const DivBreakBottom = styled(DivBreak)``
const EndorserFlowSubmittedDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px 16px 0;
`
const EndorserModalLabel = styled(ModalLabel)`
  width: 100%;
`
const PreformattedText = styled.pre`
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
`
const StyledButton = styled(Button)`
  margin-left: 15px;
`
const CloseButton = styled(Button)``

function Endorser(props) {
  const dispatch = useDispatch()
  const contactsState = useSelector((state) => state.contacts)
  const endorserState = useSelector((state) => state.endorser)
  const invitationState = useSelector((state) => state.invitations)
  const schemasState = useSelector((state) => state.schemas)
  const settingsState = useSelector((state) => state.settings)

  const TAA = schemasState.TAA
  const [TAAAccepted, setTAAAccepted] = useState('')
  const { sendRequest } = props

  useEffect(() => {
    if (
      !schemasState.issuerDID &&
      TAA.taa_accepted?.mechanism &&
      props.createDIDPressed &&
      props.userRole !== 'author'
    ) {
      sendRequest('SCHEMAS', 'CREATE_DID', {})
    }
  }, [TAA.taa_accepted, props.createDIDPressed])

  const closeEndorserModal = () => {
    props.closeEndorserModal()
  }

  useEffect(() => {
    if (schemasState.issuerDID) {
      closeEndorserModal()
    }
  }, [schemasState.issuerDID])

  const acceptTAA = () => {
    setTAAAccepted('accepted')
    sendRequest('SCHEMAS', 'ACCEPT_TAA', {
      text: schemasState.TAA.taa_record.text,
      version: schemasState.TAA.taa_record.version,
    })
  }
  const declineTAA = () => {
    closeEndorserModal()
    setTAAAccepted('declined')
    props.closeEndorserModal()
    dispatch(
      setNotificationState({
        message: `TAA is declined.`,
        type: 'warning',
      })
    )
  }
  const setPublicDID = () => {
    sendRequest('SCHEMAS', 'SET_PUBLIC_DID', {
      did: schemasState.walletDID.did,
    })
  }

  const submitEndorserConnectionForm = ({ type, data }) => {
    if (!data.startsWith('http')) {
      console.error('Please paste in the full invitation URL.')
      return // Stop the execution of the function
    } else {
      if (type === 'existingConnection') {
        console.log('We are on using an existing connection')
        console.log('The data we received is: ', data)
        const contactId = data.contact_id
        sendRequest('CONTACTS', 'GET', { data: contactId })
        console.log('Contacts state is: ', contactsState)
        // If we have an existing connection, set role on both sides
        // Get connection
      } else if (type === 'externalEndorserInvitation') {
        // TO DO: Need to handle if they don't begin the https portion
        if (data.split('=')[0].split('?')[1] === 'c_i') {
          dispatch(
            setNotificationState({
              message:
                'This type of invitation is not supported for author-endorser connections. Please use OOB invitations.',
              type: 'error',
            })
          )
        } else if (data.split('=')[0].split('?')[1] === 'oob') {
          // console.log('AuthorEndorser connections support OOB invitations')
          closeEndorserModal()
          dispatch(setWaitingForInvitations(true))
          sendRequest('TRANSACTIONS', 'ACCEPT_OOB_INVITATION', data)
        } else {
          console.log(
            'The existingConnection is not supported : ' +
              data.invitation_url.split('=')[0].split('?')[1]
          )
        }
      }
    }
  }

  // UseEffect on endorserInvitation
  useEffect(() => {
    if (
      !invitationState.waitingForInvitations &&
      endorserState.authorInvMsgID
    ) {
      console.log('INVITATIONS ARE: ', invitationState.invitations)
      const mostRecentInvitation = invitationState.invitations.find(
        (invitation) => invitation.inv_msg_id === endorserState.authorInvMsgID
      )
      const connId = mostRecentInvitation.invitation.connection_id
      sendRequest('CONNECTIONS', 'GET_CONNECTIONS', {
        connectionId: connId,
      })
    }
  }, [invitationState.waitingForInvitations])

  // (eldersonar) TODO: needs to be refactored
  useEffect(() => {
    if (props.userRole === 'author') {
      if (
        !invitationState.invitations ||
        !Array.isArray(invitationState.invitations) ||
        invitationState.invitations.length === 0
      ) {
        return
      }

      const mostRecentInvitation = invitationState.invitations.find(
        (invitation) => invitation.inv_msg_id === endorserState.authorInvMsgID
      )
      if (!mostRecentInvitation) {
        return
      }
    }

    const connRecord = contactsState.connection
    if (!connRecord.length || connRecord.length === 0) {
      return
    } else if (props.userRole === 'author' && !props.authorRoleIsSet) {
      sendRequest('TRANSACTIONS', 'SET_ROLE', {
        role: 'TRANSACTION_AUTHOR',
        connection_id: connRecord.connection_id,
      })
    } else if (
      props.userRole === 'endorser' &&
      connRecord.state === 'active' &&
      connRecord.connection_id
    ) {
      sendRequest('TRANSACTIONS', 'SET_ROLE', {
        role: 'TRANSACTION_ENDORSER',
        connection_id: connRecord.connection_id,
      })
    }

    if (
      !props.endorserInfoIsSet &&
      props.authorRoleIsSet &&
      props.userRole === 'author'
    ) {
      sendRequest('TRANSACTIONS', 'SET_ENDORSER_INFO', {
        connection_id: connRecord.connection_id,
        endorser_public_did: connRecord.their_public_did,
        endorser_label: settingsState.organizationName,
      })
    }
  }, [contactsState.connection, props.authorRoleIsSet, props.endorserInfoIsSet])

  const DidAndVerkeyGeneration = () => {
    return (
      <ModalContent>
        <div>
          <p>The following DID Has Been Generated For You:</p>
          <p>{`DID: ${schemasState.walletDID.did}`}</p>
          <p>{`Verkey: ${schemasState.walletDID.verkey}`}</p>
          <br />
          <p>
            Have you anchored the above DID (such as via the&nbsp;
            <a href="https://selfserve.indiciotech.io" target="_blank">
              selfserve.indiciotech.io
            </a>
            &nbsp;or&nbsp;
            <a href="https://selfserve.sovrin.org" target="_blank">
              selfserve.sovrin.org
            </a>
            )
          </p>
          <StyledButton
            onClick={() => {
              setPublicDID()
            }}
          >
            Yes
          </StyledButton>
        </div>
      </ModalContent>
    )
  }

  const GenerateDIDAndVerkeyThroughEndorser = ({ onFormSubmit }) => {
    const [existingConnection, setExistingConnection] = useState(null)
    const [
      externalEndorserInvitation,
      setExternalEndorserInvitation,
    ] = useState('')

    const handleClear = () => {
      setExistingConnection(null)
      setExternalEndorserInvitation('')
    }

    const handleSubmit = (event) => {
      event.preventDefault()
      if (!existingConnection && !externalEndorserInvitation) {
        // TO DO: May need to be notice
        dispatch(
          setNotificationState({
            message: 'Please select a way to connect to an endorser.',
            type: 'error',
          })
        )
        return
      }
      if (existingConnection && externalEndorserInvitation) {
        dispatch(
          setNotificationState({
            message:
              // 'Please either choose an existing connection or paste in an invitation to an endorser but not both.',
              'Please paste in an invitation to an endorser.',
            type: 'error',
          })
        )
        return
      }
      if (existingConnection) {
        onFormSubmit({
          type: 'existingConnection',
          data: existingConnection,
        })
      } else if (externalEndorserInvitation) {
        onFormSubmit({
          type: 'externalEndorserInvitation',
          data: externalEndorserInvitation,
        })
      }
    }

    // TO DO: Of options for existing connections, filter those with a public did and public invitation

    // Options stuff
    return (
      <ModalContent>
        <form onSubmit={handleSubmit}>
          <ColumnarInputBox>
            <EndorserModalLabel htmlFor="externalEndorserInvitation">
              Receive Invitation from Endorser:
            </EndorserModalLabel>
            <InputFieldModal
              type="text"
              name="externalEndorserInvitation"
              id="externalEndorserInvitation"
              value={externalEndorserInvitation}
              onChange={(e) => setExternalEndorserInvitation(e.target.value)}
              style={{ width: '100%' }}
            />
          </ColumnarInputBox>

          <DivBreakBottom>
            <StyledButton type="submit" style={{ marginLeft: '0px' }}>
              Submit
            </StyledButton>
            <StyledButton
              type="button"
              onClick={handleClear}
              style={{ marginLeft: '10px' }}
            >
              Clear
            </StyledButton>
          </DivBreakBottom>
        </form>
      </ModalContent>
    )
  }
  const FetchingTAA = () => {
    return (
      <LoadingHolder>
        <p>Fetching TAA, please wait...</p>
        <Spinner />
      </LoadingHolder>
    )
  }
  const TAAContent = () => {
    return (
      <ModalContent>
        <PreformattedText>{TAA?.taa_record.text}</PreformattedText>
        <div>
          <p>Do you agree to the above Transaction Author Agreement?</p>
          <br />
          <StyledButton onClick={acceptTAA}>Yes</StyledButton>
          <StyledButton onClick={declineTAA}>No</StyledButton>
        </div>
      </ModalContent>
    )
  }

  // (anwalker293) Handle DID Seed
  useEffect(() => {
    if (
      TAA.taa_accepted?.mechanism &&
      schemasState.issuerDID &&
      props.userRole === 'endorser'
    ) {
      props.sendRequest('SCHEMAS', 'SET_PUBLIC_DID', {
        did: schemasState.issuerDID,
      })
      closeEndorserModal()
    }
  }, [TAA.taa_accepted?.mechanism, schemasState.issuerDID])

  useEffect(() => {
    if (
      TAA.taa_accepted?.mechanism &&
      schemasState.issuerDID &&
      props.userRole === 'endorser'
    ) {
      props.sendRequest('SCHEMAS', 'SET_PUBLIC_DID', {
        did: schemasState.issuerDID,
      })
    }
  }, [])

  const TAADeclined = () => {
    return (
      <ModalContent>
        <div>
          <p>
            You have not agreed to the Transaction Author Agreement,
            <br /> please cease use of the Enterprise Application.
            <br /> If this choice was in error, please refresh and try again.{' '}
            <br />
            Or reach out to your Indicio Support Agent.
          </p>
        </div>
      </ModalContent>
    )
  }
  const WaitingForEndorserPublicDIDAcceptance = () => {
    return (
      <ModalContent>
        <EndorserFlowSubmittedDiv>
          <IconWait height="35px" />
          <br />
          <p>
            We are currently waiting for the endorser to accept the request to
            set the Public DID. Please check back later.
          </p>
          <CloseButton
            onClick={() => {
              closeEndorserModal()
            }}
          >
            Close Modal
          </CloseButton>
        </EndorserFlowSubmittedDiv>
      </ModalContent>
    )
  }

  return (
    <StyledPopup
      open={props.endorserModalIsOpen}
      closeOnDocumentClick
      onClose={closeEndorserModal}
    >
      <Modal className="modal">
        <ModalHeader>
          {props.flow[0].toUpperCase() + props.flow.slice(1)} Workflow
        </ModalHeader>
        <ModalContentWrapper>
          {schemasState.TAA.taa_record?.text && !TAA.taa_accepted?.mechanism ? (
            <TAAContent />
          ) : TAAAccepted === 'declined' ? (
            <TAADeclined />
          ) : props.userRole === 'author' &&
            props.nymTransactionStatus === 'request_received' ? (
            <WaitingForEndorserPublicDIDAcceptance />
          ) : TAA.taa_accepted?.mechanism && !schemasState.issuerDID ? (
            props.flow === 'endorser' ? (
              <GenerateDIDAndVerkeyThroughEndorser
                onFormSubmit={submitEndorserConnectionForm}
              />
            ) : (
              <DidAndVerkeyGeneration />
            )
          ) : schemasState.issuerDID ? null : (
            <FetchingTAA />
          )}
          <div />
        </ModalContentWrapper>
        <CloseBtn onClick={closeEndorserModal} id="button-exit">
          &times;
        </CloseBtn>
      </Modal>
    </StyledPopup>
  )
}

export default Endorser
