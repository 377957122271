import { useRef } from 'react'
import styled from 'styled-components'
import {
  Actions,
  CancelBtn,
  CloseBtn,
  InputBox,
  InputFieldModal,
  Modal,
  ModalContentWrapper,
  ModalContent,
  ModalHeader,
  ModalLabel,
  StyledPopup,
  SubmitBtnModal,
} from './CommonStylesForms'

const StyledInputFieldModal = styled(InputFieldModal)`
  width: 100%;
`

function FormSchemaIssuanceAdd(props) {
  const schemaForm = useRef()
  const submitBtn = useRef()

  // Disable button on submit
  const onBtnClick = (e) => {
    if (submitBtn.current) {
      submitBtn.current.setAttribute('disabled', 'disabled')
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    onBtnClick()

    const form = new FormData(schemaForm.current)
    const schema_id = form.get('schema_id')

    const data = { schema_id }

    // (anwalker) If we're an author, make a transaction
    if (props.userRole) {
      data.user_role = props.userRole
    }
    props.sendRequest('SCHEMAS', 'ADD_ISSUANCE_SCHEMA', data)
    closeModal()
  }

  function closeModal() {
    props.closeAddIssuanceSchemaModal()
  }

  return (
    <StyledPopup
      open={props.addIssuanceSchemaModalIsOpen}
      closeOnDocumentClick
      onClose={closeModal}
    >
      <Modal className="modal">
        <ModalHeader>Add New Issuance Schema</ModalHeader>
        <ModalContentWrapper>
          <ModalContent>
            <form id="form" onSubmit={handleSubmit} ref={schemaForm}>
              <InputBox>
                <ModalLabel htmlFor="schema_id">Schema ID</ModalLabel>
                <StyledInputFieldModal
                  type="text"
                  name="schema_id"
                  id="schema_id"
                  placeholder="5N5U5dLtgGnZWcUTwg9CSM:2:User:1.0"
                  required
                />
              </InputBox>
              <Actions>
                <CancelBtn
                  type="button"
                  onClick={closeModal}
                  id="button-cancel"
                >
                  Cancel
                </CancelBtn>
                <SubmitBtnModal
                  type="submit"
                  ref={submitBtn}
                  id="button-submit"
                >
                  Submit
                </SubmitBtnModal>
              </Actions>
            </form>
          </ModalContent>
        </ModalContentWrapper>
        <CloseBtn onClick={closeModal} id="button-exit">
          &times;
        </CloseBtn>
      </Modal>
    </StyledPopup>
  )
}

export default FormSchemaIssuanceAdd
