import React from 'react';
import poweredByImage from '../assets/powered-by.png'; // Adjust the path as necessary
import indicioImage from '../assets/indicio.png'; // Import the indicio image
import {
  FormContainer,
  LogoHolder,
  Logo,
  Form,
  SubmitBtnModal,
  PoweredBy,
} from '../UI/CommonStylesForms'


const RootPage = () => {
  const navigateToLogin = () => {
    window.location.href = '/auth/login';
  };

  return (
    <FormContainer>
      <LogoHolder>
        <Logo src={indicioImage} alt="Indicio Logo" />
        <Form>Welcome</Form>
        <SubmitBtnModal onClick={navigateToLogin}>Go to Login</SubmitBtnModal>
      </LogoHolder>
      <PoweredBy
        id="powered-by-image"
        src={poweredByImage}
        alt="Powered By Indicio"
      />
    </FormContainer>
  );
};

export default RootPage;