import { useRef, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { IconHelp } from './CommonStylesTables'
import styled, { useTheme } from 'styled-components'
import Select from 'react-select'
import {
  Actions,
  CancelBtn,
  Checkbox,
  CheckboxHolder,
  CloseBtn,
  InputBox,
  Modal,
  ModalContentWrapper,
  ModalContent,
  ModalHeader,
  ModalLabel,
  ModalLabelForDropDown,
  StyledPopup,
  SubmitBtnModal,
} from './CommonStylesForms'

import { setApiKey } from '../redux/multitenancyReducer'

const StyledInputBox = styled(InputBox)`
  justify-content: left;
  margin-left: 50px;
`
const StyledLabel = styled(ModalLabel)`
  margin-right: 0px;
  width: 26%;
`

const ApiKeyLabel = styled(ModalLabel)`
  font-size: 1.3em;
  margin-right: 0px;
  width: 26%;
`

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`
const SelectContainer = styled.div`
  z-index: 100;
  width: 275px;
  margin-right: 15px;
  overflow: visible;
  font-size: 1.3em;
`

function FormSubwalletApiKeyAdd(props) {
  const dispatch = useDispatch()
  const { subwallets, apiKey } = useSelector((state) => state.multitenancy)
  const rolesState = useSelector((state) => state.roles.roles)
  const theme = useTheme()

  const [subwalletsOptions, setSubwalletsOptions] = useState([])
  const [userRolesOptions, setUserRolesOptions] = useState([])
  const [selectedSubwallet, setSelectedSubwallet] = useState({})

  const subwalletForm = useRef()
  const submitBtn = useRef()

  useEffect(() => {
    let options = []

    if (subwallets) {
      for (let i = 0; i < subwallets.length; i++) {
        options.push({
          id: i,
          label: subwallets[i].wallet_label,
          value: subwallets[i].wallet_id,
        })
      }
      setSubwalletsOptions(options)
    }
  }, [subwallets])

  function handleCredChange(e) {
    setSelectedSubwallet(e)
  }

  const SubwalletSelect = () => {
    return (
      <FlexContainer>
        <ModalLabelForDropDown htmlFor="selectedSubwallet">
          Subwallets
        </ModalLabelForDropDown>
        <SelectContainer>
          <Select
            className="react-select-container"
            name="selectedSubwallet"
            placeholder="---Choose subwallet---"
            defaultValue={
              Object.keys(selectedSubwallet).length !== 0
                ? selectedSubwallet
                : ''
            }
            onChange={(e) => handleCredChange(e)}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              control: (base) => ({
                ...base,
                fontSize: '1.05em',
              }),
              singleValue: (base) => ({
                ...base,
                fontSize: '1.05em',
              }),
            }}
            options={subwalletsOptions}
            id="button-dropdown-subwallet-form-selection"
          />
        </SelectContainer>
      </FlexContainer>
    )
  }

  const handleCheckboxChange = (event) => {
    let newArray = [...userRolesOptions, event.target.value]
    if (userRolesOptions.includes(event.target.value)) {
      newArray = newArray.filter((s) => s !== event.target.value)
    }
    setUserRolesOptions(newArray)
  }

  const rolesOptions = rolesState.map((role) => {
    return (
      <div key={role.role_id}>
        <Checkbox
          type="checkbox"
          value={role.role_name}
          id={role.role_id}
          onChange={handleCheckboxChange}
        />
        <label htmlFor={role.role_id}>{role.role_name}</label>
      </div>
    )
  })

  const handleSubmit = async (e) => {
    e.preventDefault()
    await props.sendRequest('MULTITENANCY', 'CREATE_API_KEY', {
      wallet_id: selectedSubwallet.value,
      roles: userRolesOptions,
    })
  }

  function closeModal() {
    props.closeSubwalletApiKeyAddModal()
    setUserRolesOptions([])
    dispatch(setApiKey({}))
  }

  return (
    <StyledPopup
      open={props.subwalletApiKeyAddModalIsOpen}
      closeOnDocumentClick
      onClose={closeModal}
    >
      {Object.keys(apiKey ?? {}).length === 0 ? (
        <Modal className="modal">
          <ModalHeader>Create New Api Key</ModalHeader>
          <ModalContentWrapper>
            <ModalContent>
              <form id="form" onSubmit={handleSubmit} ref={subwalletForm}>
                <StyledInputBox>
                  <SubwalletSelect />
                </StyledInputBox>
                <StyledInputBox>
                  <StyledLabel htmlFor="roles">
                    Roles
                    <IconHelp
                      alt="Help"
                      data-tip
                      data-for="rolesTip"
                      data-delay-hide="250"
                    />
                  </StyledLabel>
                  <ReactTooltip
                    id="rolesTip"
                    effect="solid"
                    type="info"
                    backgroundColor={theme.primary_color}
                  >
                    <span>
                      Select which roles to
                      <br />
                      assign to the new API key.
                    </span>
                  </ReactTooltip>
                  <CheckboxHolder>{rolesOptions}</CheckboxHolder>
                </StyledInputBox>
                <Actions>
                  <CancelBtn
                    type="button"
                    onClick={closeModal}
                    id="button-cancel"
                  >
                    Cancel
                  </CancelBtn>
                  <SubmitBtnModal
                    type="submit"
                    ref={submitBtn}
                    id="button-submit"
                  >
                    Submit
                  </SubmitBtnModal>
                </Actions>
              </form>
            </ModalContent>
          </ModalContentWrapper>
          <CloseBtn onClick={closeModal} id="button-exit">
            &times;
          </CloseBtn>
        </Modal>
      ) : (
        <Modal className="modal">
          <ModalHeader>New Api Key</ModalHeader>
          <ModalContentWrapper>
            <ModalContent>
              <div>
                <ApiKeyLabel>
                  <strong>API Key: </strong>
                </ApiKeyLabel>
                {apiKey.apiKey}
              </div>
              <div>
                <ApiKeyLabel>
                  <strong>Key ID: </strong>
                </ApiKeyLabel>
                {apiKey.keyId}
              </div>
              <Actions>
                <CancelBtn
                  type="button"
                  onClick={closeModal}
                  id="button-cancel"
                >
                  Close
                </CancelBtn>
              </Actions>
            </ModalContent>
          </ModalContentWrapper>
          <CloseBtn onClick={closeModal} id="button-exit">
            &times;
          </CloseBtn>
        </Modal>
      )}
    </StyledPopup>
  )
}

export default FormSubwalletApiKeyAdd
