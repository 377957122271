import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { CanUser } from './CanUser'

import FormSubwalletAdd from './FormSubwalletAdd'
import FormSubwalletApiKeyAdd from './FormSubwalletApiKeyAdd'
import FormSubwalletApiKeyRevoke from './FormSubwalletApiKeyRevoke'

import { Button } from './CommonStylesForms'
import styled from 'styled-components'
import { DataTable, DataRow, DataHeader, DataCell } from './CommonStylesTables'
import PageHeader from './PageHeader'
import PageSection from './PageSection'

const StyledButton = styled(Button)`
  margin-left: 15px;
  margin-bottom: 15px;
`

function Multitenancy(props) {
  const multiTenancyState = useSelector((state) => state.multitenancy)
  // const roles = useSelector((state) => state.roles)

  const [subwalletAddModalIsOpen, setSubwalletAddModalIsOpen] = useState(false)
  const [
    subwalletApiKeyAddModalIsOpen,
    setSubwalletApiKeyAddModalIsOpen,
  ] = useState(false)
  const [
    subwalletApiKeyRevokeModalIsOpen,
    setSubwalletApiKeyRevokeModalIsOpen,
  ] = useState(false)

  const closeSubwalletAddModal = () => setSubwalletAddModalIsOpen(false)
  const createSubwallet = () => {
    setSubwalletAddModalIsOpen(true)
  }

  const closeSubwalletApiKeyAddModal = () =>
    setSubwalletApiKeyAddModalIsOpen(false)
  const createApiKey = () => {
    setSubwalletApiKeyAddModalIsOpen(true)
  }

  const closeSubwalletApiKeyRevokeModal = () =>
    setSubwalletApiKeyRevokeModalIsOpen(false)
  const revokeApiKey = () => {
    setSubwalletApiKeyRevokeModalIsOpen(true)
  }

  const issuanceSchemaRows = multiTenancyState.subwallets.map((subwallet) => {
    return (
      <DataRow key={subwallet.wallet_id}>
        <DataCell>{subwallet.wallet_name}</DataCell>
        <DataCell>{subwallet.wallet_label}</DataCell>
        <DataCell>{subwallet.wallet_id}</DataCell>
      </DataRow>
    )
  })

  return (
    <div id="multi-tenancy">
      <PageHeader title={'Multitenancy Management'} />
      <CanUser
        perform="multitenancy:createWallet"
        yes={() => (
          <PageSection>
            <h2>Subwallets</h2>
            <StyledButton onClick={createSubwallet}>
              Create Subwallet
            </StyledButton>
            <DataTable>
              <thead>
                <DataRow>
                  <DataHeader>Wallet Name</DataHeader>
                  <DataHeader>Wallet Label</DataHeader>
                  <DataHeader>Wallet ID</DataHeader>
                </DataRow>
              </thead>
              <tbody>{issuanceSchemaRows}</tbody>
            </DataTable>
          </PageSection>
        )}
        no={() => null}
      />

      <PageSection>
        <h2>API Keys</h2>
        <StyledButton onClick={createApiKey}>Create API Key</StyledButton>
        <StyledButton onClick={revokeApiKey}>Revoke API Key</StyledButton>
      </PageSection>
      <FormSubwalletAdd
        sendRequest={props.sendRequest}
        subwalletAddModalIsOpen={subwalletAddModalIsOpen}
        closeSubwalletAddModal={closeSubwalletAddModal}
      />
      <FormSubwalletApiKeyAdd
        sendRequest={props.sendRequest}
        subwalletApiKeyAddModalIsOpen={subwalletApiKeyAddModalIsOpen}
        closeSubwalletApiKeyAddModal={closeSubwalletApiKeyAddModal}
      />
      <FormSubwalletApiKeyRevoke
        sendRequest={props.sendRequest}
        subwalletApiKeyRevokeModalIsOpen={subwalletApiKeyRevokeModalIsOpen}
        closeSubwalletApiKeyRevokeModal={closeSubwalletApiKeyRevokeModal}
      />
    </div>
  )
}

export default Multitenancy
