import Axios from 'axios'
import jwt_decode from 'jwt-decode'
import QRCode from 'qrcode.react'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import {AppStoreButtons} from "./AppStoreButtons"

import { setNotificationState } from '../redux/notificationsReducer'
import { setInvitationURL } from '../redux/invitationsReducer'

import { LogoHolder, Logo } from './CommonStylesForms'

const QR = styled(QRCode)`
  display: block;
  margin: auto;
  padding: 20px;
  width: 300px;
  height: 300px;
  background-color: #FFFFFF;`

const HeaderVerify = styled.div`
  color: ${(props) => props.theme.primary_color};
  border-bottom: 2px solid #ddd;
  margin-bottom: 30px;
  font-size: 1.9em;
  padding-top: 1em;
  padding-bottom: 3px;`

const FormWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2%;
  background: #FFFFFF;`

function EmailVerification(props) {
  const dispatch = useDispatch()
  const settingsState = useSelector((state) => state.settings)
  const invitationsState = useSelector((state) => state.invitations)
  const contactsState = useSelector((state) => state.contacts)
  const logo = settingsState.logo

  const [waitingForInvitation, setWaitingForInvitation] = useState(false)
  const [connected, setConnected] = useState(false)
  const [waitingForConnection, setWaitingForConnection] = useState(false)

  const token = window.location.hash.substring(1)
  useEffect(() => {
    // Kick the user off this page if trying to access without a token
    if (!token) {
      console.log('No token')
      return
    }

    const decoded = jwt_decode(token)
    // Check if the token is expired
    if (Date.now() >= decoded.exp * 1000) {
      console.log('This link has expired')
      dispatch(
        setNotificationState({
          message: 'This link has expired',
          type: 'error',
        })
      )
      props.history.push('/auth/login')
    }

    // Check the token on the back end
    Axios({
      method: 'POST',
      data: {
        token: token,
      },
      url: '/api/email/invite',
    }).then((res) => {
      if (
        !waitingForInvitation &&
        props.websocket &&
        props.readyForMessages
      ) {
        dispatch(setInvitationURL(''))
        props.sendRequest('OUT_OF_BAND', 'EMAIL_INVITATION', {
          handshakeProtocol: 'https://didcomm.org/didexchange/1.1',
          alias: 'Invitation',
          invitationMode: 'once',
          accept: 'auto',
          public: true,
          invitationRole: 'Holder',
          invitationLabel: settingsState.organizationName,
          invitationStatus: 'active',
          invitationDescription: "Invitation for credential verification.",
          invitationActiveStartingAt: Date.now(),
          invitationActiveEndingAt: null,
          usesAllowed: 1,
          token,
          walletId: decoded.wallet_id,
        })
        setWaitingForInvitation(true) 
      }
    }).catch((res)=>{
        dispatch(
          setNotificationState({ message: res.response.data.error, type: 'error' })
        )
        props.history.push('/auth/login')
    })
  }, [])

  useEffect(() => {
    if (invitationsState.invitationURL !== '') {
      setWaitingForConnection(true)

      // setWaitingForInvitation(false) // (eldersonar) Do it only if you want to get a new invitation when a websocket re-connects
    }
    if (contactsState.connection.state === 'active' && waitingForConnection) {
      setConnected(true)

      // (eldersoanr) Keep session alive by resetting expiration time
      Axios({
        method: 'GET',
        url: '/api/check-session',
        params: {
          resetExpiration: true,
        },
      })
    }
  }, [
    invitationsState.invitationURL,
    contactsState.connection,
    waitingForConnection,
  ])

  return (
    <FormWrapper>
      <LogoHolder>
        {logo ? <Logo src={logo} alt="Logo" /> : <Logo />}
      </LogoHolder>
      <HeaderVerify>
        Connect to Receive Your Credential
      </HeaderVerify>
      <p>
        Please open your Holdr+, or other wallet, and scan the following QR code to receive your credential!
      </p>
      {!connected ? (
        <>
          {invitationsState.invitationURL ? (
            <div className="qr">
              <p>
                <QR
                  value={invitationsState.invitationURL}
                  size={256}
                  renderAs="svg"
                />
              </p>
            </div>
          ) : (
            <p>
              <span>Loading...</span>
            </p>
          )}
        <AppStoreButtons/>
      </>
      ) : (
        <p>
          Connection established. Please, check your holder agent for credential
          offer.
        </p>
      )}
    </FormWrapper>
  )
}

export default EmailVerification
