const initialState = {
  issuerDID: '',
  schemas: [],
  tempLedgerSchemas: [],
  schemaSelected: {},
  verificationDefinitions: [],
  TAA: {},
  walletDID: {},
  hideInactiveIssuanceSchemas: false,
  hideInactiveVerificationDefinitions: false,
  selectedLedgerSchema: null,
  endorserFlow: false,
  createInvForm: false,
  authorInvitation: '',
  publicConnections: [],
}

const GET_DID = 'GET_DID'
const GET_TAA = 'GET_TAA'
const SET_SCHEMAS = 'SET_SCHEMAS'
const SET_TEMP_LEDGER_SCHEMAS = 'SET_TEMP_LEDGER_SCHEMAS'
const SET_SCHEMA_SELECTED = 'SET_SCHEMA_SELECTED'
const CLEAR_SCHEMAS_STATE = 'CLEAR_SCHEMAS_STATE'
const SET_VERIFICATION_DEFINITIONS = 'SET_VERIFICATION_DEFINITIONS'
const SET_WALLET_DID = 'SET_WALLET_DID'
const SET_HIDE_INACTIVE_ISSUANCE_SCHEMAS = 'SET_HIDE_INACTIVE_ISSUANCE_SCHEMAS'
const SET_HIDE_INACTIVE_VERIFICATION_DEFINITIONS =
  'SET_HIDE_INACTIVE_VERIFICATION_DEFINITIONS'
const SET_SELECTED_LEDGER_SCHEMA = 'SET_SELECTED_LEDGER_SCHEMA'
const SET_CREATE_INV_FORM = 'SET_CREATE_INV_FORM'
const SET_AUTHOR_INVITATION = 'SET_AUTHOR_INVITATION'
const SET_PUBLIC_CONNECTIONS = 'SET_PUBLIC_CONNECTIONS'

export function getDID(DID) {
  return {
    type: GET_DID,
    payload: DID,
  }
}

export function getTAA(TAA) {
  return {
    type: GET_TAA,
    payload: TAA,
  }
}

export function setAuthorInvitation(invitationUrl) {
  return {
    type: SET_AUTHOR_INVITATION,
    payload: invitationUrl,
  }
}

export function setCreateInvForm(createInvForm) {
  return {
    type: SET_CREATE_INV_FORM,
    payload: createInvForm,
  }
}

export function setPublicConnections(publicConnections) {
  return {
    type: SET_PUBLIC_CONNECTIONS,
    payload: publicConnections,
  }
}

export function setSchemas(schemas) {
  return {
    type: SET_SCHEMAS,
    payload: schemas,
  }
}

export function setTempLedgerSchemas(tempLedgerSchemas) {
  return {
    type: SET_TEMP_LEDGER_SCHEMAS,
    payload: tempLedgerSchemas,
  }
}

export function setSchemaSelected(schema) {
  return {
    type: SET_SCHEMA_SELECTED,
    payload: schema,
  }
}

export function setVerificationDefinitions(verificationDefinitions) {
  return {
    type: SET_VERIFICATION_DEFINITIONS,
    payload: verificationDefinitions,
  }
}

export function clearSchemasState() {
  return {
    type: CLEAR_SCHEMAS_STATE,
    payload: null,
  }
}

export function setWalletDID(walletDID) {
  return {
    type: SET_WALLET_DID,
    payload: walletDID,
  }
}

export function setHideInactiveIssuanceSchemas(hideInactiveIssuanceSchemas) {
  return {
    type: SET_HIDE_INACTIVE_ISSUANCE_SCHEMAS,
    payload: hideInactiveIssuanceSchemas,
  }
}

export function setHideInactiveVerificationDefinitions(
  hideInactiveVerificationDefinitions
) {
  return {
    type: SET_HIDE_INACTIVE_VERIFICATION_DEFINITIONS,
    payload: hideInactiveVerificationDefinitions,
  }
}

export function setSelectedLedgerSchema(selectedLedgerSchema) {
  return {
    type: SET_SELECTED_LEDGER_SCHEMA,
    payload: selectedLedgerSchema,
  }
}

export default function schemasReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DID:
      return { ...state, issuerDID: action.payload }

    case GET_TAA:
      return { ...state, TAA: action.payload }

    case SET_SCHEMAS:
      return { ...state, schemas: action.payload }

    case SET_TEMP_LEDGER_SCHEMAS:
      return { ...state, tempLedgerSchemas: action.payload }

    case SET_SCHEMA_SELECTED:
      return { ...state, schemaSelected: action.payload }

    case SET_VERIFICATION_DEFINITIONS:
      return { ...state, verificationDefinitions: action.payload }

    case CLEAR_SCHEMAS_STATE:
      return initialState

    case SET_WALLET_DID:
      return { ...state, walletDID: action.payload }

    case SET_AUTHOR_INVITATION:
      return { ...state, authorInvitation: action.payload }

    case SET_CREATE_INV_FORM:
      return { ...state, createInvForm: action.payload }

    case SET_HIDE_INACTIVE_ISSUANCE_SCHEMAS:
      return { ...state, hideInactiveIssuanceSchemas: action.payload }

    case SET_HIDE_INACTIVE_VERIFICATION_DEFINITIONS:
      return { ...state, hideInactiveVerificationDefinitions: action.payload }

    case SET_SELECTED_LEDGER_SCHEMA:
      return { ...state, selectedLedgerSchema: action.payload }

    default:
      return state
  }
}
