const initialState = {
  roles: [],
}

const SET_ROLES = 'SET_ROLES'

export function setRoles(roles) {
  return {
    type: SET_ROLES,
    payload: roles,
  }
}


export default function rolesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ROLES:
      return { ...state, roles: action.payload }
    default:
      return state
  }
}
