import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { CanUser } from './CanUser'

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  & ul {
    display: none;
    position: relative;
    padding: 0 0 0 20px;
  }
`
const Item = styled.li`
  border-bottom: ${(props) => props.theme.border};
  &:first-child {
    border-top: ${(props) => props.theme.border};
  }
  & li,
  & li:first-child {
    border: none;
  }
  & a.active {
    border-right: 3px solid ${(props) => props.theme.primary_color};
    background: ${(props) => props.theme.text_light};
    color: ${(props) => props.theme.primary_color};
  }
  &.active ul {
    display: block;
  }
`
const StyledLink = styled(NavLink)`
  display: block;
  padding: 20px 0 20px 20px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  color: ${(props) => props.theme.text_color};
  &:hover,
  &.active {
    text-decoration: underline;
    color: ${(props) => props.theme.secondary_color};
    border-right: 3px solid ${(props) => props.theme.secondary_color};
    background: ${(props) => props.theme.background_secondary};
  }
`
const StyledSubLink = styled(NavLink)`
  display: block;
  padding: 10px 0 10px 20px;
  font-size: 14px;
  text-decoration: none;
  color: ${(props) => props.theme.text_color};
  &:hover,
  &.active {
    text-decoration: underline;
    color: ${(props) => props.theme.primary_color};
    background: none;
  }
`
function AppMenu(props) {
  const loginState = useSelector((state) => state.login)
  const endorserState = useSelector((state) => state.endorser)

  let pathMatch = ''
  if (props.match.path !== undefined) {
    pathMatch = props.match.path
  }

  if (loginState.loggedIn) {
    return (
      <nav id="app-menu">
        <List>
          <Item className={pathMatch === '/' ? 'active' : undefined}>
            <StyledLink exact to="/admin" id="section-home">
              Home
            </StyledLink>
          </Item>
          <CanUser
            perform="invitations:read"
            yes={() => (
              <Item
                className={
                  pathMatch.includes('/invitations') ? 'active' : undefined
                }
              >
                <StyledLink to="/admin/invitations" id="section-invitations">
                  Invitations
                </StyledLink>
              </Item>
            )}
          />
          <CanUser
            perform="contacts:read"
            yes={() => (
              <Item
                className={
                  pathMatch.includes('/contacts') ? 'active' : undefined
                }
              >
                <StyledLink to="/admin/contacts" id="section-contacts">
                  Contacts
                </StyledLink>
              </Item>
            )}
          />
          <CanUser
            perform="credentials:read"
            yes={() => (
              <Item
                className={pathMatch === '/credentials' ? 'active' : undefined}
              >
                <StyledLink to="/admin/credentials" id="section-credentials">
                  Credentials
                </StyledLink>
              </Item>
            )}
          />
          <CanUser
            perform="presentations:read"
            yes={() => (
              <Item
                className={
                  pathMatch === '/admin/presentations' ? 'active' : undefined
                }
              >
                <StyledLink
                  to="/admin/presentations"
                  id="section-presentations"
                >
                  Presentations
                </StyledLink>
              </Item>
            )}
          />
          {/* <CanUser
            perform="credentialsReceived:read"
            yes={() => (
              <Item className={pathMatch === '/wallet' ? 'active' : undefined}>
                <StyledLink to="/admin/wallet" id="section-wallet">
                  Wallet
                </StyledLink>
              </Item>
            )}
          /> */}
          <CanUser
            perform="settings:read"
            yes={() => (
              <>
                <Item
                  className={pathMatch === '/settings' ? 'active' : undefined}
                >
                  <StyledLink to="/admin/settings" id="section-settings">
                    Settings
                  </StyledLink>
                </Item>
              </>
            )}
            no={() => ''}
          />
          <CanUser
            perform="settings:read"
            yes={() => (
              <>
                <Item
                  className={pathMatch === '/schemas' ? 'active' : undefined}
                >
                  <StyledLink to="/admin/schemas" id="section-schemas">
                    Schemas
                  </StyledLink>
                </Item>
              </>
            )}
            no={() => ''}
          />
          <CanUser
            perform="multitenancy:createAPIKey"
            yes={() => (
              <>
                <Item
                  className={
                    pathMatch === '/multi-tenancy' ? 'active' : undefined
                  }
                >
                  <StyledLink
                    to="/admin/multi-tenancy"
                    id="section-mutlti-tenancy"
                  >
                    Multi-Tenancy
                  </StyledLink>
                </Item>
              </>
            )}
            no={() => ''}
          />
          {endorserState.userRole === 'endorser' ? (
            <CanUser
              perform="settings:read"
              yes={() => (
                <>
                  <Item
                    className={
                      pathMatch === '/endorsements' ? 'active' : undefined
                    }
                  >
                    <StyledLink
                      to="/admin/endorsements"
                      id="section-endorsements"
                    >
                      Endorsements
                    </StyledLink>
                  </Item>
                </>
              )}
              no={() => ''}
            />
          ) : null}

          <CanUser
            perform="governanceFiles:set"
            yes={() => (
              <>
                <Item
                  className={
                    pathMatch === '/admin/governance' ? 'active' : undefined
                  }
                >
                  <StyledLink
                    exact
                    to="/admin/governance"
                    id="section-governance"
                  >
                    Governance
                  </StyledLink>
                </Item>
              </>
            )}
            no={() => ''}
          />

          <CanUser
            perform="governanceEditorFiles:set"
            yes={() => (
              <>
                <Item
                  className={
                    pathMatch.includes('/admin/governance/governance-editor')
                      ? 'active'
                      : undefined
                  }
                >
                  <StyledLink
                    to="/admin/governance/governance-editor"
                    id="section-governance-editor"
                  >
                    Governance Editor
                  </StyledLink>
                  <List>
                    <Item
                      className={
                        pathMatch ===
                        '/admin/governance/governance-editor/schemas'
                          ? 'active'
                          : undefined
                      }
                    >
                      <StyledSubLink
                        exact
                        to="/admin/governance/governance-editor/schemas"
                        id="section-governance-editor-schemas"
                      >
                        Schemas
                      </StyledSubLink>
                    </Item>
                    <Item
                      className={
                        pathMatch ===
                        '/admin/governance/governance-editor/participants'
                          ? 'active'
                          : undefined
                      }
                    >
                      <StyledSubLink
                        exact
                        to="/admin/governance/governance-editor/participants"
                        id="section-governance-editor-participants"
                      >
                        Participants
                      </StyledSubLink>
                    </Item>
                  </List>
                </Item>
              </>
            )}
            no={() => ''}
          />

          <Item className={pathMatch === '/help' ? 'active' : undefined}>
            <StyledLink exact to="/admin/help" id="section-help">
              Help
            </StyledLink>
          </Item>
        </List>
      </nav>
    )
  } else return null
}
export default AppMenu
