const initialState = {
  proofReports: [],
}

const SET_PROOFS_REPORTS = 'SET_PROOFS_REPORTS'
const CLEAR_PROOFS_STATE = 'CLEAR_PROOFS_STATE'

export function setProofReports(proofReports) {
  return {
    type: SET_PROOFS_REPORTS,
    payload: proofReports,
  }
}

export function clearProofsState() {
  return {
    type: CLEAR_PROOFS_STATE,
    payload: null,
  }
}

export default function proofsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PROOFS_REPORTS:
      return { ...state, proofReports: action.payload }

    case CLEAR_PROOFS_STATE:
      return initialState

    default:
      return state
  }
}
