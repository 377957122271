import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import PageHeader from './PageHeader.js'
import PageSection from './PageSection.js'
import { setSchemaSelected } from '../redux/schemasReducer.js'

import { AttributeTable, AttributeRow } from './CommonStylesTables'

function Schema(props) {
  const dispatch = useDispatch()
  const schemasState = useSelector((state) => state.schemas)
  const schemaSelected = schemasState.schemaSelected
  const schemaId = props.id
  let [schemaAttributes, setSchemaAttributes] = useState([])
  let mappedAttributes = null

  useEffect(() => {
    if (schemasState.verificationDefinitions) {
      for (let i = 0; i < schemasState.verificationDefinitions.length; i++) {
        if (schemasState.verificationDefinitions[i].id === Number(schemaId)) {
          dispatch(setSchemaSelected(schemasState.verificationDefinitions[i]))
          setSchemaAttributes(schemaSelected.attribute_names)
          break
        }
      }
    }
  }, [schemasState.verificationDefinitions, schemaSelected, schemaId, dispatch])

  if (
    schemaSelected.schema_id !== null &&
    schemaSelected.schema_id !== undefined &&
    schemaAttributes !== undefined
  ) {
    mappedAttributes = schemaAttributes.map((attribute) => {
      return (
        <AttributeRow attribute={attribute} key={attribute}>
          <th></th>
          <td>{attribute}</td>
        </AttributeRow>
      )
    })
  }

  return (
    <>
      <div id="contact">
        <PageHeader
          title={`Schema Details: ${schemaSelected.verification_label}`}
        />
        <PageSection>
          <h2>General Information</h2>
          <AttributeTable>
            <tbody>
              <AttributeRow>
                <th>Label:</th>
                <td>{schemaSelected.verification_label || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Schema ID:</th>
                <td>{schemaSelected.schema_id || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Version:</th>
                <td>{schemaSelected.version || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Active:</th>
                <td>{schemaSelected.active?.toString() || ''}</td>
              </AttributeRow>
            </tbody>
          </AttributeTable>
          <h2>Attributes</h2>
          <AttributeTable>
            <tbody>{mappedAttributes}</tbody>
          </AttributeTable>
        </PageSection>
      </div>
    </>
  )
}

export default Schema
