import { useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'

import styled from 'styled-components'

import {
  CloseBtn,
  InputFieldModal,
  Modal,
  ModalContentWrapper,
  ModalContent,
  ModalHeader,
  ModalLabel,
  StyledPopup,
} from './CommonStylesForms'

const InputTXNBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StandardDataBox = ({ titleString, titleStringConcatenated, value }) => {
  return (
    <InputTXNBox id={titleStringConcatenated}>
      <ModalLabel
        htmlFor={titleStringConcatenated}
        style={{ fontWeight: '600' }}
      >
        {titleString}
      </ModalLabel>
      <InputFieldModal
        type={titleStringConcatenated}
        name={titleStringConcatenated}
        value={value}
        id={titleStringConcatenated}
        required
        style={{ width: '90%', border: 'unset' }}
      />
    </InputTXNBox>
  )
}

const FormNym = ({ row }) => {
  console.log('What did we receive?', row)
  return (
    <>
      {Object.entries(row.transaction_data).map(([key, value]) => {
        console.log(`Key: ${key}, Value: ${value}`) // Log the key and value

        if (value !== null && key !== 'data') {
          const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1)
          return (
            <StandardDataBox
              titleString={capitalizedKey}
              titleStringConcatenated={capitalizedKey}
              value={value}
            />
          )
        }
      })}
    </>
  )
}

const FormDid = ({ row }) => {
  return (
    <>
      {Object.entries(row.transaction_data).map(([key, value]) => {
        console.log(`Key: ${key}, Value: ${value}`) // Log the key and value

        if (value !== null && key !== 'data') {
          const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1)
          return (
            <InputTXNBox key={key}>
              <ModalLabel htmlFor={key} style={{ fontWeight: '600' }}>
                DID To Register:
              </ModalLabel>
              <InputFieldModal
                type={key}
                name={capitalizedKey}
                value={value}
                id={key}
                required
                style={{ width: '90%', border: 'unset' }}
              />
            </InputTXNBox>
          )
        }
      })}
    </>
  )
}

const FormSchema = ({ row }) => {
  const schemaAttrs = row.transaction_data.schemaAttrs
  const schemaID = row.transaction_data.schemaID
  const schemaName = row.transaction_data.schemaName
  const schemaVersion = row.transaction_data.schemaVersion
  return (
    <>
      <StandardDataBox
        titleString="Schema Name"
        titleStringConcatenated="schemaName"
        value={schemaName}
      />
      <StandardDataBox
        titleString="Schema ID"
        titleStringConcatenated="schemaID"
        value={schemaID}
      />
      <StandardDataBox
        titleString="Schema Version"
        titleStringConcatenated="schemaVersion"
        value={schemaVersion}
      />
      <InputTXNBox>
        <ModalLabel
          htmlFor="schemaAttrs"
          style={{ fontWeight: '600', width: '90%' }}
        >
          Schema Attributes
        </ModalLabel>
        {schemaAttrs.map((attr, index) => (
          <InputFieldModal
            key={index}
            type="text"
            name={attr}
            value={attr}
            id={attr}
            required
            style={{ width: '90%', border: 'unset', marginLeft: '4%' }}
          />
        ))}
      </InputTXNBox>
    </>
  )
}

const FormCredDef = ({ row }) => {
  const credDefRef = row.transaction_data.credDefRef
  const credDefId = row.transaction_data.credDefId
  const credDefSignatureType = row.transaction_data.credDefSignatureType
  const credDefTag = row.transaction_data.credDefTag
  return (
    <>
      <StandardDataBox
        titleString="Ref"
        titleStringConcatenated="credDefRef"
        value={credDefRef}
      />
      <StandardDataBox
        titleString="Cred Def ID"
        titleStringConcatenated="credDefId"
        value={credDefId}
      />
      <StandardDataBox
        titleString="Signature Type"
        titleStringConcatenated="credDefSignatureType"
        value={credDefSignatureType}
      />
      <StandardDataBox
        titleString="Tag"
        titleStringConcatenated="credDefTag"
        value={credDefTag}
      />
    </>
  )
}

function FormTransaction(props) {
  const notificationType = useSelector(
    (state) => state.notifications.notificationType
  )

  const invitationForm = useRef()
  const submitBtn = useRef()

  useEffect(() => {
    if (notificationType === 'error' && submitBtn.current) {
      submitBtn.current.removeAttribute('disabled')
    }
  }, [notificationType])

  // Disable button on submit
  const onBtnClick = (e) => {
    if (submitBtn.current) {
      submitBtn.current.setAttribute('disabled', 'disabled')
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    onBtnClick()

    if (props.onFormSubmit) {
      props.onFormSubmit()
    }
    closeCreateInvModal()
  }

  function closeCreateInvModal() {
    props.closeCreateInvModal()
  }

  return (
    <StyledPopup
      open={props.createInvModalIsOpen}
      closeOnDocumentClick
      onClose={closeCreateInvModal}
    >
      <PageContainer>
        <Modal>
          <ModalHeader>Transaction Preview</ModalHeader>
          <ModalContentWrapper>
            <ModalContent>
              <form id="form" onSubmit={handleSubmit} ref={invitationForm}>
                <InputTXNBox>
                  <ModalLabel htmlFor="txnType" style={{ fontWeight: '600' }}>
                    Transaction Type
                  </ModalLabel>
                  <InputFieldModal
                    value={props.row.transaction_type}
                    id="txnType"
                    readOnly
                    style={{ width: '90%', border: 'unset' }}
                  />
                </InputTXNBox>
                <InputTXNBox>
                  <ModalLabel htmlFor="txnID" style={{ fontWeight: '600' }}>
                    Transaction ID
                  </ModalLabel>
                  <InputFieldModal
                    value={props.row.transaction_id}
                    readOnly
                    style={{ width: '90%', border: 'unset' }}
                  />
                </InputTXNBox>
                <InputTXNBox>
                  <ModalLabel
                    htmlFor="authorLabor"
                    style={{ fontWeight: '600' }}
                  >
                    Author Alias
                  </ModalLabel>
                  <InputFieldModal
                    value={props.row.author_alias}
                    readOnly
                    style={{ width: '90%', border: 'unset' }}
                  />
                </InputTXNBox>

                {props.row.transaction_type === 'NYM' && (
                  <FormNym row={props.row} />
                )}
                {props.row.transaction_type === 'ATTRIB' && (
                  <FormDid row={props.row} />
                )}
                {props.row.transaction_type === 'SCHEMA' && (
                  <FormSchema row={props.row} />
                )}
                {props.row.transaction_type === 'CRED DEF' && (
                  <FormCredDef row={props.row} />
                )}
              </form>
            </ModalContent>
          </ModalContentWrapper>
          <CloseBtn onClick={closeCreateInvModal} id="button-exit">
            &times;
          </CloseBtn>
        </Modal>
      </PageContainer>
    </StyledPopup>
  )
}

export default FormTransaction
