const initialState = {
  websocket: false,
  readyForWebsocketMessages: false,
}

const SET_WEBSOCKET = 'SET_WEBSOCKET'
const SET_READY_FOR_WEBSOCKET_MESSAGES = 'SET_READY_FOR_WEBSOCKET_MESSAGES'
const CLEAR_WEBSOCKETS = 'CLEAR_WEBSOCKETS'

export function setWebsocket(websocket) {
  return {
    type: SET_WEBSOCKET,
    payload: websocket,
  }
}
export function setReadyForWebsocketMessages(ready) {
  return {
    type: SET_READY_FOR_WEBSOCKET_MESSAGES,
    payload: ready,
  }
}
export function clearWebsockets() {
  return {
    type: CLEAR_WEBSOCKETS,
    payload: null,
  }
}

export default function websocketsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_WEBSOCKET:
      return { ...state, websocket: action.payload }

    case SET_READY_FOR_WEBSOCKET_MESSAGES:
      return { ...state, readyForWebsocketMessages: action.payload }

    case CLEAR_WEBSOCKETS:
      return initialState

    default:
      return state
  }
}
