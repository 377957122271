import { useRef } from 'react'
import {
  Actions,
  CancelBtn,
  CloseBtn,
  InputBox,
  InputFieldModal,
  Modal,
  ModalContentWrapper,
  ModalContent,
  ModalHeader,
  ModalLabel,
  StyledPopup,
  SubmitBtnModal,
} from './CommonStylesForms'

function FormSubwalletAdd(props) {
  const subwalletForm = useRef()
  const submitBtn = useRef()

  const onBtnClick = () => {
    if (submitBtn.current) {
      submitBtn.current.setAttribute('disabled', 'disabled')
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    onBtnClick()

    const form = new FormData(subwalletForm.current)
    const walletName = form.get('wallet_name')
    const walletLabel = form.get('wallet_label')

    props.sendRequest('MULTITENANCY', 'CREATE_SUBWALLET', {
      wallet_name: walletName,
      label: walletLabel,
    })
    closeModal()
  }

  function closeModal() {
    props.closeSubwalletAddModal()
  }

  return (
    <StyledPopup
      open={props.subwalletAddModalIsOpen}
      closeOnDocumentClick
      onClose={closeModal}
    >
      <Modal className="modal">
        <ModalHeader>Create New Subwallet</ModalHeader>
        <ModalContentWrapper>
          <ModalContent>
            <form id="form" onSubmit={handleSubmit} ref={subwalletForm}>
              <InputBox>
                <ModalLabel htmlFor="wallet_name">Wallet Name</ModalLabel>
                <InputFieldModal
                  type="wallet_name"
                  name="wallet_name"
                  id="wallet_name"
                  placeholder="Organization Name"
                  required
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="wallet_label">Label</ModalLabel>
                <InputFieldModal
                  type="wallet_label"
                  name="wallet_label"
                  id="wallet_label"
                  placeholder="Organization Label"
                />
              </InputBox>
              <Actions>
                <CancelBtn
                  type="button"
                  onClick={closeModal}
                  id="button-cancel"
                >
                  Cancel
                </CancelBtn>
                <SubmitBtnModal
                  type="submit"
                  ref={submitBtn}
                  id="button-submit"
                >
                  Submit
                </SubmitBtnModal>
              </Actions>
            </form>
          </ModalContent>
        </ModalContentWrapper>
        <CloseBtn onClick={closeModal} id="button-exit">
          &times;
        </CloseBtn>
      </Modal>
    </StyledPopup>
  )
}

export default FormSubwalletAdd
