const rules = {
  admin: [
    'basicMessages:create',
    'basicMessages:read',
    'contacts:create',
    'contacts:read',
    'contacts:update',
    'contacts:delete',
    'contextFiles:set',
    'contextFiles:read',
    'contextFiles:delete',
    'credentials:issue',
    'credentials:read',
    'credentials:reissue',
    'credentials:revoke',
    'credentialsReceived:delete',
    'credentialsReceived:read',
    'email:verify',
    'governanceEditorFiles:read',
    'governanceEditorFiles:save',
    'governanceEditorFiles:set',
    'governanceEditorFiles:delete',
    'governanceFiles:read',
    'governanceFiles:set',
    'governanceFiles:delete',
    'did:create',
    'invitations:create',
    'invitations:accept',
    'invitations:delete',
    'invitations:read',
    'presentations:read',
    'presentations:request',
    'credentialsShared:read',
    'roles:read',
    'schemas:create',
    'schemas:delete',
    'schemas:read',
    'schemas:update',
    'settings:read',
    'schemas:set',
    'settings:update',
    'multitenancy:read',
    'multitenancy:createAPIKey',
    'multitenancy:revokeAPIKey',
    'multitenancy:createWalletSettings',
    'multitenancy:setSelectedWallet',
  ],
  technician: [
    'basicMessages:create',
    'basicMessages:read',
    'contacts:create',
    'contacts:read',
    'contacts:update',
    'contextFiles:set',
    'contextFiles:read',
    'contextFiles:delete',
    'credentials:issue',
    'credentials:read',
    'credentials:reissue',
    'credentials:revoke',
    'credentialsReceived:read',
    'credentialsShared:read',
    'invitations:create',
    'invitations:read',
    'invitations:accept',
    'presentations:read',
    'presentations:request',
    'schemas:read',
    'multitenancy:setSelectedWallet',
  ],
  'super-admin': [
    'multitenancy:createWallet',
    'multitenancy:read',
    'multitenancy:createAPIKey',
    'multitenancy:revokeAPIKey',
    'multitenancy:createWalletSettings',
    'roles:read',
    'settings:update',
  ],
}

export default rules
