import { DateTime } from 'luxon'
import { useSelector } from 'react-redux'

import PageHeader from './PageHeader.js'
import PageSection from './PageSection.js'

import { AttributeTable, AttributeRow } from './CommonStylesTables'
import styled from 'styled-components'

const TopAllignedTH = styled.th`
  vertical-align: top;
`

function Presentation(props) {
  const contacts = useSelector((state) => state.contacts.contacts)
  const presentationId = props.presentation
  const presentationReports = useSelector(
    (state) => state.presentations.presentationReports
  )

  let presentationSelected = ''

  for (let i = 0; i < presentationReports.length; i++) {
    if (presentationReports[i].presentation_exchange_id === presentationId) {
      presentationSelected = presentationReports[i]
      break
    }
  }

  // (AmmonBurgi) Find connection and give the selected presentation a contact_label.
  for (let i = 0; i < contacts.length; i++) {
    if (
      contacts[i].Connections[0].connection_id ===
      presentationSelected.connection_id
    ) {
      presentationSelected.contact_label = contacts[i].label
      break
    }
  }

  if (presentationSelected !== '') {
    let displayAttributes = ''
    let attributes = {}

    const requestName = presentationSelected.presentation_request
      ? presentationSelected.presentation_request.name
      : ''

    let presentationRequestedProof = {}

    if (presentationSelected?.presentation?.requested_proof) {
      presentationRequestedProof =
        presentationSelected.presentation.requested_proof

      if (
        presentationRequestedProof?.self_attested_attrs ||
        presentationRequestedProof?.revealed_attrs
      ) {
        attributes = {
          ...presentationRequestedProof.self_attested_attrs,
          ...presentationRequestedProof.revealed_attrs,
        }
      }
    } else if (presentationSelected?.presentation?.verifiableCredential) {
      presentationRequestedProof =
        presentationSelected.presentation.verifiableCredential[0]

      if (presentationRequestedProof?.credentialSubject) {
        attributes = {
          ...presentationRequestedProof?.credentialSubject,
        }
      }
    }

    if (
      attributes[Object.keys(attributes)[0]] &&
      attributes[Object.keys(attributes)[0]].raw !== null &&
      attributes[Object.keys(attributes)[0]].raw !== undefined
    ) {
      for (const attribute in attributes) {
        attributes[attribute] = attributes[attribute].raw
      }
    }

    //(RomanStepanyan) recursively check all nested objects inside the credential to render images as well as text
    function renderAttributes(attr) {
      return Object.entries(attr).map(([key, value]) => {
        if (typeof value === 'object' && value !== null) {
          const nestedAttributes = renderAttributes(value)
          return (
            <AttributeRow key={key}>
              <TopAllignedTH>
                {key
                  .replaceAll('_', ' ')
                  .replace(/^\w/, (c) => c.toUpperCase())}
                :{' '}
              </TopAllignedTH>
              <td>
                <table>
                  <tbody>{nestedAttributes}</tbody>
                </table>
              </td>
            </AttributeRow>
          )
        } else {
          return (
            <AttributeRow key={key}>
              <TopAllignedTH>
                {key
                  .replaceAll('_', ' ')
                  .replace(/^\w/, (c) => c.toUpperCase())}
                :{' '}
              </TopAllignedTH>
              <td>
                {value?.startsWith('data:image/') ? (
                  <img src={value} alt={key.name} style={{ width: '200px' }} />
                ) : (
                  value
                )}
              </td>
            </AttributeRow>
          )
        }
      })
    }

    if (attributes && Object.keys(attributes).length > 0) {
      displayAttributes = (
        <>
          <h2>Presentation Attributes</h2>
          <AttributeTable>
            <tbody>{renderAttributes(attributes)}</tbody>
          </AttributeTable>
        </>
      )
    } else {
      displayAttributes = (
        <>
          <h2>Presentation Attributes</h2>
          <AttributeTable>
            <tbody>
              <tr>
                <td>No attributes to display</td>
              </tr>
            </tbody>
          </AttributeTable>
        </>
      )
    }

    return (
      <div id="contact">
        <PageHeader
          title={
            'Presentation request from ' + presentationSelected.contact_label ||
            ''
          }
        />
        <PageSection>
          <h2>Presentation Information</h2>
          <AttributeTable>
            <tbody>
              <AttributeRow>
                <th>Contact Label:</th>
                <td>{presentationSelected.contact_label || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Connection ID:</th>
                <td>{presentationSelected.connection_id || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Initiator:</th>
                <td>{presentationSelected.initiator || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Presentation Exchange ID:</th>
                <td>{presentationSelected.presentation_exchange_id || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Request Name:</th>
                <td>{requestName || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Role:</th>
                <td>{presentationSelected.role || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Verified:</th>
                <td>{String(presentationSelected.verified) || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>State:</th>
                <td>{presentationSelected.state.replaceAll('_', ' ') || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Thread ID:</th>
                <td>{presentationSelected.thread_id || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Date Updated:</th>
                <td>
                  {DateTime.fromISO(
                    presentationSelected.presentation_updated_at
                  ).toLocaleString(DateTime.DATETIME_MED) || ''}
                  {presentationSelected.presentation_updated_at}
                </td>
              </AttributeRow>
              <AttributeRow>
                <th>Date Created:</th>
                <td>
                  {DateTime.fromISO(
                    presentationSelected.presentation_created_at
                  ).toLocaleString(DateTime.DATETIME_MED) || ''}
                  {presentationSelected.presentation_created_at}
                </td>
              </AttributeRow>
            </tbody>
          </AttributeTable>
          {displayAttributes}
        </PageSection>
      </div>
    )
  } else {
    return (
      <div id="contact">
        <PageHeader title={'Presentation request'} />
        <PageSection>
          <h2>Presentation Information</h2>
        </PageSection>
      </div>
    )
  }
}

export default Presentation
