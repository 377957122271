import { useState } from 'react'
import { useSelector } from 'react-redux'

import FormTransaction from './FormTransaction'
import PageSection from './PageSection'
import { DataTable, DataRow, DataHeader, DataCell } from './CommonStylesTables'
import { Button } from './CommonStylesForms'

const TransactionsHeader = () => {
  return <h2 style={{ marginLeft: '-8px' }}>Author-Endorser Transactions</h2>
}

function Transactions(props) {
  const transactionsState = useSelector((state) => state.transactions)
  const [selectedRow, setSelectedRow] = useState(null)

  const transactions = transactionsState.transactions

  const transactionRows = transactions.map((transaction) => {
    const transactionId = transaction.transaction_id || ''
    const transactionType = transaction.transaction_type || ''
    const transactionAuthorAlias = transaction.author_alias || ''
    const transactionCreatedAt = transaction.created_at || ''

    return (
      <DataRow
        key={transactionId}
        onClick={() => {
          setSelectedRow(transactionId)
        }}
      >
        <DataCell>{transactionType}</DataCell>
        <DataCell>{transactionAuthorAlias}</DataCell>
        <DataCell>{new Date(transactionCreatedAt).toLocaleString()}</DataCell>
        <DataCell>
          {transaction.transaction_state === 'request_received'
            ? 'Pending'
            : transaction.transaction_state === 'transaction_acked'
            ? 'Endorsed'
            : transaction.transaction_state === 'transaction_refused'
            ? 'Rejected'
            : transaction.transaction_state || ''}
        </DataCell>
        {/* (eldersonar) TODO: move styles to styled components */}
        <DataCell
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '15px',
            }}
          >
            <Button style={{ marginLeft: '10px', alignSelf: 'center' }}>
              Preview
            </Button>
          </div>
        </DataCell>
        <FormTransaction
          createInvModalIsOpen={selectedRow === transactionId}
          closeCreateInvModal={() => setSelectedRow(null)}
          sendRequest={props.sendRequest}
          txnID={transactionId}
          row={transaction}
        />
      </DataRow>
    )
  })

  return (
    <>
      <div id="transactions">
        <PageSection>
          <TransactionsHeader />
          <DataTable id="table-transactions">
            <thead>
              <DataRow>
                <DataHeader>Type</DataHeader>
                <DataHeader>Created By</DataHeader>
                <DataHeader>Created At</DataHeader>
                <DataHeader>Transaction Status</DataHeader>
                <DataHeader></DataHeader>
              </DataRow>
            </thead>
            <tbody>{transactionRows}</tbody>
          </DataTable>
        </PageSection>
      </div>
    </>
  )
}

export default Transactions
