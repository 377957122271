import { useState } from 'react'
import { useSelector } from 'react-redux'

import PageHeader from './PageHeader.js'
import PageSection from './PageSection.js'
import FormCredentialRevocation from './FormCredentialRevocation'

import { AttributeTable, AttributeRow } from './CommonStylesTables'

import styled from 'styled-components'

const SaveBtn = styled.button`
  width: 80px;
  background: ${(props) => props.theme.primary_color};
  padding: 10px;
  color: ${(props) => props.theme.text_light};
  border: none;
  float: right;
  box-shadow: ${(props) => props.theme.drop_shadow};
  &:hover {
    cursor: pointer;
  }
`
const TopAllignedTH = styled.th`
  vertical-align: top;
`

function Credential(props) {
  const credentials = useSelector((state) => state.credentials.credentials)
  const settings = useSelector((state) => state.settings)

  const credentialId = props.credential

  const [revocationModalIsOpen, setRevocationModalIsOpen] = useState(false)

  const closeRevocationModal = () => setRevocationModalIsOpen(false)

  const revokeCredential = () => {
    setRevocationModalIsOpen(true)
  }

  let credentialSelected = ''
  let attributesObject = null

  credentialSelected =
    credentials.find((cred) => cred.credential_exchange_id === credentialId) ||
    ''

  if (credentialSelected && credentialSelected?.attributes?.credentialSubject) {
    attributesObject = credentialSelected.attributes.credentialSubject
  } else if (
    credentialSelected &&
    credentialSelected.credential_definition_id
  ) {
    attributesObject = credentialSelected.attributes
  }

  // Initialize variables to blank (to prevent errors during loading)
  let showCredential = {
    name: '',
    connection_id: '',
    credential_exchange_id: '',
    state: '',
    date_created: '',
    revoked: '',
  }

  // Attributes of this kind of credential
  let mappedAttributes = ''

  // Now set the values if we have a credential
  if (credentialSelected) {
    if (credentialSelected.schema_id) {
      showCredential.name = credentialSelected.schema_id
        ?.split(':')[2]
        .replaceAll('_', ' ')
    } else if (credentialSelected.attributes?.credentialSubject) {
      showCredential.name = credentialSelected.attributes.name
    } else {
      showCredential.name = credentialSelected.credential_offer.credential.name
    }

    showCredential.connection_id = credentialSelected.connection_id || ''
    showCredential.credential_exchange_id =
      credentialSelected.credential_exchange_id || ''
    showCredential.state = credentialSelected.state.replaceAll('_', ' ')
    showCredential.created_at =
      new Date(credentialSelected.created_at).toLocaleString() || ''
    showCredential.revoked =
      credentialSelected.revoked === true ? 'true' : 'false'

    //(RomanStepanyan) recursively check all nested objects inside the credential to render images as well as text
    function renderAttributes(attributes) {
      return Object.entries(attributes).map(([key, value]) => {
        if (typeof value === 'object' && value !== null) {
          const nestedAttributes = renderAttributes(value)
          return (
            <AttributeRow key={key}>
              <TopAllignedTH>
                {key
                  .replaceAll('_', ' ')
                  .replace(/^\w/, (c) => c.toUpperCase())}
                :{' '}
              </TopAllignedTH>
              <td>
                <table>
                  <tbody>{nestedAttributes}</tbody>
                </table>
              </td>
            </AttributeRow>
          )
        } else {
          return (
            <AttributeRow key={key}>
              <TopAllignedTH>
                {key
                  .replaceAll('_', ' ')
                  .replace(/^\w/, (c) => c.toUpperCase())}
                :{' '}
              </TopAllignedTH>
              <td>
                {value.startsWith('data:image/') ? (
                  <img src={value} alt={key.name} style={{ width: '200px' }} />
                ) : (
                  value
                )}
              </td>
            </AttributeRow>
          )
        }
      })
    }

    if (
      credentialSelected &&
      Object.keys(credentialSelected).length > 0 &&
      attributesObject
    ) {
      mappedAttributes = renderAttributes(attributesObject)
    } else if (
      credentialSelected &&
      Object.keys(credentialSelected).length > 0 &&
      !attributesObject
    ) {
      mappedAttributes = (
        <tr>
          <td>No attributes to display</td>
        </tr>
      )
    }
  }

  return (
    <div id="contact">
      <PageHeader title={`${showCredential.name} Credential`} />
      <PageSection>
        <h2>Credential Information</h2>
        {settings.revocationAllowed &&
          showCredential.revoked === 'false' &&
          (showCredential.state === 'credential_acked' ||
            showCredential.state === 'done') && (
            <SaveBtn title="Revoke a credential" onClick={revokeCredential}>
              Revoke
            </SaveBtn>
          )}
        <AttributeTable>
          <tbody>
            <AttributeRow>
              <th>Credential Name:</th>
              <td>{showCredential.name}</td>
            </AttributeRow>
            <AttributeRow>
              <th>Credential ID:</th>
              <td>{showCredential.credential_exchange_id}</td>
            </AttributeRow>
            <AttributeRow>
              <th>Credential State:</th>
              <td>{showCredential.state}</td>
            </AttributeRow>
            <AttributeRow>
              <th>Date Created:</th>
              <td>{showCredential.created_at}</td>
            </AttributeRow>
            <AttributeRow>
              <th>Revoked:</th>
              <td>{showCredential.revoked}</td>
            </AttributeRow>
            <FormCredentialRevocation
              sendRequest={props.sendRequest}
              revocationModalIsOpen={revocationModalIsOpen}
              closeRevocationModal={closeRevocationModal}
              credential_exchange_id={showCredential.credential_exchange_id}
              connection_id={showCredential.connection_id}
            />
          </tbody>
        </AttributeTable>
        <h2>Attributes</h2>
        <AttributeTable>
          <tbody>{mappedAttributes}</tbody>
        </AttributeTable>
      </PageSection>
    </div>
  )
}

export default Credential
