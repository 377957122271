import React, { useRef, useEffect } from 'react'

import {
  StyledPopup,
  Modal,
  ModalHeader,
  ModalContentWrapper,
  ModalContent,
  CloseBtn,
  Actions,
  CancelBtn,
  SubmitBtnModal,
} from '../Styles/CommonStylesForms'

function FormFileDelete(props) {
  const fileID = props.goverananceFileId
  const error = props.error

  const submitBtn = useRef()

  useEffect(() => {
    if (error && submitBtn.current) {
      submitBtn.current.removeAttribute('disabled')
    }
  }, [error])

  // Disable button on submit
  const onBtnClick = (e) => {
    if (submitBtn.current) {
      submitBtn.current.setAttribute('disabled', 'disabled')
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    onBtnClick()

    props.sendRequest('GOVERNANCE', 'DELETE_GOVERNANCE_FILE', fileID)
  }

  function closeModal() {
    props.closeDeleteModal()
  }

  return (
    <StyledPopup
      open={props.deleteGovernanceFileModalIsOpen}
      closeOnDocumentClick
      onClose={closeModal}
    >
      <Modal className="modal">
        <ModalHeader>
          Are you sure you want to remove this file from the list?
        </ModalHeader>
        <ModalContentWrapper>
          <ModalContent>
            <form id="form" onSubmit={handleSubmit}>
              <Actions>
                <CancelBtn
                  id="button-cancel-governance"
                  type="button"
                  onClick={closeModal}
                >
                  Cancel
                </CancelBtn>
                <SubmitBtnModal
                  id="button-remove-governance"
                  type="submit"
                  ref={submitBtn}
                >
                  Remove
                </SubmitBtnModal>
              </Actions>
            </form>
          </ModalContent>
        </ModalContentWrapper>
        <CloseBtn id="button-exit-governance" onClick={closeModal}>
          &times;
        </CloseBtn>
      </Modal>
    </StyledPopup>
  )
}

export default FormFileDelete
