import rules from './rbac-rules'
import store from '../store'

export const check = (actions) => {
  const currentState = store.getState()
  const userRoles = currentState.login.loggedInRoles

  // Get user roles
  if (!userRoles) {
    return false
  } else {
    let roles = userRoles

    // Handle multiple roles by casting roles into array
    roles = roles instanceof Array ? roles : [roles]

    let permissions = []

    // Combine roles, ignore duplicate roles
    for (let i = 0; i < Object.keys(roles).length; i++) {
      permissions = [...new Set([...permissions, ...rules[roles[i]]])]
    }

    if (!permissions) {
      return false
    }

    // Determine all the permissions required by the component
    if (permissions) {
      const actionsList = actions?.split(', ')

      // Check if the user has all required permissions
      for (const action in actionsList) {
        if (permissions.includes(actionsList[action])) {
          return true
        }
      }
    }
    return false
  }
}

export const CanUser = ({ perform, yes = () => null, no = () => null }) =>
  check(perform) ? yes() : no()
