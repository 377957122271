import Axios from 'axios'
import { useEffect, useState, createContext } from 'react'
import { useSelector } from 'react-redux'

import { formatSeconds } from './util'

import {
  Actions,
  StyledPopup,
  SubmitBtnModal,
  Modal,
  ModalHeader,
  ModalContent,
  TextWrapper,
} from './CommonStylesForms'

const SessionAlertContext = createContext()

// Exporting the session alert component
export default function SessionAlertProvider(props) {
  const loginState = useSelector((state) => state.login)

  const [open, setOpen] = useState(false)
  const [keepAlive, setKeepAlive] = useState(true)
  const [idleCountdown, setIdleCountdown] = useState(299)
  const [finalCountdown, setFinalCountdown] = useState(300)

  const closeModal = () => {
    setOpen(false)
  }

  // ----------------------------- Session management -------------------------
  // Handling form submit
  const handleSubmit = (e) => {
    e.preventDefault()

    closeModal()
    setFinalCountdown(300)
    setKeepAlive(true)

    // (eldersoanr) Keep session alive by resetting expiration time
    Axios({
      method: 'GET',
      url: '/api/check-session',
    })
  }
  // ----------------------------- Session management -------------------------

  // ----------------------------- Idle countdown -------------------------
  // Setting idle countdown
  useEffect(() => {
    if (!loginState.loggedIn) {
      setIdleCountdown(299)
      setFinalCountdown(300)
      closeModal()
      return
    }

    const countdownInterval = setInterval(() => {
      setIdleCountdown((prevCountdown) => {
        if (prevCountdown > 0 && !open) {
          return prevCountdown - 1
        } else if (!open && prevCountdown === 0) {
          console.log('setting to true')
          setOpen(true)
          setKeepAlive(false)
        }

        return prevCountdown
      })

      if (finalCountdown === 1) {
        closeModal()
        props.logout()
      }
    }, 1000)

    const resetTimeout = () => {
      setIdleCountdown(299)
    }

    const events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keydown',
    ]

    events.forEach((event) => {
      window.addEventListener(event, resetTimeout)
    })

    return () => {
      clearInterval(countdownInterval)

      events.forEach((event) => {
        window.removeEventListener(event, resetTimeout)
      })
    }
  }, [idleCountdown, finalCountdown, loginState.loggedIn, open])

  // Setting the final countdown
  useEffect(() => {
    const secondsInterval = setInterval(() => {
      if (finalCountdown >= 0 && !keepAlive && loginState.loggedIn && open) {
        setFinalCountdown(finalCountdown - 1)
        SecondsTimer()
      }
    }, 1000)
    return () => {
      clearInterval(secondsInterval)
    }
  })
  // ----------------------------- Idle countdown -------------------------

  const SecondsTimer = () => {
    return (
      <TextWrapper>
        <h3>The session will expire in</h3>
        <br />
        <h2> {formatSeconds(finalCountdown)}</h2>
        <h3>Extend my session</h3>
      </TextWrapper>
    )
  }

  const sessionAlert =
    (
      <StyledPopup
        open={open}
        closeOnDocumentClick={false}
        onClose={closeModal}
      >
        <Modal>
          <ModalHeader>You've been inactive for a while...</ModalHeader>
          <form id="form" onSubmit={handleSubmit}>
            <ModalContent>
              <SecondsTimer />
            </ModalContent>
            <Actions>
              <SubmitBtnModal type="submit">Yes</SubmitBtnModal>
            </Actions>
          </form>
        </Modal>
      </StyledPopup>
    ) || ''

  return (
    <SessionAlertContext.Provider value={null}>
      {loginState.loggedIn ? sessionAlert : null}
      {props.children}
    </SessionAlertContext.Provider>
  )
}
